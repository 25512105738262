import React from 'react';
import { PackageCardProps } from 'components/Signup/types';
import useGetUser from 'components/Signup/hooks/useGetUser';
import { useFlag } from '@dashboard-experience/react-flagr';
import { M } from '@dashboard-experience/mastodon';
import useAmplitudeExperiment from 'components/Signup/hooks/useAmplitudeExperiment';

import { SHOW_COMPLETE_CRIMINAL_FLAG_KEY } from 'Constants';
import { Container } from '../styles';
import BasicPackageCard from './BasicPackageCard';
import CompletePackageCard from './CompletePackageCard';
import EssentialPackageCard from './EssentialPackageCard';
import ProfessionalPackageCard from './ProfessionalPackageCard';
import { HeaderHeightProvider } from '../../PackageCard/PackageCardState';

const DomesticPackages = ({
  selectedPackage,
  setSelectedPackage,
}: PackageCardProps) => {
  const { data, isLoading } = useGetUser();
  const { loading: experimentLoading, variant: ab13Variant } =
    useAmplitudeExperiment();

  let showCompleteCrim = false;
  let cohorts = [];

  const showCompleteFlagEnabled =
    useFlag(SHOW_COMPLETE_CRIMINAL_FLAG_KEY)?.variantKey === 'enabled';

  if (data && !isLoading) {
    cohorts = data?.account?.cohorts;
    showCompleteCrim =
      showCompleteFlagEnabled ||
      cohorts?.some(
        (cohort: { name: string }) =>
          cohort.name === 'ab-10-good-better-best-test' ||
          cohort.name === 'ab-11-good-better-best-test-assisted',
      );
  }

  const cardProps = {
    selectedPackage,
    setSelectedPackage,
    showCompleteCrim,
    showCompleteText:
      ab13Variant === 'ab-13-mvt-on-signup-packages-page-test-v2' ||
      ab13Variant === 'ab-13-mvt-on-signup-packages-page-test-v3',
  };

  if (isLoading || experimentLoading) {
    return <M.LoadingBlock />;
  }

  if (
    ab13Variant === 'ab-13-mvt-on-signup-packages-page-test-v1' ||
    ab13Variant === 'ab-13-mvt-on-signup-packages-page-test-v3'
  ) {
    return (
      <HeaderHeightProvider>
        <Container data-testid='domestic-packages-container'>
          {showCompleteCrim ? (
            <CompletePackageCard {...cardProps} />
          ) : (
            <ProfessionalPackageCard {...cardProps} />
          )}
          <EssentialPackageCard {...cardProps} />
          <BasicPackageCard {...cardProps} />
        </Container>
      </HeaderHeightProvider>
    );
  }

  return (
    <HeaderHeightProvider>
      <Container data-testid='domestic-packages-container'>
        <BasicPackageCard {...cardProps} />
        <EssentialPackageCard {...cardProps} />
        {showCompleteCrim ? (
          <CompletePackageCard {...cardProps} />
        ) : (
          <ProfessionalPackageCard {...cardProps} />
        )}
      </Container>
    </HeaderHeightProvider>
  );
};

export default DomesticPackages;

import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledSubNote = styled.div<{ $showBorder?: boolean }>`
  border-top: ${({ $showBorder }) =>
    $showBorder ? `1px solid ${colors.uiTextPrimaryLight}` : 'none'};
  padding-top: 16px;
`;

export const StyledSubNoteText = styled.div`
  color: ${colors.uiTextPrimaryLight};
  line-height: 16px;
  font-size: 12px;
`;

export const StyledLink = styled(M.Link)`
  font-size: 12px;
`;

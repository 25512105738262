import {
  NAV_COLLAPSE_TRANSITION,
  NAV_SIZES,
} from 'modules/layout/navigation/constants';
import React from 'react';
import styled, { css } from 'styled-components';

type BodyContainerProps = {
  reportsListRedesignState: boolean;
  isHamburgerNavigationTrue: boolean;
};

export const BodyContainer = styled.div<BodyContainerProps>`
  margin-right: auto;
  margin-left: auto;
  margin-top: ${({ reportsListRedesignState, isHamburgerNavigationTrue }) =>
    reportsListRedesignState && isHamburgerNavigationTrue ? '56px' : '0'};
  padding-left: 15px;
  padding-right: 15px;
  overflow: visible;
  width: 100%;
`;

export const MainContent = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  margin-top: 0;

  overflow-y: auto;
  overflow-x: clip;
  height: 100vh;

  @media (min-width: 768px) {
    align-items: stretch;
  }
`;

const getPadding = (removePadding: boolean, onReportsList: boolean) => {
  if (onReportsList) {
    return '0px 32px 32px 32px';
  }
  if (removePadding) {
    return '0';
  }
  return '4.5rem 1.5rem 1rem';
};

const getMediaQueryPadding = (
  removePadding: boolean,
  onReportsList: boolean,
) => {
  if (onReportsList) {
    return '0px 32px 32px 32px';
  }
  if (removePadding) {
    return '0';
  }
  return '3rem 4rem';
};

type ContentNewProps = {
  removePadding: boolean;
  onReportsList: boolean;
};

export const Content = styled.div<ContentNewProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: ${({ removePadding, onReportsList }) =>
    getPadding(removePadding, onReportsList)};

  @media (min-width: 768px) {
    padding: ${({ removePadding, onReportsList }) =>
      getMediaQueryPadding(removePadding, onReportsList)};
  }

  @media (min-width: 320px) and (max-width: 1055px) {
    padding: ${({ onReportsList }) =>
      onReportsList ? '0px 24px 20px 24px' : undefined};
  }
`;

export const FlexGrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

type ContentWrapNewProps = {
  navCollapsed: boolean;
};

export const ContentWrap = styled.div<ContentWrapNewProps>`
  position: relative;
  left: 0;
  transition: left 0.35s ease;

  display: flex;
  flex-direction: column;

  transition: width ${NAV_COLLAPSE_TRANSITION};
  width: calc(100% - ${NAV_SIZES.collapsed});

  @media (min-width: 768px) {
    ${({ navCollapsed }) =>
      !navCollapsed &&
      css`
        width: calc(100% - ${NAV_SIZES.expanded});
      `}
  }
`;

export const ContentWrapReportsListRedesign = styled.div`
  position: relative;
  left: 0;
  transition: left 0.35s ease;

  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

type BodyProps = {
  children: React.ReactNode;
  reportsListRedesignState: boolean;
  isHamburgerNavigationTrue: boolean;
};

export const Body: React.FC<BodyProps> = ({
  children,
  reportsListRedesignState,
  isHamburgerNavigationTrue,
}) => {
  return (
    <BodyContainer
      reportsListRedesignState={reportsListRedesignState}
      isHamburgerNavigationTrue={isHamburgerNavigationTrue}
    >
      {children}
    </BodyContainer>
  );
};

import React from 'react';
import { FieldComponentProps } from '../../types';

const Name: React.FC<FieldComponentProps> = ({
  fieldKey,
  candidate,
  testid,
}) => {
  if (!candidate) {
    return null;
  }

  const value = (candidate as any)[fieldKey] || '-';

  return <div data-testid={testid}>{value}</div>;
};

export default Name;

import React, { useCallback, useState } from 'react';
import PackageScreeningItem from 'components/BetterOrderExperience/SelectPackageStep/PackageScreeningItem';
import { useTranslation } from 'react-i18next';
import { Screening } from 'types';
import { PostPackageType } from '@dashboard-experience/utils';
import { namespace } from '../../locales';
import {
  StyledContainer,
  StyledFlexContainer,
  StyledPackageName,
  StyledSelectedPackage,
  StyledViewDetailsButton,
} from './SelectedPackageInfoSection.styles';
import PackagePrice from '../ui/PackagePrice';

const SelectedPackageInfoSection: React.FC<{
  selectedPackage: PostPackageType;
}> = ({ selectedPackage }) => {
  const { t } = useTranslation(namespace);
  const [viewDetails, setViewDetails] = useState(false);

  const handleViewDetailsClick = useCallback(
    () => setViewDetails(!viewDetails),
    [viewDetails],
  );

  return (
    <StyledFlexContainer>
      <StyledSelectedPackage>
        <StyledPackageName>
          <h5>{selectedPackage.name} </h5>
          <StyledViewDetailsButton
            data-testid='view-details-button'
            onClick={handleViewDetailsClick}
          >
            {t(
              `reviewAndSubmit.${!viewDetails ? 'viewDetails' : 'hideDetails'}`,
            )}
          </StyledViewDetailsButton>
        </StyledPackageName>
        {selectedPackage.screenings.map((screening, idx: number) => {
          const key = `screening-${idx}`;
          return (
            <StyledContainer key={key}>
              <PackageScreeningItem
                screening={screening as Screening}
                showScreeningSettings={viewDetails}
              />
            </StyledContainer>
          );
        })}
      </StyledSelectedPackage>
      <PackagePrice price={selectedPackage.price} />
    </StyledFlexContainer>
  );
};

export default SelectedPackageInfoSection;

import React, { useCallback, useContext, useMemo } from 'react';
import UIContext from 'context/UI';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import {
  PAYMENT_AND_BILLING_NAMES,
  updateParentWindowUrl,
  useTrackEvent,
} from 'utils';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY,
  IS_IN_IFRAME,
} from 'Constants';
import { useHistory } from 'react-router-dom';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import {
  DocumentsPage,
  InvoicesPage,
  PaymentProfilesPage,
  BulkOrderHistoryPage,
  NodeBillingPage,
} from '../../pages/Billing';
import { PaymentPage } from '../../pages/Account';
import { BillingHeader } from '../../components/Account';
import { useBillingCustomerContext } from './context';

type BillingContainerProps = {
  context: string;
};

const TabsWrapper = styled.div`
  #mastodon & {
    .mastodon-tabs-container {
      z-index: auto;

      .mastodon-tab-list {
        top: 7.5rem;
        position: sticky;
        z-index: 999;
        background-color: white;
        padding-left: 2rem;
      }
    }
  }
`;

const BillingContainer: React.FC<BillingContainerProps> = ({ context }) => {
  const list = [
    'invoices',
    'payment',
    'documents',
    'bulk-history',
    'payment-profiles',
  ];
  const pages = useMemo(() => list, []);
  const currentIndex = pages.indexOf(context);
  const { contextId } = useContext(UIContext);
  const { customerData, isLoading: isCustomerLoading } =
    useBillingCustomerContext();
  const currentUser = useUser();

  const history = useHistory();
  const bulkInviteFlagrEnabled: boolean = !isNaN(
    useFlag(BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY)?.variantKey,
  );
  const trackEvent = useTrackEvent();
  const updateUrl = useCallback(
    (e: { selectedIndex: number }) => {
      const path = `billing/${pages[e.selectedIndex]}`;

      if (bulkInviteFlagrEnabled && pages[e.selectedIndex] === 'documents') {
        trackEvent(PAYMENT_AND_BILLING_NAMES.PAYMENT_DOCUMENTS_TAB_CLICKED);
      }

      if (IS_IN_IFRAME && contextId) {
        updateParentWindowUrl({
          contextId,
          path,
        });
      }
      history.push(pages[e.selectedIndex]);
    },
    [pages, bulkInviteFlagrEnabled, contextId, trackEvent, history],
  );

  const hasReadAccountInvoicesPermission =
    hasPermission(currentUser, 'read_account_invoices') || false;

  const billingEntityEnabled = customerData?.billing_entity_enabled || false;
  const ahBillingEnabled =
    currentUser?.account?.account_hierarchy_billing || false;
  const showProfilesTab = ahBillingEnabled || billingEntityEnabled;
  const profilesTabText = ahBillingEnabled
    ? 'Node billing'
    : 'Payment profiles';
  const profilesTabComponent = ahBillingEnabled ? (
    <NodeBillingPage />
  ) : (
    <PaymentProfilesPage />
  );

  const autopayEnabled = customerData?.autopay_enabled || false;

  return (
    <>
      <BillingHeader
        autopayEnabled={autopayEnabled}
        isCustomerLoading={isCustomerLoading}
      />
      <TabsWrapper>
        <M.Tabs
          defaultSelectedIndex={currentIndex}
          selectedIndex={currentIndex}
          onChange={updateUrl}
        >
          <M.TabList>
            <M.Tab data-testid='payment-billing-invoices-tab'>Invoices</M.Tab>
            <M.Tab data-testid='payment-billing-payment-tab'>
              Payment methods
            </M.Tab>
            <M.Tab data-testid='payment-billing-documents-tab'>Documents</M.Tab>
            {hasReadAccountInvoicesPermission && (
              <M.Tab data-testid='payment-billing-bulk-order-history-tab'>
                Bulk order history
              </M.Tab>
            )}
            {showProfilesTab ? (
              <M.Tab data-testid='payment-billing-payment-profiles-tab'>
                {profilesTabText}
              </M.Tab>
            ) : (
              <div />
            )}
          </M.TabList>
          <M.TabPanels>
            <M.TabPanel>
              <InvoicesPage />
            </M.TabPanel>
            <M.TabPanel>
              <PaymentPage />
            </M.TabPanel>
            <M.TabPanel>
              <DocumentsPage />
            </M.TabPanel>

            {hasReadAccountInvoicesPermission && (
              <M.TabPanel>
                {context === 'bulk-history' && <BulkOrderHistoryPage />}
              </M.TabPanel>
            )}

            {showProfilesTab ? (
              <M.TabPanel>{profilesTabComponent}</M.TabPanel>
            ) : (
              <div />
            )}
          </M.TabPanels>
        </M.Tabs>
      </TabsWrapper>
    </>
  );
};

export default BillingContainer;

import { useCandidate } from 'providers/Candidate';
import React from 'react';
import { useInvitation } from 'modules/candidate/utilities';
import { useTranslation } from 'react-i18next';
import NonActionableBanner from './non-actionable-banner';

const InvitationQueuedBanner = () => {
  const { t } = useTranslation();

  const candidate = useCandidate();
  const invitation = useInvitation();

  // Only show this banner if the invitation is actually queued
  if (invitation?.status !== 'queued') {
    return null;
  }

  // Show generic text if we don't have the candidate's name; we GENERALLY won't have a name, unless they already have an actual Report with us.
  const displayName = candidate.first_name || 'the candidate';

  return (
    <NonActionableBanner
      kind='info'
      title={t('banners.invitationQueued.text', {
        displayName,
        interpolation: { escapeValue: false },
      })}
    />
  );
};

export default InvitationQueuedBanner;

import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const statusColorMap: { [key: string]: string } = {
  invited: colors.brandSlate5,
  pending: colors.uiNavy500,
  clear: colors.uiGreen500,
  review: colors.uiYellow500,
  escalated: colors.uiPink500,
  disputed: colors.uiOrange500,
};

const statusColorShadowMap: { [key: string]: string } = {
  invited: 'rgba(157, 167, 178, 0.30)',
  pending: 'rgba(83, 153, 223, 0.30)',
  clear: 'rgba(53, 212, 101, 0.30)',
  review: 'rgba(250, 210, 50, 0.30)',
  escalated: 'rgba(243, 130, 182, 0.30)',
  disputed: 'rgba(248, 113, 79, 0.30)',
};

const StatusCardIcon = styled(M.Icon)`
  visibility: hidden;
  opacity: 0;
  color: ${colors.uiGrey600};
  transition: 0.3s;
`;

const StatusCardColor = styled.div<{
  status: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 200px;
  background-color: ${props => statusColorMap[props.status]};
  transition: 0.3s;
`;

const StatusCardPrimaryTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-bottom: 4px;
  transition: 0.3s;

  tr td div {
    width: 48px !important;
  }
`;

const StatusCardPrimaryText = styled.h3`
  font-size: 1.5rem !important;
  margin-bottom: 0 !important;
  font-family: Helvetica Neue;
  color: ${colors.uiTextPrimaryLight};
`;

const StatusCardSecondaryTextContainer = styled.div`
  width: 100%;
  font-size: 0.75rem !important;
  font-weight: 400;
  color: ${colors.uiTextTertiaryLight};
  transition: 0.3s;

  label {
    cursor: pointer;
  }
`;

const StatusCardContainer = styled.div<{
  status: string;
}>`
  cursor: pointer;
  background-color: ${colors.uiGrey0};
  border: 1px solid ${colors.uiGrey200};
  border-radius: 4px;
  padding: 8px 16px 12px 18px;
  flex: 1;
  overflow: hidden;
  position: relative;
  transition: 0.3s;
  box-shadow: 0px 2px 4px -2px rgba(72, 82, 94, 0.14);

  &:hover {
    box-shadow: 0px 8px 24px -8px rgba(98, 110, 122, 0.1),
      0px 8px 32px -16px var(--ui-hover, rgba(135, 147, 161, 0.1));
    ${StatusCardIcon} {
      visibility: visible;
      opacity: 1;
    }
    ${StatusCardPrimaryTextContainer} {
      margin-left: 2px;
    }
    ${StatusCardSecondaryTextContainer} {
      margin-left: 2px;
    }
    ${StatusCardColor} {
      box-shadow: 0px 0px 16px 0px
        ${props => statusColorShadowMap[props.status]};
    }
  }

  &:active {
    box-shadow: none;
  }
`;

const styles = {
  StatusCardIcon,
  StatusCardContainer,
  StatusCardColor,
  StatusCardPrimaryTextContainer,
  StatusCardPrimaryText,
  StatusCardSecondaryTextContainer,
};

export default styles;

import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledMotorVehicleReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledToggleTip = styled(M.Toggletip)`
  #mastodon && {
    margin-left: 0.25rem;
    margin-top: 0.275rem;
    .mastodon-toggletip-content {
      max-width: 18rem;
    }
    .mastodon-toggletip-button > svg {
      fill: ${colors.uiNavy600};
    }
  }
`;

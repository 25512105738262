import { M, colors } from '@dashboard-experience/mastodon';
import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

const Container = styled.span`
  cursor: pointer;
`;

const Text = styled.span`
  color: ${colors.uiGrey500};
  font-weight: bolder;
  margin-left: 0.5em;
`;

type Props = {};

const UnassignedStatus: React.FC<Props> = () => {
  const { t } = useTranslation('assess');
  const text = t(`assess:ruleset_assignments.status.unassigned`, 'Unassigned');

  return (
    <Container data-testid='assess-rulesets-unassigned-status'>
      <M.Tooltip
        label='This ruleset has no candidate segments assigned to it. It will not evaluate any candidates.'
        highlighted={false}
      >
        <M.StatusIcon icon='warning' style={{ display: 'inline-block' }} />
        <Text>{text}</Text>
      </M.Tooltip>
    </Container>
  );
};

export default UnassignedStatus;

import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

type Props = {
  openModal: boolean;
  closeModal: Function;
};

const LearnMoreModal: React.FC<Props> = ({ openModal, closeModal }) => {
  const { t } = useTranslation();

  return (
    <M.ComposedModal onClose={closeModal} open={openModal}>
      <M.ModalHeader closeModal={closeModal}>
        <h2>{t(`pageHeaders.billing.title`)}</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <p>
          {t(`pageHeaders.billing.modal.page_description_p1`)}{' '}
          <M.Link
            inline
            href='https://help.checkr.com/hc/en-us/articles/4429308795927-Payment-billing'
            target='_blank'
          >
            {t(`pageHeaders.billing.modal.learn_more_link`)}
          </M.Link>
        </p>
        <p>
          {t(`pageHeaders.billing.modal.page_description_p2`)}{' '}
          <M.Link inline href='https://www.stripe.com' target='_blank'>
            {t(`pageHeaders.billing.modal.stripe_link`)}
          </M.Link>
        </p>
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default LearnMoreModal;

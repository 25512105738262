import styled from 'styled-components';

// FLEXBOX COMPONENT
type FlexProps = {
  flexDirection?: 'row' | 'column';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  columnGap?: '1rem';
  alignItems?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'stretch'
    | 'baseline'
    | 'right';
  verticalAlign?: 'top' | 'middle' | 'bottom';
};

// eslint-disable-next-line import/prefer-default-export
export const StyledFlex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  column-gap: ${props => props.columnGap || '0'};
  align-items: ${props => props.alignItems || 'flex-start'};
  verticalal-align: ${props => props.verticalAlign || 'middle'};
  width: 100%;
`;

/* eslint-disable react/no-danger */
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/candidate/locales';
import moment from 'moment';
import { useCandidate } from 'providers/Candidate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Exception } from 'types';
import {
  capitalizeFirstLetter,
  getExceptionDocumentTypes,
  getExpirationDaysMessage,
  getOldestExceptionCreationDate,
} from '../utils';
import Action from './action';

const List = styled.ul`
  line-height: 20px;
`;

const ListItem = styled.li`
  list-style-type: disc;
  margin-left: 25px;
  padding-left: 3px;
`;

const StyledLink = styled(M.Link)`
  display: inline !important;
`;

const MultipleExceptionsBanner = ({
  exceptions,
  exceptionStatus,
}: {
  exceptions: Exception[];
  exceptionStatus: 'resolvable' | 'resolved';
}) => {
  const candidate = useCandidate();
  const { t } = useTranslation();

  if (exceptions.length <= 1) return null;

  const exceptionIsResolved = exceptionStatus === 'resolved';
  const kind = exceptionIsResolved ? 'success' : 'warning';
  const dateFormat = exceptionIsResolved ? 'MMM D, YYYY h:mm A' : 'MMM D, YYYY';
  const expirationDaysMessage = getExpirationDaysMessage(exceptions);

  const translatedTitle = t(
    `${namespace}:report.exceptions.banners.${exceptionStatus}.multipleTitle`,
  );

  const translatedBodyIntro = t(
    `${namespace}:report.exceptions.banners.${exceptionStatus}.multipleBody`,
    {
      firstName: candidate?.first_name || 'the candidate',
      interpolation: { escapeValue: false },
    },
  );

  return (
    <M.ExpandableNotification
      key='exceptions'
      title={translatedTitle}
      secondaryTitle={
        !exceptionIsResolved ? getOldestExceptionCreationDate(exceptions) : null
      }
      bodyNode={
        <>
          <div dangerouslySetInnerHTML={{ __html: translatedBodyIntro }} />
          <List>
            {exceptions.map(exception => {
              const documentLine = t(
                `${namespace}:report.exceptions.banners.${exceptionStatus}.documentLine`,
                {
                  documentType: capitalizeFirstLetter(
                    getExceptionDocumentTypes(exception),
                  ),
                  resolvedDate: moment(exception.resolved_at).format(
                    dateFormat,
                  ),
                  interpolation: { escapeValue: false },
                },
              );

              const sentToCandidate = t(
                `${namespace}:report.exceptions.banners.${exceptionStatus}.sentToCandidate`,
                {
                  exceptionCreatedMessage: `on ${moment(
                    exception.created_at,
                  ).format(dateFormat)}`,
                  interpolation: { escapeValue: false },
                },
              );

              return (
                <ListItem key={exception.id}>
                  <span dangerouslySetInnerHTML={{ __html: documentLine }} />
                  <StyledLink href={exception.verification_uri} target='_blank'>
                    {t(
                      `${namespace}:report.exceptions.banners.${exceptionStatus}.showRequest`,
                    )}
                  </StyledLink>{' '}
                  <span dangerouslySetInnerHTML={{ __html: sentToCandidate }} />
                  <br />
                </ListItem>
              );
            })}
            {!exceptionIsResolved && (
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    `${namespace}:report.exceptions.banners.resolvable.footer`,
                    {
                      firstName: candidate?.first_name || 'the candidate',
                      expirationDaysMessage,
                      interpolation: { escapeValue: false },
                    },
                  ).replace(/<br key='1' \/>/g, '<br />'),
                }}
              />
            )}
          </List>
          {!exceptionIsResolved && <Action />}
        </>
      }
      kind={kind}
      initialExpanded
    />
  );
};

export default MultipleExceptionsBanner;

import React from 'react';
import { M } from '@dashboard-experience/mastodon';

const FairChanceActLink = () => (
  <M.Link
    href='https://help.checkr.com/s/article/360036928693-What-is-the-Fair-Credit-Reporting-Act-FCRA'
    target='_blank'
  >
    Fair Credit Reporting Act
  </M.Link>
);
export default FairChanceActLink;

import React from 'react';
import {
  BoxHeader,
  BulletPoint,
} from 'components/AddScreenings/shared/ListItemElements';
import { StyledFlex } from '../../AddonsStep.styles';

type Props = {
  header: string;
  items: string[];
};

const ScreeningSectionItemBulletedList: React.FC<Props> = ({
  header,
  items,
}) => {
  return (
    <StyledFlex flexDirection='column' justifyContent='space-between'>
      <BoxHeader>{header}</BoxHeader>
      <ul className={`${header}-bulleted-list`}>
        {items?.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <BulletPoint key={i} text={item} />
        ))}
      </ul>
    </StyledFlex>
  );
};

export default ScreeningSectionItemBulletedList;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCandidate } from 'providers/Candidate';
import React from 'react';
import { useInvitation } from 'modules/candidate/utilities';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import NonActionableBanner from './non-actionable-banner';

const InvitationSentBanner = () => {
  const { t } = useTranslation();

  const candidate = useCandidate();
  const invitation = useInvitation();

  // Only show this banner if the invitation is still pending.
  if (!invitation || invitation.status !== 'pending') {
    return null;
  }

  const timezone = moment.tz.guess();
  const displayDate = moment
    .tz(invitation.created_at, timezone)
    .format('MMM D, YYYY [at] h:mm A z');

  // Momentjs' diff calculation always rounds DOWN in days, so even if the expiry is 3.5 days away, this will just return 3.
  // Also, our expiry times are set to 11:59:59pm Pacific.
  const remainingDays = moment(invitation.expires_at).diff(
    moment.now(),
    'days',
  );

  // Show generic text if we don't have the candidate's name; we GENERALLY won't have a name, unless they already have an actual Report with us.
  const displayName = candidate.first_name || 'the candidate';

  return (
    <NonActionableBanner
      kind='info'
      title={t('banners.invitationSent.text', {
        displayName,
        displayDate,
        remainingDays,
        interpolation: { escapeValue: false },
      })}
    />
  );
};

export default InvitationSentBanner;

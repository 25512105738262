/* eslint-disable import/prefer-default-export */

import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePreference } from 'api/dashboardPreferences';
import { hideNavigation, isSmallScreenRoute } from './helpers';
import {
  FORCE_COLLAPSED_NAV_BREAKPOINT,
  FORCE_COLLAPSED_NAV_BREAKPOINT_EARLY,
  FORCE_HAMBURGER_NAV_BREAKPOINT,
} from '../constants';

/** Hook to contain the logic for whether the navbar is expanded or collapsed, based on a few different factors */
export const useNavCollapse = () => {
  const location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // For the sake of screen real estate, there are some specific routes manage the nav state:
  const routeForcesCollapse = hideNavigation(location.pathname); // These routes ALWAYS collapse the nav
  const routeCollapsesEarlier = isSmallScreenRoute(location.pathname); // These routes have an earlier breakpoint than the default where they'll collapse the nav

  // User's PREFERRED view
  const [preferCollapsed, savePreferCollapsed] =
    usePreference('leftNavCollapsed');

  // Decide whether to force collapsed view (for mobile views, or specific routes)
  const isCollapseForced = useMemo(() => {
    if (routeForcesCollapse) {
      return true;
    }
    if (routeCollapsesEarlier) {
      return windowWidth < FORCE_COLLAPSED_NAV_BREAKPOINT_EARLY;
    }

    return windowWidth < FORCE_COLLAPSED_NAV_BREAKPOINT;
  }, [routeForcesCollapse, routeCollapsesEarlier, windowWidth]);

  useEffect(() => {
    const resizeListener = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  // Merge preference + forced state for the "real" state
  const isCollapsed = useMemo(() => {
    return isCollapseForced ? true : preferCollapsed;
  }, [isCollapseForced, preferCollapsed]);

  return { isCollapsed, isCollapseForced, savePreferCollapsed };
};

export const useIsSmallScreenForHamburgerNavigation = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < FORCE_HAMBURGER_NAV_BREAKPOINT,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < FORCE_HAMBURGER_NAV_BREAKPOINT);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isSmallScreen;
};

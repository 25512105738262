import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

export const StyledPriceWrapper = styled.div`
  text-align: right;
  min-width: 80px;
`;

export const StyledStartingAtText = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

export const StyledPriceText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #000;
`;

export const StyledPriceVaries = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: right;
  color: ${colors.uiTextPrimaryLight};
`;

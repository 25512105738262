import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/candidate/locales';
import moment from 'moment';
import { useCandidate } from 'providers/Candidate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Exception } from 'types';
import { getExceptionDocumentTypes, getExpirationDaysMessage } from '../utils';
import Action from './action';

const SingleExceptionBanner = ({
  exceptions,
  exceptionStatus,
}: {
  exceptions: Exception[];
  exceptionStatus: 'resolved' | 'resolvable';
}) => {
  const candidate = useCandidate();
  const { t } = useTranslation();

  if (exceptions.length !== 1) return null;

  const exceptionIsResolved = exceptionStatus === 'resolved';
  const kind = exceptionIsResolved ? 'success' : 'warning';
  const dateFormat = exceptionIsResolved ? 'MMM D, YYYY h:mm A' : 'MMM D, YYYY';
  const expirationDaysMessage = getExpirationDaysMessage(exceptions);
  const exception = exceptions[0];
  const exceptionDocumentTypes = getExceptionDocumentTypes(exception);

  if (!exceptionDocumentTypes) return null;

  const getBannerTitle = () => {
    const allDocumentTypes = exceptionDocumentTypes.split(', ');

    // When the exception type is `criminal_screening_requirement`, there can be multiple document
    // types that are parsed from the comment. This allows the title to be more readable
    // and we will still display all the document types in the bodyNode.
    if (allDocumentTypes.length > 1) {
      return t(
        `${namespace}:report.exceptions.banners.${exceptionStatus}.multipleTitle`,
      );
    }
    return t(
      `${namespace}:report.exceptions.banners.${exceptionStatus}.singleTitle`,
      {
        exceptionDocumentTypes,
      },
    );
  };

  const bodyHtml = t(
    `${namespace}:report.exceptions.banners.${exceptionStatus}.singleBody`,
    {
      firstName: candidate?.first_name || 'the candidate',
      exceptionDocumentTypes,
      expirationDaysMessage,
      exceptionCompletedDate: moment(exception.resolved_at).format(dateFormat),
      interpolation: { escapeValue: false },
    },
  )
    .replace(
      /<1>(.*?)<\/1>/,
      `<a href='${exception.verification_uri}' target='_blank'>$1</a>`,
    )
    .replace(/<br\/>/g, '<br />');

  return (
    <M.ExpandableNotification
      title={getBannerTitle()}
      secondaryTitle={moment(exception.created_at).format(dateFormat)}
      bodyNode={
        <>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />
          {!exceptionIsResolved && <Action />}
        </>
      }
      kind={kind}
      initialExpanded
    />
  );
};

export default SingleExceptionBanner;

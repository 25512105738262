import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledLookbackModalBody = styled(M.ModalBody)`
  padding-bottom: 1rem !important;
  p {
    padding-right: 0;
  }
`;

export const StyledEmploymentVerificationDropdown = styled(M.Dropdown)`
  width: 375px;
  margin-bottom: 16px;
  @media (max-device-width: 530px) {
    width: 200px !important;
  }
  .cds--list-box__invalid-icon {
    z-index: 1 !important;
  }
  .cds--list-box__label {
    color: ${colors.slate06} !important;
    font-style: normal !important;
  }
`;

import { pick } from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { debug } from 'utils';
import {
  CurrentUser,
  GenericObject,
  isInternal,
} from '@dashboard-experience/utils';

import { PARAMS } from 'components/Search';

export const getSearchQuery = (params: any, account: any) => {
  const query = pick(params, PARAMS.whitelisted());
  query.not_pii_removed = true;
  if (query.q === '') query.q = '*';

  for (const param in query) {
    if (param in query) {
      const val = query[param];
      if (!val || (Array.isArray(val) && !val.length)) {
        // eliminate empty '' and []
        delete query[param];
      } else if (Array.isArray(val) && val.length) {
        query[param] = val.join(',');
      } else if (PARAMS.noneSelectValues.includes(param) && val === 'none') {
        query.missing = query.missing ? `${query.missing},${param}` : param;
        delete query[param];
      } else if (PARAMS.date.includes(param) && typeof val === 'string') {
        // url params need to be '-' separated to be backwards compatible with the legacy
        // implementation, in order to properly parse the date it needs to be '/' separated
        const d = val.split('-').join('/');
        const date = new Date(d);

        const dateParam =
          param === 'dob'
            ? moment(date).format('YYYY-MM-DD')
            : date.toISOString();
        query[param] = dateParam;
      }
    }
  }

  let queryWithSecondaryOwnership = null;
  if (account?.business_entities?.length) {
    queryWithSecondaryOwnership = {
      ...query,
      secondary_ownership: JSON.stringify(account.business_entities),
    };
  }

  debug('Search query update:', query);

  return qs.stringify(queryWithSecondaryOwnership || query);
};

export const searchParamsWithArchived = (
  currentUser: CurrentUser,
  searchParams: GenericObject,
) => {
  let modifiedSearchParams = {};
  if (isInternal(currentUser)) {
    modifiedSearchParams = {
      archived: 'all',
      ...searchParams,
    };
  } else {
    switch (sessionStorage.getItem('resultsContentType')) {
      case 'true':
        modifiedSearchParams = {
          archived: 'true',
          ...searchParams,
        };
        break;
      case 'false':
        modifiedSearchParams = {
          archived: 'false',
          ...searchParams,
        };
        break;
      case 'all':
        modifiedSearchParams = searchParams;
        break;
      default:
        modifiedSearchParams = {
          archived: 'false',
          ...searchParams,
        };
    }
  }

  return modifiedSearchParams;
};

import { useCallback, useEffect, useState } from 'react';
import { AnyQueryKey, queryCache, useMutation, useQuery } from 'react-query';

import { Namespace } from 'modules/assess/api';
import * as API from 'modules/assess/api/assignables';
import { update } from 'modules/assess/api/ruleset/assignments';
import * as rulesets from 'modules/assess/api/rulesets';
import { ID, asID } from 'modules/entity';
import * as Assignment from 'modules/assess/models/rulesets/assignment';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import { Base } from 'modules/assess/models/assignables';
import { Category } from 'modules/assess/models/assignables/base';
import {
  SelectedAssignments,
  AssignmentV2,
} from 'modules/assess/models/rulesets/assignment';
import { useNamespace } from '../../router/context';
import * as Types from './types';

export const useAssignments = (
  ruleset?: Ruleset.Type,
): [Assignment.List, React.Dispatch<React.SetStateAction<Assignment.List>>] => {
  const [assignments, setAssignments] = useState<Assignment.List>([]);

  useEffect(() => {
    if (ruleset?.assignments) {
      setAssignments(Object.values(ruleset?.assignments));
    }
  }, [ruleset]);

  return [assignments, setAssignments];
};

export const PAGE_SIZE = 5;

export const usePersisted = () => {
  const [persisted, setPersisted] = useState<Assignment.Map>({});
  const [page, setPage] = useState(1);
  const search = useSearch({ page, per_page: PAGE_SIZE });

  const { data = [] } = search.data || {};
  const more = data.length === PAGE_SIZE;

  useEffect(() => {
    if (data.length) {
      const map = data.reduce<Assignment.Map>((m, d) => {
        m[d.id] = d;
        return m;
      }, {});
      setPersisted(state => ({ ...state, ...map }));
    }
    if (more) {
      setPage(state => state + 1);
    }
  }, [data, more]);

  return persisted;
};

export const useSearch = (params?: API.SearchParams) => {
  const context = useNamespace() || Namespace.criminal;
  const namespace = API.path(context);
  const key: AnyQueryKey = [namespace, { id: undefined }, params];

  const request = () => {
    return API.search(context, params);
  };

  return useQuery(key, request);
};

export const useSelector = (
  assignables: Assignment.List | undefined,
  setAssignments?: React.Dispatch<React.SetStateAction<Assignment.List>>,
) => {
  const onSelect = useCallback(
    (assignee: string, selected: boolean) => {
      setAssignments?.(state => {
        if (selected) {
          const assignable = assignables?.find(a => a.id === assignee);
          if (assignable) {
            state = state.concat(assignable);
          }
          return state;
        }
        return state.filter(a => a.id !== assignee);
      });
    },
    [assignables, setAssignments],
  );

  return onSelect;
};

export const useUpdate = (id?: ID) => {
  const context = useNamespace() || Namespace.criminal;

  const request = (assignments: any) => {
    if (!id) {
      return Promise.reject();
    }
    return update(context, id, assignments);
  };

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      queryCache.invalidateQueries(({ queryKey: [ns, entity] }) => {
        // Refetch namespace queries if
        // * the scope is not another entity
        // * the mutation did not return an updated object

        const match = Object.values(Namespace)
          .map(n => rulesets.uri(n))
          .includes(ns as string);
        if (!match) {
          return false;
        }

        if (typeof entity !== 'object') {
          return true;
        }

        return !(entity as { id: unknown }).id;
      });
    },
  });

  return {
    call,
    result,
  };
};

export const useSelectionUpdate = (
  ruleset: ID | undefined,
  assignments: Assignment.Map | undefined,
  setSelections: (value: React.SetStateAction<Types.Selections>) => void,
) => {
  useEffect(() => {
    const selected = Object.values(assignments || []).reduce<Types.Selections>(
      (map, assignment) => {
        const assigned = asID(assignment.ruleset);

        if (assigned === ruleset) {
          map[assignment.id] = true;
        }

        return map;
      },
      {},
    );

    // merge the current state with updates but don't overwrite anything
    setSelections(state => ({ ...selected, ...state }));
  }, [assignments, ruleset, setSelections]);
};

export const filter = (
  assignments: Array<Assignment.Type>,
  assignables: Array<Assignment.Type> | undefined,
  key: Types.FilterKey,
  show: Types.FilterShow,
  type: Types.FilterType,
  selections: Types.Selections,
) => {
  let items = assignables;

  if (show === 'selected') {
    items = assignments;
  }

  const regexp = new RegExp(key || '', 'i');

  return items?.filter(item => {
    if (key && !item.id?.match(regexp) && !item.name?.match(regexp)) {
      return false;
    }
    if (show === 'selected' && !selections[item.id]) {
      return false;
    }
    if (show === 'unassigned' && item.ruleset) {
      return false;
    }
    if (type && item.type !== type) {
      return false;
    }
    return true;
  });
};

export const categorizeAssignment = (
  assignment: Assignment.Type,
): Category[] => {
  const categories: Category[] = [];

  if (Base.Kind.GEO in assignment) {
    categories.push('geo');
  }
  if (Base.Kind.SEGMENT in assignment) {
    categories.push('segment');
  }
  if (Base.Kind.ROLE in assignment) {
    categories.push('role');
  }
  if (Base.Kind.PACKAGE in assignment) {
    categories.push('package');
  }
  if (Base.Kind.STATE in assignment) {
    categories.push('state');
  }
  if (assignment.default) {
    categories.push('account');
  }

  return categories;
};

export const getInitialSelections = (assignments: Assignment.List) => {
  const initiallySelected: SelectedAssignments = {
    account: [],
    geo: [],
    segment: [],
    package: [],
    role: [],
    state: [],
  };

  assignments.forEach((assignment: Assignment.Type) => {
    const categories = categorizeAssignment(assignment);

    categories.forEach(category => {
      const categoryItems = initiallySelected[category];
      const assignmentCategory = (assignment as any)[category];

      if (category === 'account') {
        categoryItems.push(assignment);
      } else if (
        !categoryItems.some(selected => selected.id === assignmentCategory.id)
      ) {
        categoryItems.push(assignmentCategory);
      }
    });
  });

  return initiallySelected;
};

export const createAssignmentCombinations = (data: SelectedAssignments) => {
  const { geo, role, package: pkg, segment, state, account } = data;

  const createItemsArray = (items: any[]) =>
    items.length > 0 ? items : [{ id: '', name: '', metadata: {} }];

  const accountItems = createItemsArray(account);
  const geoItems = createItemsArray(geo);
  const roleItems = createItemsArray(role);
  const packageItems = createItemsArray(pkg);
  const segmentItems = createItemsArray(segment);
  const stateItems = createItemsArray(state);

  const validCombinations: AssignmentV2[] = [];

  const addCombination = (componentItems: any[], includeGeo: boolean) => {
    componentItems.forEach(componentItem => {
      roleItems.forEach(roleItem => {
        packageItems.forEach(packageItem => {
          segmentItems.forEach(segmentItem => {
            const combination: any = {
              metadata: {},
            };

            if (includeGeo && componentItem.id) {
              combination.geo = {
                id: componentItem.id,
                name: componentItem.name,
              };
              combination.metadata = {
                ...combination.metadata,
                geo_name: componentItem?.name,
              };
            } else if (!includeGeo && componentItem.id) {
              combination.state = {
                id: componentItem.id,
                name: componentItem.name,
              };
              combination.metadata = {
                ...combination.metadata,
                state_name: componentItem?.name,
              };
            }
            if (roleItem.id) {
              combination.role = { id: roleItem.id, name: roleItem.name };
              combination.metadata = {
                ...combination.metadata,
                role_name: roleItem?.name,
              };
            }
            if (packageItem.id) {
              combination.package = {
                id: packageItem.id,
                name: packageItem.name,
              };
              combination.metadata = {
                ...combination.metadata,
                package_name: packageItem?.name,
              };
            }
            if (segmentItem.id) {
              combination.segment = {
                id: segmentItem.id,
                name: segmentItem.name,
              };
              combination.metadata = {
                ...combination.metadata,
                segment_name: segmentItem?.name,
              };
            }

            validCombinations.push(combination);
          });
        });
      });
    });
  };

  /*
   * Create combinations with geo, role, package, segment
   * This case always runs even if no geo or state is selected
   */
  if (
    role.length > 0 ||
    pkg.length > 0 ||
    segment.length > 0 ||
    geo.length > 0
  ) {
    addCombination(geoItems, true);
  }

  /*
   * Create combinations with state, role, package, segment
   * This case runs only if a state is selected to avoid duplicate combinations
   */
  if (state.length > 0) {
    addCombination(stateItems, false);
  }

  if (accountItems.length > 0 && accountItems[0].id === 'ACCOUNT_DEFAULT_ID') {
    validCombinations.push({ default: true });
  }
  return validCombinations;
};

export const getInitialItem = (
  items: SelectedAssignments,
  type: Base.Kind | undefined,
) => {
  if (!type) return [];
  const filteredAssignments = items[type as keyof SelectedAssignments];
  return mapToItems(Object.values(filteredAssignments));
};

export const mapToItems = (items: { [key: string]: any }) => {
  return items.map((item: { id: string; name: string }) => ({
    id: item.id?.toString(),
    label: item.name,
  }));
};

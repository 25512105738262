import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { usePostPaymentMethod } from 'api/payment';
import { useHistory } from 'react-router-dom';
import {
  useTrackEvent,
  ORDER_BACKGROUND_CHECK_PARTNER_PAYMENT,
} from 'utils/analytics';
import { useTranslation } from 'react-i18next';
import AddPaymentModalBody from '../../../Account/Payment/AddPaymentModalBody';
import {
  StyledComposedModal,
  StyledContentSwitcher,
} from './AddPaymentDetailsModal.styles';
import { namespace } from '../../locales';

const AddPaymentDetailsModal: React.FC<{
  isPaymentDetailsModalOpen: boolean;
  setIsPaymentDetailsModalOpen: (isPaymentDetailsModalOpen: boolean) => void;
  hasPermission: boolean;
  setIsPaymentDetailsAdded: Function;
}> = ({
  isPaymentDetailsModalOpen,
  setIsPaymentDetailsModalOpen,
  hasPermission,
  setIsPaymentDetailsAdded,
}) => {
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const trackEvent = useTrackEvent();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [cardElementOptions, setCardElementOptions] = useState<string>();
  const [bankElementOptions, setBankElementOptions] = useState<string>();
  const [paymentType, setPaymentType] = useState<'us_bank_account' | 'card'>(
    'card',
  );

  const { postPaymentMethodCall, postPaymentMethodResult } =
    usePostPaymentMethod();

  useEffect(() => {
    if (isPaymentDetailsModalOpen) {
      trackEvent(
        ORDER_BACKGROUND_CHECK_PARTNER_PAYMENT.CHECKR_DASHBOARD_ORDER_BGC_PARTNER_PAYMENT_MODAL_VIEWED,
        {
          'Have permission to add payment details': hasPermission
            ? 'Yes'
            : 'No',
        },
      );
    }
  }, [trackEvent, isPaymentDetailsModalOpen, hasPermission]);

  useEffect(() => {
    if (postPaymentMethodResult.isSuccess) {
      setIsPaymentDetailsAdded(true);
      trackEvent(
        ORDER_BACKGROUND_CHECK_PARTNER_PAYMENT.CHECKR_DASHBOARD_PARTNER_PAYMENT_ENTERED,
      );
    }
  }, [trackEvent, postPaymentMethodResult.isSuccess, setIsPaymentDetailsAdded]);

  const onTabSwitch = useCallback(({ name }) => {
    setPaymentType(name);
  }, []);

  const onModalClose = useCallback(
    () => setIsPaymentDetailsModalOpen(false),
    [setIsPaymentDetailsModalOpen],
  );

  const onBackButtonClick = useCallback(() => {
    setIsPaymentDetailsModalOpen(false);
    history.push('/');
  }, [history, setIsPaymentDetailsModalOpen]);

  if (!hasPermission) {
    return (
      <M.ComposedModal
        style={{ zIndex: 999999 }}
        type='small'
        open={isPaymentDetailsModalOpen}
        onClose={onModalClose}
      >
        <M.ModalHeader
          className='fer'
          title={t('getStarted.addPaymentDetailsModal.title')}
          buttonOnClick={onModalClose}
        />
        <M.ModalBody>
          <p>
            {t('getStarted.addPaymentDetailsModal.description')}
            {t('getStarted.addPaymentDetailsModal.lackOfPermission')}
          </p>
        </M.ModalBody>
        <M.ModalFooter
          actions={{
            primary: {
              name: 'Close',
              onClick: onModalClose,
            },
            secondary: {
              name: 'Go back',
              onClick: onBackButtonClick,
            },
          }}
        />
      </M.ComposedModal>
    );
  }

  return (
    <StyledComposedModal
      style={{ zIndex: 999999 }}
      open={isPaymentDetailsModalOpen}
      data-testid='add-payment-modal'
      onClose={onModalClose}
    >
      <M.LoadingSpinner active={isProcessing} />
      <M.ModalHeader buttonOnClick={onModalClose}>
        <h2>{t('getStarted.addPaymentDetailsModal.title')}</h2>
      </M.ModalHeader>
      <M.ModalBody>
        <p>{t('getStarted.addPaymentDetailsModal.description')}</p>
        <StyledContentSwitcher onChange={onTabSwitch}>
          <M.Switch name='card' text='Credit or debit card' />
          <M.Switch name='us_bank_account' text='Bank account' />
        </StyledContentSwitcher>
        <AddPaymentModalBody
          key={paymentType}
          error={error}
          setError={setError}
          processing={isProcessing}
          setIsProcessing={setIsProcessing}
          elementOptions={
            paymentType === 'card' ? cardElementOptions : bankElementOptions
          }
          setElementOptions={
            paymentType === 'card'
              ? setCardElementOptions
              : setBankElementOptions
          }
          paymentType={paymentType}
          hideModal={onModalClose}
          postPaymentMethod={{
            postPaymentMethodCall,
            postPaymentMethodResult,
          }}
          isOrder
        />
      </M.ModalBody>
    </StyledComposedModal>
  );
};

export default AddPaymentDetailsModal;

export enum ScreeningProductType {
  FEDERAL_CRIMINAL_SEARCH = 'federal_criminal_search',
  COUNTY_CIVIL_SEARCH = 'county_civil_search',
  PROFESSIONAL_REFERENCE_VERIFICATION = 'professional_reference_verification',
  CREDIT_REPORT = 'credit_report',
  FEDERAL_CIVIL_SEARCH = 'federal_civil_search',
  EMPLOYMENT_VERIFICATION = 'employment_verification',
  COUNTY_CRIMINAL_SEARCH = 'county_criminal_search',
  EDUCATION_VERIFICATION = 'education_verification',
  STATE_CRIMINAL_SEARCH = 'state_criminal_search',
  GLOBAL_WATCHLIST_SEARCH = 'global_watchlist_search',
  FACIS_SEARCH = 'facis_search',
  MOTOR_VEHICLE_REPORT = 'motor_vehicle_report',
  PROFESSIONAL_LICENSE_VERIFICATION = 'professional_license_verification',
  PERSONAL_REFERENCE_VERIFICATION = 'personal_reference_verification',
  INTERNATIONAL_ADVERSE_MEDIA_SEARCH = 'international_adverse_media_search',
  INTERNATIONAL_CRIMINAL_SEARCH = 'international_criminal_search_v2',
  DRUG_SCREENING = 'drug_screening',
  OCCUPATIONAL_HEALTH_SCREENING = 'occupational_health_screening',
  SSN_TRACE = 'ssn_trace',
  SEX_OFFENDER_SEARCH = 'sex_offender_search',
  NATIONAL_CRIMINAL_SEARCH = 'national_criminal_search',
}

export enum CreditReportStatus {
  DISABLED = 'disabled',
  PENDING = 'pending',
  ENABLED = 'enabled',
}

export enum OccHealthStatus {
  DISABLED = 'disabled',
  PENDING = 'pending',
  ENABLED = 'enabled',
}

export enum DrugTestStatus {
  DISABLED = 'disabled',
  PENDING = 'pending',
  ENABLED = 'enabled',
}

import { APIClient, API, BillingAPI, API_EU, AmsAPI } from 'utils/APIClient';
import { SEARCH_API_BASE_URL, I9_API_BASE_URL } from 'Constants';
import {
  ACCOUNTS_URL_BASE,
  SAVED_SEARCH_URL_BASE,
  VERIFICATIONS_URL_BASE,
  ACCOUNT_SERVICE_URL_BASE,
  REPORTS_URL_BASE,
  AI_EXPLAINER_URL_BASE,
  DRIVING_JURISDICTIONS_URL_BASE,
} from './constants';

export const Client = API;
export const AccountsClient = new APIClient(ACCOUNTS_URL_BASE);
export const DrivingJurisdictionsClient = new APIClient(
  DRIVING_JURISDICTIONS_URL_BASE,
);
export const SavedSearchesClient = new APIClient(SAVED_SEARCH_URL_BASE);
export const VerificationsClient = new APIClient(VERIFICATIONS_URL_BASE);
export const ReportsClient = new APIClient(REPORTS_URL_BASE);
export const BillingClient = BillingAPI;
export const EULogsClient = API_EU;
export const AmsClient = AmsAPI;
export const SearchClient = new APIClient(SEARCH_API_BASE_URL);
export const I9VerificationsClient = new APIClient(I9_API_BASE_URL);

export const AccountServiceClient = new APIClient(ACCOUNT_SERVICE_URL_BASE);
export const AiExplainerClient = new APIClient(AI_EXPLAINER_URL_BASE);

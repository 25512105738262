import React from 'react';
import { Geo } from '@dashboard-experience/utils';
import type { GetStartedT } from 'components/BetterOrderExperience';
import { Account, BillingCustomer } from 'types';
import { BillingEntity } from 'types/Billing';

// Temp fix for incorrect type
// TODO: Remove this once Account type is fixed
type AccountT = Account & {
  id?: string;
};

export type ContextType = {
  account: AccountT;
  geos: Geo[];
  nodes: GetStartedT.Node[];
  nodesIsLoading: boolean;
  programs: GetStartedT.Program[];
  payment_profiles: BillingEntity[];
  billing_entity_enabled: boolean;
  handleNodeSearchTextChange: (searchText: string) => void;
  nodeSearchText: string;
  billing_preferences: BillingCustomer;
};

export const AccountContext = React.createContext<ContextType>({
  account: {},
  geos: [],
  nodes: [],
  nodesIsLoading: false,
  programs: [],
  payment_profiles: [],
  billing_entity_enabled: false,
  handleNodeSearchTextChange: () => {},
  nodeSearchText: '',
  billing_preferences: {} as BillingCustomer,
});

export default AccountContext;

import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddButtonContainer } from 'components/AddScreenings/shared/ListItemElements';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import { namespace } from 'components/BetterOrderExperience/locales';
import { StyledFlex } from '../../AddonsStep.styles';
import { ScreeningSectionItem } from '../ScreeningSectionItem';
import ScreeningSectionItemButton from '../ScreeningSectionItem/ScreeningSectionItemButton';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { AddonsT } from '../..';
import LookbackInfoModal from './LookbackInfoModal';
import { StyledEmploymentVerificationDropdown } from './VerificationSection.styles';
import { LOOKBACK_OPTIONS } from './VerificationSection.const';
import OutOfCountryHistory from './OutOfCountryHistory';

type Props = {
  id: string;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  price: string;
  isAdded: boolean;
  isIncluded: boolean;
  selectedAdditionalProperties?: AddonsT.EmploymentVerificationProps;
};

const EmploymentVerification: React.FC<Props> = ({
  id,
  price,
  isAdded,
  isIncluded,
  onAddClick,
  onRemoveClick,
  selectedAdditionalProperties,
}) => {
  const selectedLookbackYears = selectedAdditionalProperties?.lookback_years;
  const selectedMaxAllowedEmployers =
    selectedAdditionalProperties?.maximum_allowed_employers;
  const selectedInternationalUpgrade =
    selectedAdditionalProperties?.international_upgrade;

  const [openLookbackInfoModal, setOpenLookbackInfoModal] = useState(false);
  const [outOfCountryHistoryChecked, setOutOfCountryHistoryChecked] = useState(
    selectedInternationalUpgrade ?? false,
  );

  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.verificationSection.employmentVerification',
  });

  const defaultOption = LOOKBACK_OPTIONS[7];

  // 10 years/ current is the only option with a maximum allowed employers
  const isCurrentLookbackSelected =
    selectedLookbackYears === 10 && selectedMaxAllowedEmployers === 1;

  const selectedOption = isCurrentLookbackSelected
    ? LOOKBACK_OPTIONS.current
    : LOOKBACK_OPTIONS[selectedLookbackYears as keyof typeof LOOKBACK_OPTIONS];

  const initialSelectedOption = selectedLookbackYears
    ? selectedOption
    : defaultOption;

  const handleAdd = useCallback(
    (properties?: AddonsT.EmploymentVerificationProps) => {
      const additionalProperties = {
        lookback_years: selectedLookbackYears ?? defaultOption.lookback_years,
        maximum_allowed_employers: selectedMaxAllowedEmployers,
        international_upgrade: outOfCountryHistoryChecked,
        dot_compliance: false,
      };
      if (!properties) {
        onAddClick(ScreeningProductType.EMPLOYMENT_VERIFICATION, price, {
          employment_verification: additionalProperties,
        });
      } else {
        onAddClick(ScreeningProductType.EMPLOYMENT_VERIFICATION, price, {
          employment_verification: { ...additionalProperties, ...properties },
        });
      }
    },
    [
      defaultOption.lookback_years,
      onAddClick,
      outOfCountryHistoryChecked,
      selectedLookbackYears,
      selectedMaxAllowedEmployers,
      price,
    ],
  );

  const handleAddButtonClick = useCallback(() => {
    handleAdd();
  }, [handleAdd]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(ScreeningProductType.EMPLOYMENT_VERIFICATION);
  }, [onRemoveClick]);

  const handleDropdownChange = useCallback(
    ({ selectedItem }) => {
      handleAdd({
        lookback_years: selectedItem.lookback_years,
        maximum_allowed_employers:
          selectedItem.maximum_allowed_employers ?? null,
        international_upgrade: false,
        dot_compliance: false,
      });

      setOutOfCountryHistoryChecked(false);
    },
    [handleAdd],
  );

  const handleOutOfCountryHistoryCheck = useCallback(
    e => {
      const { checked } = e.target;
      setOutOfCountryHistoryChecked(checked);

      handleAdd({ international_upgrade: checked });
    },
    [handleAdd],
  );

  const handleLookbackPeriodLinkClick = useCallback(() => {
    setOpenLookbackInfoModal(true);
  }, []);

  const getLabel = useCallback(employmentVerificationScreening => {
    return employmentVerificationScreening.label;
  }, []);

  const advantages = useMemo(() => {
    const baseAdvantages: string[] = t('advantages', { returnObjects: true });
    const advantages7Year: string = t('advantages7Year', {
      returnObjects: true,
    });
    const advantagesCurrentAnd10Year: string = t('advantagesCurrentAnd10Year', {
      returnObjects: true,
    });
    const lookbackYears = selectedAdditionalProperties?.lookback_years;
    if (lookbackYears === 7) {
      return [advantages7Year, ...baseAdvantages];
    }
    if (lookbackYears === 10) {
      return [advantagesCurrentAnd10Year, ...baseAdvantages];
    }
    return baseAdvantages;
  }, [selectedAdditionalProperties, t]);

  const considerations = useMemo(() => {
    const baseConsiderations: string[] = t('considerations', {
      returnObjects: true,
    });
    const considerations10Year: string = t('considerations10Year', {
      returnObjects: true,
    });
    const considerations3And5Year: string = t('considerations3And5Year', {
      returnObjects: true,
    });
    const lookbackYears = selectedAdditionalProperties?.lookback_years;
    if (lookbackYears === 10) {
      return [considerations10Year, ...baseConsiderations];
    }
    if (lookbackYears === 3 || lookbackYears === 5) {
      return [considerations3And5Year, ...baseConsiderations];
    }
    return baseConsiderations;
  }, [selectedAdditionalProperties, t]);

  return (
    <StyledFlex flexDirection='column'>
      <StyledFlex>
        <LookbackInfoModal
          open={openLookbackInfoModal}
          setOpen={setOpenLookbackInfoModal}
        />
        <ScreeningSectionItem
          id={id}
          description={
            <>
              {t('description')}{' '}
              <LearnMoreLink
                style={{ textDecoration: 'underline' }}
                data-testid='employment-verification-learn-more-open-dialog'
                onClick={handleLookbackPeriodLinkClick}
                linkText='a lookback period'
              />
              .
            </>
          }
          advantages={advantages}
          disadvantages={considerations}
          price={price}
          screeningName={t('name')}
        />
        <AddButtonContainer>
          <ScreeningSectionItemButton
            id={id}
            onAddClick={handleAddButtonClick}
            onRemoveClick={handleRemoveClick}
            added={isAdded}
            disabled={false}
            included={isIncluded}
          />
        </AddButtonContainer>
      </StyledFlex>
      <StyledFlex flexDirection='column'>
        <StyledEmploymentVerificationDropdown
          data-testid='employment-verification-dropdown'
          label='Select employment verification'
          titleText=''
          id='employment-verification-dropdown'
          items={Object.values(LOOKBACK_OPTIONS)}
          itemToString={getLabel}
          initialSelectedItem={initialSelectedOption}
          onChange={handleDropdownChange}
          disabled={isIncluded}
        />
        <OutOfCountryHistory
          onChange={handleOutOfCountryHistoryCheck}
          checked={outOfCountryHistoryChecked}
          screeningType={ScreeningProductType.EMPLOYMENT_VERIFICATION}
        />
      </StyledFlex>
    </StyledFlex>
  );
};

export default EmploymentVerification;

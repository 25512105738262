import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { capitalize, split, trim } from 'lodash';
import Timeline from './Timeline';
import { DrugHealthProps } from './types';

export const splitTrimAndCapitalizeLines = (rawText: string) => {
  const textArray = split(rawText, '\n').filter(line => line.length > 0);
  return textArray.map(line =>
    React.createElement('p', {}, capitalize(trim(line))),
  );
};

const SubHeading = styled.div`
  strong {
    display: block;
    margin-bottom: 0.5rem;
  }
`;

const StyledScreening = styled(M.Screening)`
  .mastodon-screening-row:nth-child(n + 3) {
    margin-top: 1.25rem;
  }
`;

const DrugHealthScreening: React.FC<DrugHealthProps> = ({
  screening,
  reportStatusType,
  screeningTestId,
  title,
  disposition,
  timelineStatuses,
  timelineKey,
  children,
}) => {
  const showDisposition = Boolean(screening.disposition);
  const showTimeline = timelineStatuses?.length > 0;

  return (
    <StyledScreening
      title={title}
      screening={screening}
      statusType={reportStatusType}
      id={screeningTestId}
      data-testid={screeningTestId}
    >
      {children}

      {showDisposition && (
        <SubHeading data-testid='screenings-drug-health-disposition'>
          <strong>{disposition}</strong>
          <span>{capitalize(screening.disposition)}</span>
        </SubHeading>
      )}
      {Boolean(screening.mro_notes) && (
        <SubHeading data-testid='screenings-drug-health-mro-notes'>
          <div style={{ display: 'flex' }}>
            <strong>MRO Notes</strong>
            &nbsp;&nbsp;
            <M.Toggletip>
              <M.ToggletipButton label='Additional information'>
                <M.Icon icon='InformationFilled' />
              </M.ToggletipButton>
              <M.ToggletipContent>
                <p>
                  For detailed result definitions and recommended next steps
                  please review our{' '}
                  <M.Link
                    target='_blank'
                    href='https://help.checkr.com/s/article/4408098885015-Interpret-MRO-reviewed-drug-test-results'
                  >
                    MRO results guide.
                  </M.Link>
                </p>
              </M.ToggletipContent>
            </M.Toggletip>
          </div>

          <span>{splitTrimAndCapitalizeLines(screening.mro_notes)}</span>
        </SubHeading>
      )}
      {Boolean(screening.notes) && (
        <SubHeading data-testid='screenings-occ-health-notes'>
          <strong>Notes</strong>
          <span>{splitTrimAndCapitalizeLines(screening.notes)}</span>
        </SubHeading>
      )}
      {showTimeline && (
        <Timeline statuses={timelineStatuses} itemKey={timelineKey} />
      )}
    </StyledScreening>
  );
};
export default DrugHealthScreening;

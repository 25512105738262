import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import { useTranslation } from 'react-i18next';

import { BasePackage } from '../../types';
import IconCheckmark from '../BasePackages/IconCheckmark';
import {
  Container,
  Header,
  MostPopular,
  PackageCardBody,
  PriceContainer,
  ReportText,
  PackageName,
  PackageDescription,
  SelectedCheckContainer,
} from './styles';
import { HeaderHeightContext } from './PackageCardState';

type Props = {
  name: string;
  description: string;
  price: number;
  mostPopular?: boolean;
  selected?: boolean;
  children?: ReactElement;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  packageType: BasePackage;
  showCompleteText?: boolean;
};

const PackageCard = ({
  name,
  description,
  packageType,
  price,
  mostPopular = false,
  children,
  onClick = () => {},
  selected = false,
  showCompleteText = false,
}: Props) => {
  const { t } = useTranslation('signup');
  const headerRef = useRef<HTMLDivElement>(null);
  const { headerHeight, setHeaderHeight } = useContext(HeaderHeightContext);

  const darkText =
    packageType === 'checkrdirect_basic_plus_criminal' ||
    packageType === 'checkrdirect_international_basic_plus';

  const showHaveMostPopularClassName =
    (showCompleteText && !mostPopular) || (!showCompleteText && mostPopular);

  const shouldUpdateHeight =
    packageType === 'checkrdirect_basic_plus_criminal' ||
    packageType === 'checkrdirect_professional_criminal' ||
    packageType === 'checkrdirect_essential_criminal';

  useEffect(() => {
    const updateHeight = () => {
      if (
        headerRef.current &&
        packageType === 'checkrdirect_complete_criminal'
      ) {
        const currentHeight = headerRef.current.offsetHeight;
        setHeaderHeight(currentHeight);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [headerHeight, packageType, setHeaderHeight]);

  return (
    <Container
      className={`package-card ${
        showHaveMostPopularClassName ? 'most-popular' : ''
      }`}
      data-testid={`${name}-package-card-container`}
      selected={selected}
      onClick={onClick}
    >
      {showCompleteText && !mostPopular && (
        <MostPopular>{t(`components.PackageCard.mostCoverage`)}</MostPopular>
      )}
      {!showCompleteText && mostPopular && (
        <MostPopular>{t(`components.PackageCard.mostPopular`)}</MostPopular>
      )}
      <Header
        data-testid='package-card-header'
        packageType={packageType}
        selected={selected}
        ref={headerRef}
        height={shouldUpdateHeight ? headerHeight : undefined}
      >
        {selected && (
          <SelectedCheckContainer data-testid='selected-check-icon'>
            <IconCheckmark iconType={packageType} />
          </SelectedCheckContainer>
        )}
        <PackageName dark={darkText}>{name}</PackageName>
        <PackageDescription dark={darkText}>{description}</PackageDescription>
      </Header>
      <PackageCardBody>
        <PriceContainer>
          <h3 className='mb-0'>{getDollarAmount(price.toString())}</h3>{' '}
          <ReportText>{t(`components.PackageCard.slashReport`)}</ReportText>
        </PriceContainer>
        {children}
      </PackageCardBody>
    </Container>
  );
};

export default PackageCard;

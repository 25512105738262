import React, { useEffect } from 'react';
import { BackToGoodhireBanner } from 'components/Search';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, CANDIDATE_SEARCH_EVENT_NAMES } from 'utils/analytics';
import Header from './header';
import Provider from './state';
import SearchTableContainer from './search-table';

type Props = {};

const Page: React.FC<Props> = () => {
  const currentUser = useUser();
  const trackEvent = useTrackEvent();

  useEffect(() => {
    if (currentUser) {
      trackEvent(CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_PAGE_LANDED);
    }
  }, [currentUser, trackEvent]);

  return (
    <Provider currentUser={currentUser}>
      <div data-testid='reports-list-page'>
        <Header currentUser={currentUser} />
        <BackToGoodhireBanner />
        <SearchTableContainer />
      </div>
    </Provider>
  );
};

export default Page;

import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const BalanceText = styled.p`
  padding-bottom: 0px;
  margin-bottom: 0 !important;
  color: ${colors.uiTextPrimaryLight};
`;

const DotDivider: React.FC<{}> = () => (
  <M.Icon icon='DotMark' size='8px' color='rgba(26, 32, 38, 0.48)' />
);

const HelpIcon: React.FC<{}> = () => (
  <M.Icon icon='Information' size='12px' color='rgba(26, 32, 38, 0.48)' />
);

const ToolTip: React.FC<{ definition: string }> = ({ definition }) => (
  <M.TooltipDefinition
    highlighted={false}
    definition={definition}
    style={{ marginLeft: '0.25rem' }}
  >
    {' '}
    <HelpIcon />
  </M.TooltipDefinition>
);

type Props = {
  openBalance: any;
  showAccountBalance: boolean;
  credit: any;
  showCreditBalance: boolean;
};

const BalanceInformation: React.FC<Props> = ({
  openBalance,
  showAccountBalance,
  credit,
  showCreditBalance,
}) => {
  const { t } = useTranslation();

  return (
    <M.GridCol>
      <M.GridRow style={{ alignItems: 'center', gridGap: '0.5rem' }}>
        {showAccountBalance ? (
          <div>
            <BalanceText data-testid='billing-header-account-balance'>
              {t(`pageHeaders.billing.account_balance`, {
                open_balance: getDollarAmount(openBalance),
              })}
              <ToolTip
                definition={t(`pageHeaders.billing.account_balance_appliance`)}
              />
            </BalanceText>
          </div>
        ) : null}
        {showAccountBalance && showCreditBalance && <DotDivider />}
        {showCreditBalance ? (
          <div>
            <BalanceText data-testid='billing-header-credit'>
              {t(`pageHeaders.billing.available_credit`, {
                credit: getDollarAmount(credit),
              })}
              <ToolTip definition={t(`pageHeaders.billing.credit_appliance`)} />
            </BalanceText>
          </div>
        ) : null}
      </M.GridRow>
    </M.GridCol>
  );
};

export default BalanceInformation;

import querystring from 'querystring';
import {
  CurrentUser,
  GenericObject,
  Geo,
  PostPackageType,
} from '@dashboard-experience/utils';
import { BillingEntity } from 'types/Billing';
import type { GetStartedT } from '../GetStartedStep';

export type SelectionProps = {
  geo: Geo | null;
  node: GetStartedT.Node | null;
  program: GetStartedT.Program | null;
  country: string | null | undefined;
  city: string | null | undefined;
  state: string | null | undefined;
  package: PostPackageType;
  payment_profile: BillingEntity;
  purpose?: string;
};

export type ApplyUrlProps = {
  selection: SelectionProps;
  currentUser: CurrentUser;
};

export const getApplyUrl = ({ selection, currentUser }: ApplyUrlProps) => {
  if (!selection || !selection.package) return undefined;

  const { node, geo, country, city, state, package: basePackage } = selection;

  const base_url = basePackage.apply_url;
  const query = { order: currentUser.id } as GenericObject;
  if (!currentUser?.account?.segmentation_enabled && geo && geo.id !== 'None')
    query.geo_id = geo.id;
  if (node) query.node_id = node.custom_id;
  if (country) query.wl_country = country;
  if (state) query.wl_state = state;
  if (city) query.wl_city = city;
  return `${base_url}?${querystring.stringify(query)}`;
};

import React from 'react';
import { M } from '@dashboard-experience/mastodon';

const ApplicableLawsLink = () => (
  <M.Link
    href='https://help.checkr.com/s/article/360000144867-Disclosure-and-consent-for-background-checks'
    target='_blank'
  >
    applicable laws
  </M.Link>
);

export default ApplicableLawsLink;

import React, { SyntheticEvent } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Dialog } from 'modules/assess/ui/common';
import { useTranslation } from 'react-i18next';
import Content from './Content';
import { usePremium } from '../../hooks';

type Props = {
  open: boolean;
  onClose: (event?: SyntheticEvent) => void;
  onClick?: () => void;
  rulesetName?: string;
};

export const Container: React.FC<Props> = ({
  open,
  onClick,
  onClose,
  rulesetName,
}) => {
  const premiumMode = usePremium();
  const { t: tLite } = useTranslation('assess:v2');
  const { t: tPremium } = useTranslation('assess');
  const modalTitle = premiumMode
    ? tPremium('ruleset.apply.dialog.title')
    : tLite('review_and_apply.dialog.success');

  return (
    <Dialog
      data-testid='assess-v2-ruleset-version-review-and-apply-dialog'
      open={open}
      size='lg'
      onClose={onClose}
    >
      <M.ModalHeader
        data-testid='assess-v2-ruleset-version-review-and-apply-dialog-title'
        title={modalTitle}
        closeModal={onClose}
      />
      <M.ModalBody data-testid='assess-v2-ruleset-version-review-and-apply-dialog-content'>
        <Content onClick={onClick} rulesetName={rulesetName} />
      </M.ModalBody>
    </Dialog>
  );
};

export default Container;

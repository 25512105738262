import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import { AddButtonContainer } from 'components/AddScreenings/shared/ListItemElements';
import ScreeningSectionItemButton from '../ScreeningSectionItem/ScreeningSectionItemButton';
import {
  StyledSetupButton,
  StyledPendingContainer,
  StyledPendingIcon,
} from './DrugAndHealthSection.styles';
import { AddonsT } from '../..';
import { OccHealthStatus } from '../../AddonsStep.enums';

type Props = {
  id: string;
  onAddClick: () => void;
  isAdded: boolean;
  isIncluded: boolean;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  status: AddonsT.OccHealthStatusType;
  handleOpen: () => void;
};

const OccupationalHealthSetupButtons: React.FC<Props> = ({
  id,
  onAddClick,
  isAdded,
  isIncluded,
  onRemoveClick,
  status,
  handleOpen,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.drugAndHealth.ohs',
  });

  return (
    <>
      {status === OccHealthStatus.DISABLED && (
        <AddButtonContainer>
          <StyledSetupButton
            data-testid='occupational-health-disabled-button'
            onClick={handleOpen}
          >
            {t('disabled.text')}
          </StyledSetupButton>
        </AddButtonContainer>
      )}
      {status === OccHealthStatus.PENDING && (
        <StyledPendingContainer data-testid='occupational-health-pending'>
          <StyledPendingIcon icon='Renew' /> {t('pending')}
        </StyledPendingContainer>
      )}
      {status === OccHealthStatus.ENABLED && (
        <AddButtonContainer>
          <ScreeningSectionItemButton
            id={id}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            added={isAdded}
            disabled={false}
            included={isIncluded}
          />
        </AddButtonContainer>
      )}
    </>
  );
};

export default OccupationalHealthSetupButtons;

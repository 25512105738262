import React from 'react';
import { useUser } from 'context/CurrentUser';
import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import { useTranslation } from 'react-i18next';
import {
  StyledContainer,
  StyledDescription,
  StyledNewWindowMessage,
  StyledHereLink,
} from './ManualEntryStep.styles';
import { namespace } from '../locales';
import { getApplyUrl, SelectionProps } from '../utils/ManualEntryStepUtils';

const iframeStyle = {
  width: '100%',
  height: '100%',
  minHeight: '1000px',
};

const ManualEntryStep: React.FC<{}> = () => {
  const currentUser = useUser();
  const { t } = useTranslation(namespace);

  const { node, geo, program, paymentProfile, selectedPackage, location } =
    useBetterOrderExperienceContext();

  const selection: SelectionProps = {
    node,
    geo,
    program,
    country: location.country,
    city: location.city,
    state: location.state,
    package: selectedPackage,
    payment_profile: paymentProfile,
  };

  const applyUrl = getApplyUrl({ selection, currentUser });

  return (
    <div data-testid='manual-entry-step-container'>
      <StyledDescription>{t(`manualEntry.description`)}</StyledDescription>
      <StyledContainer>
        <StyledNewWindowMessage>
          {t(`manualEntry.newWindowMessage`)}
          <StyledHereLink
            data-testid='here-link-manual-order'
            href={applyUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            {t(`manualEntry.here`)}
          </StyledHereLink>
        </StyledNewWindowMessage>
      </StyledContainer>
      <iframe
        data-testid='iframe-manual-order'
        src={applyUrl}
        title='Manual Order'
        data-align='middle'
        style={iframeStyle}
      />
    </div>
  );
};

export default ManualEntryStep;

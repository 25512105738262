export const NAV_SIZES = {
  expanded: '264px',
  collapsed: '68px',
  hidden: '0px',
};

export const FORCE_COLLAPSED_NAV_BREAKPOINT = 768;
export const FORCE_COLLAPSED_NAV_BREAKPOINT_EARLY = 1200;
export const FORCE_HAMBURGER_NAV_BREAKPOINT = 1056;

export const NAV_COLLAPSE_TRANSITION = '0.4s ease-in-out';

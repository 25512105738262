import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PostPackageType } from '@dashboard-experience/utils';
import {
  StyledContainer,
  StyledTitle,
  StyledAddonsContainer,
} from './PopularAddOns.styles';
import { namespace } from '../../locales';
import AddOnScreeningCard from './AddOnScreeningCard';
import { ScreeningProductType } from '../AddonsStep.enums';
import type { AddonsT } from '../..';
import { getDollarAmount } from '../AddonsStep.utils';

type PopularAddOnsProps = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addOnPrices: AddonsT.AddonPrice[];
  includedScreenings: AddonsT.ScreeningType[];
  usingAddChecks?: boolean;
  selectedPackage: PostPackageType;
  additionalProperties: AddonsT.AdditionalProperties;
  addedScreeningTypes: AddonsT.ScreeningType[];
};

const PopularAddOns = ({
  usingAddChecks = false,
  addOnPrices,
  selectedPackage,
  additionalProperties,
  ...props
}: PopularAddOnsProps) => {
  const { t } = useTranslation('better_order_experience');
  const basePath = `${namespace}:addOns.popularAddOns`;

  const getPriceEmploymentVerification = useCallback(() => {
    const selectedPackageScreenings =
      selectedPackage?.screenings as AddonsT.PostPackageScreeningType[];
    const existingEmploymentVerificationScreening =
      selectedPackageScreenings.find(
        (screening: AddonsT.PostPackageScreeningType) =>
          screening.type === ScreeningProductType.EMPLOYMENT_VERIFICATION,
      );

    if (existingEmploymentVerificationScreening) {
      return 'Price varies';
    }

    const lookbackYears =
      additionalProperties?.employment_verification?.lookback_years || 7;
    const maximumAllowedEmployers =
      additionalProperties?.employment_verification
        ?.maximum_allowed_employers || null;
    const itemPrice = addOnPrices?.find(
      (item: AddonsT.AddonPrice) =>
        item.product_type === ScreeningProductType.EMPLOYMENT_VERIFICATION &&
        item.lookback_years === lookbackYears &&
        item.maximum_allowed_employers === maximumAllowedEmployers,
    );

    if (addOnPrices.length === 0) {
      return 'Loading price...';
    }

    return itemPrice ? getDollarAmount(itemPrice.base_price) : 'Price varies';
  }, [selectedPackage, additionalProperties, addOnPrices]);

  const getPriceByType = useCallback(
    (screeningType: string) => {
      if (screeningType === ScreeningProductType.EMPLOYMENT_VERIFICATION) {
        return getPriceEmploymentVerification();
      }

      const itemPrice = addOnPrices.find(
        (item: AddonsT.AddonPrice) => item.product_type === screeningType,
      );
      return itemPrice
        ? getDollarAmount(itemPrice.base_price)
        : 'Loading price...';
    },
    [addOnPrices, getPriceEmploymentVerification],
  );

  return (
    <StyledContainer>
      <StyledTitle data-testid='popular-add-ons-section-title'>
        {t(`${basePath}.title`, {
          addOnTerm: usingAddChecks ? 'checks' : 'searches',
        })}
      </StyledTitle>
      <StyledAddonsContainer>
        <AddOnScreeningCard
          screeningType={ScreeningProductType.MOTOR_VEHICLE_REPORT}
          getPriceByType={getPriceByType}
          {...props}
        />
        <AddOnScreeningCard
          screeningType={ScreeningProductType.EDUCATION_VERIFICATION}
          getPriceByType={getPriceByType}
          {...props}
        />
        <AddOnScreeningCard
          screeningType={ScreeningProductType.EMPLOYMENT_VERIFICATION}
          getPriceByType={getPriceByType}
          {...props}
        />
      </StyledAddonsContainer>
    </StyledContainer>
  );
};

export default PopularAddOns;

import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

import * as Entity from 'modules/assess/models/rulesets/ruleset';
import { Kind } from '../../models/entity';
import Assignments from '../ruleset/assignments';
import StatusByline from '../common/entity/status/byline';
import RulesetStats from './RulesetStats';
import { getDefault } from './data';

type Props = {
  ruleset: Entity.Type;
};

const Container = styled.div`
  margin-left: -0.5em;
`;

export const RulesetDetails: React.FC<Props> = ({ ruleset }) => {
  const active =
    ruleset?.active && typeof ruleset?.active === 'object'
      ? ruleset.active
      : undefined;
  let draft =
    ruleset?.draft && typeof ruleset?.draft === 'object'
      ? ruleset.draft
      : undefined;

  if (!draft) {
    draft = active;
  }

  return (
    <Container>
      <RulesetStats stats={ruleset?.stats} />
      <Assignments
        assignments={ruleset?.assignments}
        isdefaultAssignment={getDefault(ruleset)}
        withDetails
      />
      <VersionInfo>
        <StatusByline
          kind={Kind.final}
          time={active?.published_at && new Date(active.published_at)}
          user={active?.published_by}
        />
        <StatusByline
          kind={Kind.draft}
          time={draft?.updated_at && new Date(draft.updated_at)}
          user={draft?.updated_by}
          style={{ filter: 'opacity(0.5)' }}
        />
      </VersionInfo>
    </Container>
  );
};

const VersionInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.uiGrey400};
  padding: 1rem 0;
  margin-top: 1rem;
`;

export default RulesetDetails;

import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const StyledComposedModal = styled(M.ComposedModal)`
  #mastodon & .cds--modal-container {
    max-height: 90vh;
  }

  .StripeElement {
    width: 100%;
  }

  .justify-right {
    justify-content: end;
    padding-bottom: 1.5rem;
  }
`;

export const StyledContentSwitcher = styled(M.ContentSwitcher)`
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

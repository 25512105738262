import { useFlag } from '@dashboard-experience/react-flagr';
import React, { FC } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BetaState, useBetaState, useHideBeta } from 'providers/Beta';
import { CANDIDATE_REDESIGN, REPORTS_REDESIGN_EXCLUSIONS } from 'Flags';

const Container = styled.div`
  display: flex;
  gap: 16px;
  a {
    cursor: pointer;
  }
  align-items: center;
  justify-content: flex-end;

  #mastodon && {
    .feedback-button {
      color: ${colors.uiAqua600};
      padding: 6px 8px;
      font-size: 12px !important;
      font-weight: 600;
      min-height: 26px;
    }
  }
`;

const ReturnToLegacyBannerPhaseTwo: FC<{ overViewContainerOpen: boolean }> = ({
  overViewContainerOpen,
}) => {
  const { t } = useTranslation();
  const hideBeta = useHideBeta(CANDIDATE_REDESIGN);
  const betaState = useBetaState(CANDIDATE_REDESIGN);
  const redesignExcludedAccount =
    useFlag(REPORTS_REDESIGN_EXCLUSIONS)?.variantKey === 'disabled';

  // When we turn it off in the future, get rid of the legacy banner
  if (betaState === BetaState.DISABLED || !redesignExcludedAccount) return null;

  return (
    <Container className={overViewContainerOpen ? 'open' : 'closed'}>
      <M.Link
        className='p4'
        onClick={hideBeta}
        data-pendo-id='candidate-beta-switch-to-legacy'
        data-testid='return-to-legacy-banner-phase-two'
      >
        {t('beta.candidate_redesign.banner.legacy')}
      </M.Link>
    </Container>
  );
};

export default ReturnToLegacyBannerPhaseTwo;

import { DrivingJurisdictionsClient as client } from 'api';

export type AccessCodeParams = {
  accountId: string;
  abbr?: string;
  accessCode?: string;
};

export type DrivingJurisdictionsParams = {
  requires_customer_access_code?: boolean;
};

export type Jurisdiction = {
  id: number; // Alphabetical order (e.g. 44 for Utah)
  name: string;
  code: string; // The state abbrev (e.g. "UT")
  created_at: string;

  [key: string]: any;
};

export const getDrivingJurisdictions = (
  params: DrivingJurisdictionsParams,
): Promise<Jurisdiction[]> => {
  return client.get(`driving-jurisdictions`, {
    params,
  });
};

export const getAccessCode = (
  params: AccessCodeParams,
): Promise<{ [key: string]: any }> => {
  return client.get(
    `accounts/${params.accountId}/access-codes/${params.abbr}`,
    {
      params,
    },
  );
};

export const updateAccessCode = ({
  accountId,
  abbr,
  accessCode,
}: AccessCodeParams): Promise<{ [key: string]: any }> => {
  return client.put(`accounts/${accountId}/access-codes/${abbr}`, {
    abbr,
    access_code: accessCode,
  });
};

export const createAccessCode = ({
  accountId,
  abbr,
  accessCode,
}: AccessCodeParams): Promise<{ [key: string]: any }> => {
  return client.post(`accounts/${accountId}/access-codes`, {
    abbr,
    access_code: accessCode,
  });
};

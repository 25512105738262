import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SelectedAssignments } from 'modules/assess/models/rulesets/assignment';
import { Assignment } from 'modules/assess/models/rulesets';
import { Base } from 'modules/assess/models/assignables';
import { truncate } from 'lodash';

const ColHeader = styled.span`
  color: ${colors.textIconBlackPrimary};
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
`;

const RowItem = styled(M.ListItem)`
  color: ${colors.textIconBlackPrimary};
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
`;

const UnorderedList = styled(M.UnorderedList)`
  > ul {
    margin-left: 16px;
  }
`;

const GridCol = styled(M.GridCol)`
  margin-right: 24px;
`;

type HeaderAndListProps = {
  show: boolean;
  items: Assignment.List;
  headerKey: string;
};

type Props = {
  currentItems: SelectedAssignments;
};

type SelectedAssignmentsKeys = keyof SelectedAssignments;

const HeaderAndList: React.FC<HeaderAndListProps> = ({
  show,
  items,
  headerKey,
}) => {
  const { t } = useTranslation('');
  if (!show) return null;

  return (
    <GridCol sm={5}>
      <ColHeader data-testid={`${headerKey}-header`}>{t(headerKey)}</ColHeader>
      <UnorderedList nested>
        {items.map(item => (
          <RowItem key={item.id} data-testid={`${item.name}-item`}>
            {truncate(item.name, { length: 18 })}
          </RowItem>
        ))}
      </UnorderedList>
    </GridCol>
  );
};

export const AppliesTo: React.FC<Props> = ({ currentItems }) => {
  const { t } = useTranslation('assess');

  const headers: { key: SelectedAssignmentsKeys; label: string }[] = [
    { key: Base.Kind.GEO, label: 'assess:ruleset:apply:headers:geo' },
    { key: Base.Kind.SEGMENT, label: 'assess:ruleset:apply:headers:segment' },
    { key: Base.Kind.ROLE, label: 'assess:ruleset:apply:headers:role' },
    { key: Base.Kind.PACKAGE, label: 'assess:ruleset:apply:headers:package' },
    { key: Base.Kind.STATE, label: 'assess:ruleset:apply:headers:state' },
  ];

  return (
    <div data-testid='assess-ruleset-apply-applies-to-section'>
      <h4 data-testid='assess-ruleset-apply-applies-to-header'>
        {t('assess:ruleset:apply:headers:applies_to')}
      </h4>
      <M.Grid data-testid='assess-ruleset-apply-applies-to-grid'>
        <M.GridRow>
          {headers.map(({ key, label }) => (
            <HeaderAndList
              key={key}
              show={currentItems[key].length > 0}
              items={currentItems[key]}
              headerKey={label}
            />
          ))}
        </M.GridRow>
      </M.Grid>
    </div>
  );
};

export default AppliesTo;

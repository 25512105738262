import { M } from '@dashboard-experience/mastodon';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { Jurisdiction } from 'api/drivingJurisdictions/actions';

const AccessCodeInput = ({
  accessCode,
  jurisdiction,
  setAccessCodesInput,
}: {
  accessCode: string;
  jurisdiction: Jurisdiction;
  setAccessCodesInput: Dispatch<SetStateAction<Record<string, string | null>>>;
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAccessCodesInput(prev => ({
        ...prev,
        [jurisdiction.code]: e.target.value,
      }));
    },
    [setAccessCodesInput, jurisdiction.code],
  );

  const alignCenterVertical = { display: 'flex', alignItems: 'center' };

  return (
    <M.GridRow key={jurisdiction.id}>
      <M.GridCol style={alignCenterVertical}>
        <label htmlFor={jurisdiction.code}>{jurisdiction.name}: </label>
      </M.GridCol>
      <M.GridCol style={alignCenterVertical}>
        <M.Input
          type='text'
          id={jurisdiction.code}
          value={accessCode}
          placeholder='Enter access code'
          onChange={handleChange}
          style={{ marginBottom: 0 }}
        />
      </M.GridCol>
    </M.GridRow>
  );
};
export default AccessCodeInput;

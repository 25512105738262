import React from 'react';
import { CategoryTitle } from 'components/AddScreenings/shared/ListItemElements';
import {
  Icon,
  Accordion,
  AccordionItem,
  AccordionContent,
} from './ScreeningSection.styles';
import { ScreeningItemContainer } from '../ScreeningSectionItem/ScreeningSectionItem.styles';

type Props = {
  id: string;
  name: string;
  open: boolean;
  icon: string;
};

const ScreeningSection: React.FC<Props> = ({
  id,
  name,
  open = false,
  icon,
  children,
}) => {
  return (
    <Accordion>
      <AccordionItem
        open={open}
        title={
          <CategoryTitle data-testid={`${id}-section-title`}>
            <Icon src={icon} data-testid={`${id}-section-icon`} />
            {name}
          </CategoryTitle>
        }
      >
        <AccordionContent data-testid={`${id}-section-content`}>
          <ScreeningItemContainer>{children}</ScreeningItemContainer>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default ScreeningSection;

import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useArchiveReports } from 'api/archiveReport';
import { RealtimeContext } from 'containers/RealtimeProvider';
import { useUser } from 'context/CurrentUser';
import { ENABLE_ARCHIVE_REPORTS } from 'Flags';
import { namespace } from 'modules/candidate/locales';
import ArchiveModal from 'modules/candidate/ui/header/report-actions/v2/secondary-actions/menu-items/archive-modal';
import { useCandidate } from 'providers/Candidate';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Exception, Report } from 'types';
import {
  ARCHIVE_REPORTS_NAMES,
  scrollToTopIfIframe,
  useTrackEvent,
} from 'utils';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';
import {
  getOldestExceptionCreationDate,
  matchExceptionTypesToScreenings,
} from '../../utils';
import MultipleExceptionsMessageBody from './multiple-exceptions-message-body';
import SingleExceptionMessageBody from './single-exception-message-body';

const CanceledScreeningsBanners = ({
  unresolvedExceptions,
  report,
}: {
  unresolvedExceptions: Exception[];
  report: Report;
}) => {
  const { t } = useTranslation();
  const candidate = useCandidate();
  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();
  const currentUser = useUser();
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const archiveReportFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';

  const openArchiveModal = useCallback(() => {
    if (currentUser) {
      trackEvent(ARCHIVE_REPORTS_NAMES.ARCHIVE_REPORT_ACTION_SELECTED, {
        'Archive action': 'Archive report',
        Source: 'Secondary Actions',
        Report: reportProperties,
      });
    }

    setArchiveModalOpen(true);
    scrollToTopIfIframe();
  }, [currentUser, trackEvent, reportProperties]);

  const closeArchiveModal = useCallback(() => {
    setArchiveModalOpen(false);
  }, [setArchiveModalOpen]);

  const performAction = archiveReportFlagEnabled
    ? {
        label: t(
          `${namespace}:report.exceptions.banners.canceledScreenings.action`,
        ),
        action: openArchiveModal,
      }
    : null;

  const { call: archivedReportCall } = useArchiveReports();
  const { triggerRefetches } = useContext(RealtimeContext);

  const handleArchiveAction = useCallback(
    (setModalOpen, apiCall) => {
      setIsLoading(true);
      setModalOpen(false);

      apiCall({
        candidate_report_ids: [
          {
            candidate_id: report.candidate?.id as string,
            report_id: report.id,
          },
        ],
        user_resource_id: currentUser?.id as string,
      }).then(() => {
        triggerRefetches();
        setIsLoading(false);
      });
    },
    [report.candidate?.id, report.id, currentUser?.id, triggerRefetches],
  );

  const archive = useCallback(
    () => handleArchiveAction(setArchiveModalOpen, archivedReportCall),
    [handleArchiveAction, archivedReportCall],
  );

  const translateCanceledStatusToSuspended =
    report?.account?.translate_canceled_status_to_suspended;

  const screeningStatus: string = translateCanceledStatusToSuspended
    ? 'suspended'
    : 'canceled';

  const allCanceledScreenings = Object.entries(report).flatMap(([, value]) => {
    if (Array.isArray(value)) {
      // some screenings are an array of objects, like state_criminal_searches
      return value.filter(screening => screening?.status === screeningStatus);
    }
    return value?.status === screeningStatus ? [value] : [];
  });

  const customerRequestedCompleteNow = allCanceledScreenings.some(
    (screening: any) => {
      const cancelationReason = screening?.cancellation_reason || '';

      return cancelationReason === 'complete_now_customer_requested';
    },
  );

  if (customerRequestedCompleteNow) return null;

  const exceptionsWithCanceledScreenings = matchExceptionTypesToScreenings(
    unresolvedExceptions,
    allCanceledScreenings,
  );

  if (!exceptionsWithCanceledScreenings.length) return null;

  const { first_name } = candidate;

  const body =
    exceptionsWithCanceledScreenings.length === 1 ? (
      <SingleExceptionMessageBody
        exception={exceptionsWithCanceledScreenings[0]}
        firstName={first_name}
        totalCanceledScreenings={allCanceledScreenings.length}
        screeningStatus={screeningStatus}
      />
    ) : (
      <MultipleExceptionsMessageBody
        exceptions={exceptionsWithCanceledScreenings}
        firstName={first_name}
        totalCanceledScreenings={allCanceledScreenings.length}
        screeningStatus={screeningStatus}
      />
    );

  return (
    <>
      <M.ExpandableNotification
        title={t(
          `${namespace}:report.exceptions.banners.canceledScreenings.title`,
          {
            screeningStatus,
          },
        )}
        secondaryTitle={getOldestExceptionCreationDate(
          exceptionsWithCanceledScreenings,
        )}
        bodyNode={body}
        kind='warning'
        primaryAction={performAction}
        initialExpanded
      />
      <ArchiveModal
        isOpen={archiveModalOpen}
        closeModal={closeArchiveModal}
        archive={archive}
      />
      {isLoading && <M.LoadingSpinner />}
    </>
  );
};

export default CanceledScreeningsBanners;

import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { hasPermission, CurrentUser } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { UpdateInput } from 'pages/BetterOrderExperience/BetterOrderExperience.types';
import type { GetStartedT } from '..';
import { Header, RadioWrap, ChoiceCardGroup } from './ManualOrder.styles';

type Props = {
  currentUser: CurrentUser;
  manualOrderType?: GetStartedT.ManualOrderType;
  update: (values: UpdateInput) => void;
};

const ManualOrder: React.FC<Props> = ({
  currentUser,
  manualOrderType,
  update,
}) => {
  const { t } = useTranslation('better_order_experience', {
    keyPrefix: 'getStarted.manualOrders',
  });
  const singleSelected = manualOrderType === 'SINGLE';
  const multipleSelected = manualOrderType === 'MULTIPLE';

  const bulkOrderHistoryUrl = hasPermission(
    currentUser,
    'read_account_invoices',
  )
    ? '/billing/bulk-history'
    : '/bulk-order-history';

  const handleManualUploadChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // Update context directly so breadcrumbs reflect the correct steps
      update({
        infoSource: 'MYSELF',
        manualOrderType: e.target.value as GetStartedT.ManualOrderType,
      });
    },
    [update],
  );

  return (
    <div data-testid='manual-order-container' style={{ width: '100%' }}>
      <Header className='h4' data-testid='manual-orders-title'>
        {t('title')}
      </Header>
      <p className='p2'>{t('description')}</p>

      <ChoiceCardGroup data-testid='manual-order-radio-group'>
        <RadioWrap>
          <M.ChoiceCard
            key='SINGLE'
            id='SINGLE'
            data-testid='manual-order-radio-group-single'
            description={t('optionOne.text')}
            value='SINGLE'
            onClick={handleManualUploadChange}
            checked={manualOrderType === 'SINGLE'}
          />
        </RadioWrap>
        <RadioWrap>
          <M.ChoiceCard
            key='MULTIPLE'
            id='MULTIPLE'
            data-testid='manual-order-radio-group-multiple'
            description={t('optionTwo.text')}
            value='MULTIPLE'
            onClick={handleManualUploadChange}
            checked={manualOrderType === 'MULTIPLE'}
          />
        </RadioWrap>
      </ChoiceCardGroup>

      {singleSelected && <p className='p2'>{t('optionOne.description')}</p>}

      {multipleSelected && (
        <p className='p2' data-testid='bulk-manual-order-description'>
          {t('optionTwo.description')}{' '}
          <span className='bold'>{t('optionTwo.descriptionBolded')}</span>
          &gt;{' '}
          <M.Link
            data-testid='bulk-history-redirect'
            href={bulkOrderHistoryUrl}
            className='p2 bold'
          >
            {t('optionTwo.descriptionLink')}
          </M.Link>
        </p>
      )}
    </div>
  );
};

export default ManualOrder;

/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import { useFlag } from '@dashboard-experience/react-flagr';
import { EMAIL_VERIFICATION_SIGNUP } from 'Constants';
import {
  ModalHeader,
  ModalBody,
  StyledModal,
  TitleContainer,
  Subtitle,
} from './styles';
import SkipOrderModal from './SkipOrderModal';
import ProgressBar from '../ProgressBar';

type Props = {
  children: React.ReactNode;
  footer?: React.ReactNode;
  stepNumber?: number;
  bodyClassName?: string;
  hideHeader?: boolean;
};

const Modal = ({
  children,
  footer,
  stepNumber,
  bodyClassName,
  hideHeader,
}: Props) => {
  const { t } = useTranslation('signup');
  const history = useHistory();
  const trackEvent = useTrackEvent();
  const location = useLocation();

  const [showSkipModal, setShowSkipModal] = useState(false);

  const enableEmailVerification =
    useFlag(EMAIL_VERIFICATION_SIGNUP)?.variantKey === 'on';

  const handleSkipClick = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_ORDER_BGC_SKIP_ORDER_CLICKED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    setShowSkipModal(true);
  }, [trackEvent]);
  const handleSkipCancel = useCallback(() => setShowSkipModal(false), []);
  const handleSkipConfirm = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_ORDER_BGC_SKIP_ORDER_FLOW_COMPLETED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    if (enableEmailVerification) {
      history.push({
        pathname: '/signup/email-verification',
        search: location.search,
      });
    } else {
      history.push({
        pathname: '/login',
      });
    }
  }, [enableEmailVerification, history, location.search, trackEvent]);

  const searchParams = new URLSearchParams(window.location.search);
  const isGoodhireParam = searchParams.get('isGoodhire');
  const isGoodhireParent = isGoodhireParam === 'true';
  const isCreateAccountRoute =
    location.pathname === '/signup/create-account/' ||
    location.pathname === '/signup/create-account';

  const stepNames = [
    t(`pages.createAccountPage.breadcrumb`),
    t(`pages.packagesPage.breadcrumb`),
    t(`pages.candidateInfoPage.breadcrumb`),
  ];

  return (
    <>
      <StyledModal
        open
        onClose={() => false}
        type='large'
        containerClassName='signup-modal'
        data-dd-privacy='mask-user-input'
      >
        <ModalHeader
          title={t(`components.Modal.headerTitle`)}
          {...(isGoodhireParent && {
            subtitle: t(`components.Modal.headerSubtitle`),
          })}
        />
        <ModalBody className={`modal-body ${bodyClassName}`}>
          {!hideHeader && (
            <TitleContainer
              flexDirection={isCreateAccountRoute ? 'column' : 'row'}
            >
              <h2 className='mb-0'>
                {!isCreateAccountRoute
                  ? t(`components.Modal.title`)
                  : t(`components.Modal.createAccountPageTitle`)}
              </h2>
              {!isCreateAccountRoute ? (
                <M.Button
                  data-testid='skip-ordering-button'
                  kind='tertiary'
                  onClick={handleSkipClick}
                >
                  {t(`components.Modal.skipButton`)}
                </M.Button>
              ) : (
                <Subtitle data-testid='payment-subtitle'>
                  {t(`components.Modal.paymentSubtitle`)}
                </Subtitle>
              )}
            </TitleContainer>
          )}
          {stepNumber && (
            <ProgressBar stepNames={stepNames} activeStepNumber={stepNumber} />
          )}
          {children}
        </ModalBody>
        {footer}
      </StyledModal>
      <SkipOrderModal
        open={showSkipModal}
        onConfirm={handleSkipConfirm}
        onCancel={handleSkipCancel}
      />
    </>
  );
};

export default Modal;

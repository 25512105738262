import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

export const StyledView = styled.div`
  font-size: 14px;
  margin-top: 16px;
  color: ${colors.brandNavy1};
  cursor: pointer;
  display: flex;
`;

export const StyledIcon = styled(M.Icon)`
  padding-bottom: 3px;
`;

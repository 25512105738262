import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import successRulesetPublished from 'assets/success-ruleset-published.svg';
import { usePremium } from '../../hooks';

const Image = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 198px;
  height: 158.4px;
`;

const Heading = styled.div`
  color: ${colors.brandNavy3};
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 1.5em;
  text-align: center;
`;

const Body = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 1.5em;

  color: ${colors.brandNavy3};
`;

const SubHeadingDiv = styled.div`
  padding-bottom: 2em;
`;

const Container = styled.div`
  padding-right: 2.5em;
  padding-left: 2.5em;
  text-align: center;
`;

type Props = {
  rulesetName?: string;
  onClick?: () => void;
};

export const Content: React.FC<Props> = ({ onClick, rulesetName }) => {
  const { t: tLite } = useTranslation('assess:v2');
  const { t: tPremium } = useTranslation('assess');
  const translations: any = tLite('review_and_apply.dialog', {
    returnObjects: true,
  });

  const basePath = 'assess-v2-ui-guideline-review-and-apply-dialog-content';
  const headingId = `${basePath}-heading`;
  const subheadingId = `${basePath}-subheading`;
  const bodyId = `${basePath}-body`;
  const buttonId = `${basePath}-button`;

  const premiumMode = usePremium();

  const heading = premiumMode
    ? tPremium('ruleset.apply.dialog.heading', {
        ruleset_name: rulesetName,
      })
    : translations.heading;
  const subHeading = premiumMode
    ? tPremium('ruleset.apply.dialog.subheading')
    : translations.subheading;

  const buttonLabel = tPremium('ruleset.apply.dialog.button');

  return (
    <Container data-testid={basePath}>
      <Image src={successRulesetPublished} />
      <Heading data-testid={headingId}>{heading}</Heading>
      <Body data-testid={bodyId}>
        <SubHeadingDiv data-testid={subheadingId}>{subHeading}</SubHeadingDiv>
        {premiumMode ? (
          <M.Button data-testid={buttonId} kind='primary' onClick={onClick}>
            {buttonLabel}
          </M.Button>
        ) : (
          <Trans data-testid={bodyId}>{translations.body}</Trans>
        )}
      </Body>
    </Container>
  );
};

export default Content;

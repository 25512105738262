/* eslint-disable import/prefer-default-export */
import {
  formatScreeningPreferences,
  ScreeningSetting,
} from '@dashboard-experience/utils';
import { Screening } from 'types';

export const getScreeningSetting = (
  screening: Screening,
  screeningSettings: ScreeningSetting[],
) => {
  let setting = '';

  switch (screening.type) {
    case 'motor_vehicle_report': {
      if (screeningSettings.length > 0) {
        const mvrTypeSetting = screeningSettings?.find(
          mvrSettings => mvrSettings.name === 'mvr_type',
        );
        setting =
          mvrTypeSetting?.enabled && mvrTypeSetting?.value === 'commercial'
            ? 'Commercial MVR'
            : '';
        break;
      }

      if (screening.mvr_type) {
        const [enabled, value] = screening.mvr_type;
        setting = enabled && value === 'commercial' ? 'Commercial MVR' : '';
        break;
      }
      break;
    }
    case 'employment_verification':
      switch (true) {
        case screening?.preferences?.international_upgrade:
          setting = 'Out-of-country history';
          break;
        case screening?.preferences?.dot_compliance:
          setting = 'DOT Compliance';
          break;
        default:
          setting = '';
          break;
      }
      break;
    case 'education_verification':
      setting = screening?.preferences?.international_upgrade
        ? 'Out-of-country history'
        : '';
      break;
    case 'personal_reference_verification':
    case 'professional_reference_verification':
      setting = screening?.preferences?.required_quantity
        ? screening.preferences.required_quantity.toString()
        : '';
      break;
    default:
      setting = formatScreeningPreferences(screening)
        .replace(/[^a-z0-9\s,]/gi, ' ')
        .replace(/,/g, ', ');
  }

  return setting && `(${setting.trim()})`;
};

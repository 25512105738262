/* eslint-disable import/prefer-default-export */

export const replaceCheckrEmail = (email?: string) =>
  email === 'eng@checkr.io' ? 'Checkr' : email;

export const replaceRubySyntax = (extraInfoStr: string) => {
  if (!extraInfoStr) return null;

  const jsonStr = extraInfoStr
    .replace(/:([a-zA-Z_]+)=>/g, '"$1":')
    .replace(/=>/g, ':');

  try {
    return JSON.parse(jsonStr);
  } catch {
    return { customer_email: '' };
  }
};

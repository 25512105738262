import React from 'react';
import { getDollarAmount } from 'components/BetterOrderExperience/utils/PricingUtils';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../locales';
import {
  StyledPricingContainer,
  StyledItemPricing,
  StyledSubtotalPricing,
} from './PricingSummary.styles';

type PricingSummaryProps = {
  packagePrice: number;
  addonPrice: number;
  aliasPrice?: number;
  subtotal: number;
  minTotalFees: number;
  maxTotalFees: number;
};

const PricingSummary: React.FC<PricingSummaryProps> = ({
  packagePrice,
  addonPrice,
  aliasPrice = 0,
  subtotal,
  minTotalFees,
  maxTotalFees,
}) => {
  const { t } = useTranslation(namespace);
  return (
    <StyledPricingContainer data-testid='summary-of-pricing'>
      <StyledItemPricing data-testid='summary-base-package-price'>
        {t(`reviewAndSubmit.infoSection.pricingSummary.package`)}
        {getDollarAmount(packagePrice)}
      </StyledItemPricing>
      <StyledItemPricing data-testid='summary-addons-price'>
        {t(`reviewAndSubmit.infoSection.pricingSummary.customizations`)}
        {getDollarAmount(addonPrice)}
      </StyledItemPricing>
      {aliasPrice !== 0 && (
        <StyledItemPricing data-testid='summary-aliases-price'>
          {t(`reviewAndSubmit.infoSection.pricingSummary.aliases`)}
          {getDollarAmount(aliasPrice)}
        </StyledItemPricing>
      )}
      <StyledSubtotalPricing data-testid='summary-subtotal-price'>
        {t(`reviewAndSubmit.infoSection.pricingSummary.subtotal`)}
        {getDollarAmount(subtotal)}
      </StyledSubtotalPricing>
      {maxTotalFees > 0 && (
        <StyledItemPricing data-testid='summary-third-party-price'>
          {t(`reviewAndSubmit.infoSection.pricingSummary.thirdPartyFees`)}
          {`${getDollarAmount(minTotalFees)}-${getDollarAmount(maxTotalFees)}`}
        </StyledItemPricing>
      )}
    </StyledPricingContainer>
  );
};

export default PricingSummary;

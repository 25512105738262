import React from 'react';
import { useTimeSinceOffense } from 'modules/adjudication/utilities';

import { FieldComponentProps } from '../types';

const TimeSinceOffense: React.FC<FieldComponentProps> = ({
  adverseItem,
  testid,
}) => {
  const { date } = adverseItem;
  const { call: timeSinceOffense } = useTimeSinceOffense();
  const string = timeSinceOffense(date, '-');

  return (
    <span data-testid={testid} className='value-width'>
      {string}
    </span>
  );
};

export default TimeSinceOffense;

/* eslint-disable import/prefer-default-export */

import { useCallback, useState } from 'react';
import {
  useCreateAccessCode,
  useUpdateAccessCode,
} from 'api/drivingJurisdictions/hooks';

export const useManageDrivingJurisdictionAccessCodes = (accountId: string) => {
  const [accessCodesInput, setAccessCodesInput] = useState<
    Record<string, string | null>
  >({});

  const [existingAccessCodes, setExistingAccessCodes] = useState<
    Record<string, string>
  >({});

  const [createAccessCodeCall] = useCreateAccessCode();

  const [updateAccessCodeCall] = useUpdateAccessCode();

  const handleCreateAccessCode = useCallback(
    (abbr: string, accessCode: string) => {
      createAccessCodeCall({
        accountId,
        abbr,
        accessCode,
      });
    },
    [accountId, createAccessCodeCall],
  );

  const handleUpdateAccessCode = useCallback(
    (abbr: string, accessCode: string) => {
      updateAccessCodeCall({
        accountId,
        abbr,
        accessCode,
      });
    },
    [accountId, updateAccessCodeCall],
  );

  const saveAccessCodes = async () =>
    Promise.all(
      Object.entries(accessCodesInput).map(async ([abbr, accessCode]) => {
        if (accessCode) {
          if (existingAccessCodes[abbr]) {
            handleUpdateAccessCode(abbr, accessCode);
          } else {
            handleCreateAccessCode(abbr, accessCode);
          }
        }
      }),
    );

  return {
    saveAccessCodes,
    setAccessCodesInput,
    accessCodesInput,
    setExistingAccessCodes,
  };
};

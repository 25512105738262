import * as Assignment from 'modules/assess/models/rulesets/assignment';
import { ID } from 'modules/id';
import client from '../client';
import { Namespace } from '../constants';

const BASE_PATH = 'rulesets';

const path = (namespace: Namespace, id: ID) => {
  return `/${namespace}/${BASE_PATH}/${id}/assignments`;
};

export const update = (
  namespace: Namespace,
  id: ID,
  data: Assignment.List | Assignment.AssignmentV2[],
): Promise<void> => {
  return client.put(path(namespace, id), { data });
};

import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import {
  Button,
  DeleteButton,
} from 'components/AddScreenings/shared/AddScreeningsAction';
import AddedLabel from 'components/AddScreenings/shared/AddedLabel';
import type { AddonsT } from '../..';

type Props = {
  id: string;
  added?: boolean;
  disabled?: boolean;
  included?: boolean; // Included in the base package
  onAddClick: (
    screening: AddonsT.ScreeningType,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
};

const ScreeningSectionItemButton: React.FC<Props> = ({
  id,
  added = false,
  disabled = false,
  included = false,
  onAddClick,
  onRemoveClick,
}) => {
  if (included) {
    return <AddedLabel />;
  }
  if (added) {
    return (
      <DeleteButton
        id='delete-trash-can'
        kind='tertiary'
        data-testid={`${id}-delete-button`}
        onClick={onRemoveClick}
      >
        <M.Icon icon='TrashCan' />
      </DeleteButton>
    );
  }
  return (
    <Button
      kind='secondary'
      onClick={onAddClick}
      disabled={disabled}
      data-testid={`${id}-add-button`}
    >
      <M.Icon icon='Add' /> Add
    </Button>
  );
};

export default ScreeningSectionItemButton;

import React from 'react';
import {
  CurrentUser,
  DrugScreeningV2,
  OccupationalHealthScreeningPost,
} from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { usePackageSubtypes } from 'api/packages';
import { namespace } from 'components/BetterOrderExperience/locales';
import drugBottle from 'components/AddScreenings/shared/svg/drug-bottle.svg';
import type { AddonsT } from '../..';
import { ScreeningSection } from '../ScreeningSection';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { StyledFlex } from '../../AddonsStep.styles';
import DrugTest from './DrugTest';
import OccupationalHealthScreening from './OccupationalHealthScreening';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  isAdded: (screening: AddonsT.ScreeningType) => boolean;
  isIncluded: (screening: AddonsT.ScreeningType) => boolean;
  currentUser: CurrentUser;
};

const DrugAndHealthSection: React.FC<Props> = ({
  onAddClick,
  onRemoveClick,
  isAdded,
  isIncluded,
  currentUser,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.drugAndHealth',
  });

  const { data: packageSubtypeData } = usePackageSubtypes(
    currentUser?.account?.id,
  );

  // Drug Screening

  const isDrugTestIncluded = isIncluded(ScreeningProductType.DRUG_SCREENING);
  const isOHSIncluded = isIncluded(
    ScreeningProductType.OCCUPATIONAL_HEALTH_SCREENING,
  );

  const drugTestScreenings = packageSubtypeData?.drug_screening_v2?.flatMap(
    (drugScreening: DrugScreeningV2) => drugScreening.services,
  );

  // Occupational Health Screening
  const ohsScreeningOptions =
    packageSubtypeData?.occupational_health_screening ?? [];

  const occupationalHealthScreenings = ohsScreeningOptions?.flatMap(
    (screening: OccupationalHealthScreeningPost) => {
      return screening.services;
    },
  );

  return (
    <ScreeningSection
      id='drug-and-ohs'
      name={t('sectionName')}
      icon={drugBottle}
      open={isDrugTestIncluded || isOHSIncluded}
    >
      <StyledFlex flexDirection='column' verticalAlign='middle'>
        <DrugTest
          id={ScreeningProductType.DRUG_SCREENING}
          price='Price varies'
          isAdded={isAdded(ScreeningProductType.DRUG_SCREENING)}
          isIncluded={isDrugTestIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          status={currentUser?.account?.drug_screening_setup}
          drugTestScreenings={drugTestScreenings}
        />

        <OccupationalHealthScreening
          id={ScreeningProductType.OCCUPATIONAL_HEALTH_SCREENING}
          price='Price varies'
          isAdded={isAdded(ScreeningProductType.OCCUPATIONAL_HEALTH_SCREENING)}
          isIncluded={isDrugTestIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          occHealthScreenings={occupationalHealthScreenings}
          status={currentUser?.account?.occ_health_screening_setup}
        />
      </StyledFlex>
    </ScreeningSection>
  );
};

export default DrugAndHealthSection;

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetUser from 'components/Signup/hooks/useGetUser';
import { M } from '@dashboard-experience/mastodon';
import { Modal } from '../Modal';

import {
  VerifyEmailAddressContainer,
  WelcomeSection,
  OrderSubmissionInstructions,
  OrderSubmittedEmail,
  OrderSubmittedTitle,
  ErrorMessage,
} from './styles';
import { useVerifyUserEmail } from '../../../../api/users';

interface VerifyEmailAddressProps {
  orderSubmitted?: boolean;
}

const VerifyEmailAddress: React.FC<VerifyEmailAddressProps> = ({
  orderSubmitted = false,
}) => {
  const { t } = useTranslation('signup');
  const { data: user } = useGetUser();
  const userId = user?.id;
  const accountId = user?.account_id;
  const userEmail = user?.email;

  const { verifyEmailCall, verifyEmailResult } = useVerifyUserEmail(
    accountId,
    userId,
  );
  const hasError = verifyEmailResult?.isError;

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState(
    t('pages.verifyEmailAddressPage.resendEmail'),
  );

  const handleResendEmail = useCallback(() => {
    if (!isButtonDisabled) {
      verifyEmailCall();

      setIsButtonDisabled(true);
      setButtonText(t('pages.verifyEmailAddressPage.emailResent'));

      setTimeout(() => {
        setIsButtonDisabled(false);
        setButtonText(t('pages.verifyEmailAddressPage.resendEmail'));
      }, 40000);
    }
  }, [isButtonDisabled, verifyEmailCall, t]);

  useEffect(() => {
    verifyEmailCall();
  }, [verifyEmailCall]);

  return (
    <Modal hideHeader>
      <VerifyEmailAddressContainer
        data-testid='email-container'
        orderSubmitted={orderSubmitted}
      >
        {orderSubmitted && (
          <OrderSubmittedTitle>
            {t(`pages.verifyEmailAddressPage.orderSubmitted`)}
          </OrderSubmittedTitle>
        )}
        <WelcomeSection>
          <OrderSubmissionInstructions>
            {t(`pages.verifyEmailAddressPage.title`)}
          </OrderSubmissionInstructions>
          {hasError && (
            <ErrorMessage>
              {t(`pages.verifyEmailAddressPage.error`)}
            </ErrorMessage>
          )}
          {!hasError && (
            <>
              <OrderSubmittedEmail>
                Click the link we sent to {userEmail}
              </OrderSubmittedEmail>
              <M.Button
                kind='tertiary'
                size='md'
                onClick={handleResendEmail}
                disabled={isButtonDisabled}
              >
                {isButtonDisabled && (
                  <M.Icon
                    icon='Checkmark'
                    size='16'
                    style={{ marginRight: '8px' }}
                  />
                )}
                {buttonText}
              </M.Button>
            </>
          )}
        </WelcomeSection>
      </VerifyEmailAddressContainer>
    </Modal>
  );
};

export default VerifyEmailAddress;

import React, { Dispatch, SetStateAction } from 'react';
import { useDrivingJurisdictionsList } from 'api/drivingJurisdictions/hooks';
import DrivingJurisdictionDisplay from 'components/MotorVehicle/DrivingJurisdictionDisplay';

const DrivingJurisdictionsContainer = ({
  accountId,
  accessCodesInput,
  setAccessCodesInput,
  setExistingAccessCodes,
}: {
  accountId: string;
  accessCodesInput: Record<string, string | null>;
  setAccessCodesInput: Dispatch<SetStateAction<Record<string, string | null>>>;
  setExistingAccessCodes: Dispatch<SetStateAction<Record<string, string>>>;
}) => {
  const { data: drivingJurisdictions } = useDrivingJurisdictionsList({
    requires_customer_access_code: true,
  });

  return (
    <>
      {drivingJurisdictions &&
        drivingJurisdictions.map(jurisdiction => {
          return (
            <DrivingJurisdictionDisplay
              key={jurisdiction.id}
              jurisdiction={jurisdiction}
              setAccessCodesInput={setAccessCodesInput}
              setExistingAccessCodes={setExistingAccessCodes}
              accessCode={accessCodesInput[jurisdiction.code] || ''}
              accountId={accountId}
            />
          );
        })}
    </>
  );
};
export default DrivingJurisdictionsContainer;

import React, { useCallback, useState } from 'react';
import PackageScreeningItem from 'components/BetterOrderExperience/SelectPackageStep/PackageScreeningItem';
import { Screening } from 'types';
import { getDollarAmount } from 'components/BetterOrderExperience/utils/PricingUtils';
import { StyledFlexContainer } from './CustomizationsInfoSection.styles';
import ToggleView from './ToggleView';

export type SelectedAddOn = {
  screening: Screening;
  price: number;
};

const CustomizationsInfoSection: React.FC<{
  addOns: SelectedAddOn[];
  showPrices: boolean;
}> = ({ addOns, showPrices }) => {
  const [visibleAddons, setVisibleAddons] = useState<SelectedAddOn[]>(
    addOns.slice(0, 5),
  );

  const handleToggle = useCallback(
    (viewMore: boolean) => {
      if (viewMore) {
        setVisibleAddons(addOns.slice(0, 5));
      } else {
        setVisibleAddons(addOns);
      }
    },
    [addOns],
  );

  return (
    <>
      {visibleAddons.map((addOn, idx: number) => {
        const key = `screening-${idx}`;
        const { type } = addOn.screening;
        return (
          <StyledFlexContainer key={key}>
            <PackageScreeningItem
              screening={addOn.screening}
              showScreeningSettings={false}
            />
            {showPrices && (
              <div data-testid={`${type}-price`}>
                {getDollarAmount(addOn.price)}
              </div>
            )}
          </StyledFlexContainer>
        );
      })}
      <ToggleView listLength={addOns.length} handleToggle={handleToggle} />
    </>
  );
};

export default CustomizationsInfoSection;

import React from 'react';
import truck from 'components/AddScreenings/shared/svg/truck.svg';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import { getPricesByScreeningType } from 'components/BetterOrderExperience/utils/PricingUtils';
import type { AddonsT } from '../..';
import { ScreeningSection } from '../ScreeningSection';
import MotorVehicleReport from './MotorVehicleReport';
import { ScreeningProductType } from '../../AddonsStep.enums';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  isAdded: (screening: AddonsT.ScreeningType) => boolean;
  isIncluded: (screening: AddonsT.ScreeningType) => boolean;
  addOnPrices: AddonsT.AddonPrice[];
  selectedAdditionalProperties: AddonsT.AdditionalProperties;
  setSelectedAdditionalProperties: (
    additionalProperties: AddonsT.AdditionalProperties,
  ) => void;
};

const MotorVehicleRecordSection: React.FC<Props> = ({
  onAddClick,
  onRemoveClick,
  isAdded,
  isIncluded,
  addOnPrices,
  selectedAdditionalProperties,
  setSelectedAdditionalProperties,
}) => {
  const { t } = useTranslation(namespace, { keyPrefix: 'addOns.mvrSection' });

  const mvrPrice = getPricesByScreeningType(
    addOnPrices,
    ScreeningProductType.MOTOR_VEHICLE_REPORT,
  );

  return (
    <ScreeningSection
      id='motor-vehicle-record'
      name={t('sectionName')}
      icon={truck}
      open={isIncluded(ScreeningProductType.MOTOR_VEHICLE_REPORT)}
    >
      <MotorVehicleReport
        id={ScreeningProductType.MOTOR_VEHICLE_REPORT}
        price={mvrPrice}
        isAdded={isAdded(ScreeningProductType.MOTOR_VEHICLE_REPORT)}
        isIncluded={isIncluded(ScreeningProductType.MOTOR_VEHICLE_REPORT)}
        onAddClick={onAddClick}
        onRemoveClick={onRemoveClick}
        selectedAdditionalProperties={selectedAdditionalProperties}
        setSelectedAdditionalProperties={setSelectedAdditionalProperties}
      />
    </ScreeningSection>
  );
};

export default MotorVehicleRecordSection;

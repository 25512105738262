import { ChargeType, Service } from '@dashboard-experience/utils';

export const DEFAULT_DRUG_PANELS: Service[] = [
  {
    name: 'DS_5_PANEL',
    label: '5 Panel',
    counterpart_slug: 'DS_INSTANT_5PANEL',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-5PI-STD-LAB-U',
        charge_type: ChargeType.InNetwork,
      },
      {
        name: 'DRG-0001-5PO-STD-LAB-U',
        charge_type: ChargeType.OutNetwork,
      },
    ],
  },
  {
    name: 'DS_5_PANEL_NO_THC',
    label: '5 Panel no THC',
    counterpart_slug: 'DS_XCUP_5PANEL_NO_THC',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-5PI-MTHC-LAB-U',
        charge_type: ChargeType.InNetwork,
      },
      {
        name: 'DRG-0001-5PO-MTHC-LAB-U',
        charge_type: ChargeType.OutNetwork,
      },
    ],
  },
  {
    name: 'DS_INSTANT_5PANEL',
    label: 'Instant 5 Panel',
    counterpart_slug: '',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-5PI-I-U',
        charge_type: ChargeType.InNetwork,
      },
      {
        name: 'DRG-0001-5PO-I-U',
        charge_type: ChargeType.OutNetwork,
      },
    ],
  },
  {
    name: 'DS_7_PANEL',
    label: '7 Panel',
    counterpart_slug: 'DS_XCUP_7PANEL',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-7PI-STD-LAB-U',
        charge_type: ChargeType.InNetwork,
      },
      {
        name: 'DRG-0001-7PO-STD-LAB-U',
        charge_type: ChargeType.OutNetwork,
      },
    ],
  },
  {
    name: 'DS_9_PANEL',
    label: '9 Panel',
    counterpart_slug: 'DS_XCUP_9PANEL',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-9PI-STD-LAB-U',
        charge_type: ChargeType.InNetwork,
      },
      {
        name: 'DRG-0001-9PO-STD-LAB-U',
        charge_type: ChargeType.OutNetwork,
      },
    ],
  },
  {
    name: 'DS_9_PANEL_NO_THC',
    label: '9 Panel no THC',
    counterpart_slug: '',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-9PI-MTHC-LAB-U',
        charge_type: ChargeType.InNetwork,
      },
      {
        name: 'DRG-0001-9PO-MTHC-LAB-U',
        charge_type: ChargeType.OutNetwork,
      },
    ],
  },
  {
    name: 'DS_10_PANEL',
    label: '10 Panel',
    counterpart_slug: 'DS_XCUP_10PANEL',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-10PI-STD-LAB-U',
        charge_type: ChargeType.InNetwork,
      },
      {
        name: 'DRG-0001-10PO-STD-LAB-U',
        charge_type: ChargeType.OutNetwork,
      },
    ],
  },
  {
    name: 'DS_10_PANEL_NO_THC',
    label: '10 panel no THC',
    counterpart_slug: 'DS_XCUP_10PANEL_NO_THC',
    integration: '',
    skus: [
      {
        name: 'DRG-0001-10PI-MTHC-LAB-U',
        charge_type: ChargeType.InNetwork,
      },
      {
        name: 'DRG-0001-10PO-MTHC-LAB-U',
        charge_type: ChargeType.OutNetwork,
      },
    ],
  },
];

export const OCCUPATIONAL_HEALTH_SECTIONS = [
  { title: 'Physical evaluations', description: 'Physicals, lift tests' },
  { title: 'TB tests & x-rays', description: 'Tuberculosis tests' },
  { title: 'Hearing & vision', description: 'Audiogram, vision tests' },
  {
    title: 'Immunizations',
    description:
      'Administer vaccines and blood-draw tests for resistance to common diseases',
  },
  { title: 'Other', description: 'OSHA respirator questionnaire' },
];

export type OccHealthServices = {
  section: string;
  disables: string[];
  label?: string;
  value?: string;
};

export const OCCUPATIONAL_HEALTH_SCREENING_SECTIONS: Record<
  string,
  OccHealthServices
> = {
  OHS_DOT_PHYSICAL: {
    section: 'Physical evaluations',
    disables: [],
  },
  OHS_HEIGHT_WEIGHT_BLOOD_PRESSURE: {
    section: 'Physical evaluations',
    disables: [],
  },
  OHS_KRAUS_WEBER_EVALUATION: { section: 'Physical evaluations', disables: [] },
  OHS_LIFT_TESTING_L1_50_LBS: {
    section: 'Physical evaluations',
    disables: ['OHS_LIFT_TESTING_L2_75_LBS'],
  },
  OHS_LIFT_TESTING_L2_75_LBS: {
    section: 'Physical evaluations',
    disables: ['OHS_LIFT_TESTING_L1_50_LBS'],
  },
  OHS_WELLNESS_SCREEN: { section: 'Physical evaluations', disables: [] },
  OHS_NON_DOT_PHYSICAL: { section: 'Physical evaluations', disables: [] },
  OHS_AUDIOGRAM: { section: 'Hearing & vision', disables: [] },
  OHS_VISION_TEST_ISHIHARA: { section: 'Hearing & vision', disables: [] },
  OHS_VISION_TEST_JAEGER: { section: 'Hearing & vision', disables: [] },
  OHS_VISION_TEST_SNELLEN: { section: 'Hearing & vision', disables: [] },
  OHS_VISION_TEST_TITMUS: { section: 'Hearing & vision', disables: [] },
  OHS_TB_PPD_TEST_1: {
    section: 'TB tests & x-rays',
    disables: ['OHS_TB_CHEST_XRAY', 'OHS_TB_PPD_TEST_2'],
  },
  OHS_TB_PPD_TEST_2: {
    section: 'TB tests & x-rays',
    disables: ['OHS_TB_PPD_TEST_1', 'OHS_TB_CHEST_XRAY'],
  },
  OHS_TB_CHEST_XRAY: {
    section: 'TB tests & x-rays',
    disables: ['OHS_TB_PPD_TEST_1', 'OHS_TB_PPD_TEST_2'],
  },
  OHS_CHEST_XRAY_1_VIEW: {
    section: 'TB tests & x-rays',
    disables: ['OHS_CHEST_XRAY_2_VIEW'],
  },
  OHS_CHEST_XRAY_2_VIEW: {
    section: 'TB tests & x-rays',
    disables: ['OHS_CHEST_XRAY_1_VIEW'],
  },
  OHS_QUANTIFERON_GOLD_TB: { section: 'TB tests & x-rays', disables: [] },
  OHS_OXFORD_TSPOT_TB: { section: 'TB tests & x-rays', disables: [] },
  OHS_INFLUENZA_VACCINE: { section: 'Immunizations', disables: [] },
  OHS_TITER_HEPATITIS_A: {
    section: 'Immunizations',
    disables: ['OHS_VACCINE_HEPATITIS_A'],
  },
  OHS_TITER_HEPATITIS_B_HBSAB: {
    section: 'Immunizations',
    disables: ['OHS_VACCINE_HEPATITIS_B_1'],
  },
  OHS_TITER_HEPATITIS_B_SURFACE_ANTIGEN: {
    section: 'Immunizations',
    disables: [],
  },
  OHS_TEST_HEPATITIS_C_ANTIBODY: { section: 'Immunizations', disables: [] },
  OHS_TITER_MMR: { section: 'Immunizations', disables: ['OHS_VACCINE_MMR_1'] },
  OHS_TITER_MUMPS: { section: 'Immunizations', disables: [] },
  OHS_TITER_RUBELLA: { section: 'Immunizations', disables: [] },
  OHS_TITER_VARICELLA_ANTIBODY_IGG: {
    section: 'Immunizations',
    disables: ['OHS_VACCINE_VARICELLA_1'],
  },
  OHS_VACCINE_HEPATITIS_A: {
    section: 'Immunizations',
    disables: ['OHS_TITER_HEPATITIS_A'],
  },
  OHS_VACCINE_HEPATITIS_B_1: {
    section: 'Immunizations',
    disables: [
      'OHS_VACCINE_HEPATITIS_B_2',
      'OHS_VACCINE_HEPATITIS_B_3',
      'OHS_TITER_HEPATITIS_B_HBSAB',
    ],
  },
  OHS_VACCINE_HEPATITIS_B_2: {
    section: 'Immunizations',
    disables: ['OHS_VACCINE_HEPATITIS_B_1', 'OHS_VACCINE_HEPATITIS_B_3'],
  },
  OHS_VACCINE_HEPATITIS_B_3: {
    section: 'Immunizations',
    disables: ['OHS_VACCINE_HEPATITIS_B_1', 'OHS_VACCINE_HEPATITIS_B_2'],
  },
  OHS_VACCINE_MMR_1: {
    section: 'Immunizations',
    disables: ['OHS_VACCINE_MMR_2', 'OHS_TITER_MMR'],
  },
  OHS_VACCINE_MMR_2: {
    section: 'Immunizations',
    disables: ['OHS_VACCINE_MMR_1'],
  },
  OHS_VACCINE_TDAP: { section: 'Immunizations', disables: [] },
  OHS_VACCINE_VARICELLA_1: {
    section: 'Immunizations',
    disables: ['OHS_VACCINE_VARICELLA_2', 'OHS_TITER_VARICELLA_ANTIBODY_IGG'],
  },
  OHS_VACCINE_VARICELLA_2: {
    section: 'Immunizations',
    disables: ['OHS_VACCINE_VARICELLA_1'],
  },
  OHS_ASR_TEST: { section: 'Other', disables: [] },
  OHS_OSHA_RESPIRATOR_QS: { section: 'Other', disables: [] },
  OHS_PULMONARY_FUNCTION: { section: 'Other', disables: [] },
  OHS_PULMONARY_FUNCTION_WITH_OSHA_QS: { section: 'Other', disables: [] },
  OHS_RESPIRATOR_FIT_TEST_QUALITATIVE: { section: 'Other', disables: [] },
  OHS_RESPIRATOR_FIT_TEST_QUANTITATIVE: { section: 'Other', disables: [] },
};

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledIcon, StyledView } from './CustomizationsInfoSection.styles';
import { namespace } from '../../locales';

const MIN_LENGTH = 5;

const ToggleView: React.FC<{
  listLength: number;
  handleToggle: Function;
  minLength?: number;
}> = ({ listLength, handleToggle, minLength = MIN_LENGTH }) => {
  const { t } = useTranslation(namespace);
  const [viewMore, setViewMore] = useState(listLength > MIN_LENGTH);

  const handleClick = useCallback(() => {
    setViewMore(!viewMore);
    handleToggle(!viewMore);
  }, [handleToggle, viewMore]);

  if (listLength <= MIN_LENGTH) {
    return null;
  }

  if (viewMore) {
    return (
      <StyledView onClick={handleClick} data-testid='addon-section-view-more'>
        {`${t('reviewAndSubmit.view')} ${listLength - minLength} ${t(
          'reviewAndSubmit.more',
        )}`}
        <StyledIcon icon='ChevronDown' size='20' />
      </StyledView>
    );
  }

  return (
    <StyledView onClick={handleClick} data-testid='addon-section-view-less'>
      {t('reviewAndSubmit.viewLess')} <StyledIcon icon='ChevronUp' size='20' />
    </StyledView>
  );
};

export default ToggleView;

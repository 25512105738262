import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { AddonsT } from '..';
import {
  StyledAddonScreeningContainer,
  StyledHelpIcon,
  StyledNameAndIcon,
  StyledScreeningDataContainer,
  StyledScreeningName,
  StyledScreeningNameContainer,
  StyledScreeningPrice,
  StyledTooltip,
} from './PopularAddOns.styles';
import { namespace } from '../../locales';
import ScreeningSectionItemButton from '../DomesticScreeningsList/ScreeningSectionItem/ScreeningSectionItemButton';
import { ScreeningProductType } from '../AddonsStep.enums';

type AddOnScreeningCardProps = {
  screeningType: AddonsT.ScreeningType;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  includedScreenings: AddonsT.ScreeningType[];
  addedScreeningTypes: AddonsT.ScreeningType[];
  getPriceByType: (screening: AddonsT.ScreeningType) => string;
};

const AddOnScreeningCard = ({
  screeningType,
  onAddClick,
  onRemoveClick,
  includedScreenings,
  addedScreeningTypes,
  getPriceByType,
}: AddOnScreeningCardProps) => {
  const { t } = useTranslation('better_order_experience');
  const basePath = `${namespace}:addOns.popularAddOns.screeningCards`;

  const getTranslationKey = (screeningType: AddonsT.ScreeningType) => {
    switch (screeningType) {
      case ScreeningProductType.MOTOR_VEHICLE_REPORT:
        return 'motorVehicleReport';
      case ScreeningProductType.EDUCATION_VERIFICATION:
        return 'educationVerification';
      case ScreeningProductType.EMPLOYMENT_VERIFICATION:
        return 'employmentVerification';
      default:
        return 'motorVehicleReport';
    }
  };

  const handleAddClick = useCallback(() => {
    onAddClick(screeningType, getPriceByType(screeningType));
  }, [onAddClick, getPriceByType, screeningType]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(screeningType);
  }, [onRemoveClick, screeningType]);

  return (
    <StyledAddonScreeningContainer>
      <StyledScreeningNameContainer>
        <StyledNameAndIcon>
          <StyledScreeningName
            data-testid={`${screeningType}-screening-card-name`}
          >
            {t(`${basePath}.${getTranslationKey(screeningType)}.name`)}
          </StyledScreeningName>

          <StyledTooltip
            openOnHover
            align='bottom'
            definition={t(
              `${basePath}.${getTranslationKey(screeningType)}.tooltip`,
            )}
          >
            <StyledHelpIcon icon='Information' />
          </StyledTooltip>
        </StyledNameAndIcon>
      </StyledScreeningNameContainer>
      <StyledScreeningDataContainer>
        <StyledScreeningPrice
          data-testid={`${screeningType}-screening-card-price`}
        >
          {getPriceByType(screeningType)}
        </StyledScreeningPrice>
        <ScreeningSectionItemButton
          id={`${screeningType}-add-remove-button`}
          onAddClick={handleAddClick}
          onRemoveClick={handleRemoveClick}
          included={includedScreenings.includes(screeningType)}
          added={addedScreeningTypes.includes(screeningType)}
        />
      </StyledScreeningDataContainer>
    </StyledAddonScreeningContainer>
  );
};

export default AddOnScreeningCard;

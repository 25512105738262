import { useFlag } from '@dashboard-experience/react-flagr';
import { useGetReport } from 'api/reports';
import { ENABLE_ARCHIVE_REPORTS } from 'Flags';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NonActionableBanner from './non-actionable-banner';
import { replaceRubySyntax } from './utils';

const ReportArchivedBanner = () => {
  const { t } = useTranslation();
  const { report } = useGetReport();
  const archiveReportFlagEnabled =
    useFlag(ENABLE_ARCHIVE_REPORTS)?.variantKey === 'on';
  const userActions = report?.user_actions;

  if (!report?.archived || !archiveReportFlagEnabled || !userActions) {
    return null;
  }

  const archivedEvent = userActions.find(
    (action: any) => action.event === 'report_archived',
  );

  const archivedDate = archivedEvent?.time
    ? moment(archivedEvent.time).format('ll')
    : null;

  const archivedBy = archivedEvent?.extra_info
    ? replaceRubySyntax(archivedEvent.extra_info)?.customer_email
    : null;

  const bannerTitle = () => {
    if (archivedDate && archivedBy)
      return t('banners.reportArchived.text', {
        archivedBy,
        archivedDate,
      });

    return t('banners.reportArchived.genericText');
  };

  return <NonActionableBanner kind='info' title={bannerTitle()} />;
};

export default ReportArchivedBanner;

import { Client } from 'api';
import { Candidate, GenericObject } from 'types';
import { COMMUNICATOR_API_URL, COMMUNICATOR_EU_API_URL } from 'Constants';
import { isEuResourceId } from 'utils';

export const getCommmunicatorBaseUrl = (resourceId: string) =>
  isEuResourceId(resourceId) ? COMMUNICATOR_EU_API_URL : COMMUNICATOR_API_URL;

// Check if the Candidate's email is in the Mailgun suppression list
export const getSuppressions = (
  candidate: GenericObject,
): Promise<GenericObject> => {
  const { id: candidateId, email } = candidate;
  if (!email || !email.includes('@')) {
    return Promise.resolve({});
  }
  const baseUrl = getCommmunicatorBaseUrl(candidateId);
  return Client.get(`${baseUrl}/suppression/${email}`);
};

// Attempt to remove the Candidate's email from the Mailgun suppression list
export const removeSuppressions = (
  candidate: Candidate,
): Promise<GenericObject> => {
  const { id: candidateId, email } = candidate;

  const baseUrl = getCommmunicatorBaseUrl(candidateId);

  return Client.delete(`${baseUrl}/suppression/${email}`);
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import { AddButtonContainer } from 'components/AddScreenings/shared/ListItemElements';
import ScreeningSectionItemButton from '../ScreeningSectionItem/ScreeningSectionItemButton';
import {
  StyledCreditReportSetupButton,
  StyledCreditReportPendingContainer,
  StyledCreditReportPendingIcon,
} from './CreditReport.styles';
import { AddonsT } from '../..';
import { CreditReportStatus } from '../../AddonsStep.enums';

type Props = {
  id: string;
  onAddClick: () => void;
  isAdded: boolean;
  isIncluded: boolean;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  status: AddonsT.CreditReportStatusType;
  handleOpen: () => void;
};

const CreditReportSetupButtons: React.FC<Props> = ({
  id,
  onAddClick,
  isAdded,
  isIncluded,
  onRemoveClick,
  status,
  handleOpen,
}) => {
  const { t } = useTranslation(namespace, { keyPrefix: 'addOns.creditReport' });

  return (
    <>
      {status === CreditReportStatus.DISABLED && (
        <AddButtonContainer>
          <StyledCreditReportSetupButton
            data-testid='credit-report-disabled-button'
            onClick={handleOpen}
          >
            {t('disabled.text')}
          </StyledCreditReportSetupButton>
        </AddButtonContainer>
      )}
      {status === CreditReportStatus.PENDING && (
        <StyledCreditReportPendingContainer data-testid='credit-report-pending'>
          <StyledCreditReportPendingIcon icon='Renew' /> {t('pending')}
        </StyledCreditReportPendingContainer>
      )}
      {status === CreditReportStatus.ENABLED && (
        <AddButtonContainer>
          <ScreeningSectionItemButton
            id={id}
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            added={isAdded}
            disabled={false}
            included={isIncluded}
          />
        </AddButtonContainer>
      )}
    </>
  );
};

export default CreditReportSetupButtons;

import * as AddonUtils from 'components/AddScreenings/shared/utils';
import { PostPackageType } from '@dashboard-experience/utils';
import type { AddonsT } from '../AddonsStep';
import { ScreeningProductType } from '../AddonsStep/AddonsStep.enums';

export const getDollarAmount = (val: number = 0) =>
  (val / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

export const getPricesByScreeningType = (
  addOns: AddonsT.AddonPrice[],
  productType: ScreeningProductType,
  subtype?: string,
  lookbackYears?: number,
) => {
  if (addOns.length === 0) return 'Loading price...';

  const matchingAddons = addOns.filter((addon: AddonsT.AddonPrice) => {
    if (subtype)
      return addon.product_type === productType && addon.subtype === subtype;
    if (lookbackYears) {
      return (
        addon.product_type === productType &&
        addon.lookback_years === lookbackYears
      );
    }
    return addon.product_type === productType;
  });

  if (matchingAddons.length === 0) return 'Price varies';

  // We are currently grabbing the first in the list, as it currently matches the sku
  // Any changes to the SKU that we list will need to be reflected here.
  return AddonUtils.getDollarAmount(matchingAddons[0]?.base_price.toString());
};

export const getDisplayPrice = (price: number | undefined | null) => {
  if (price === undefined || price === null) return 'Loading price...';
  if (price === 0 || isNaN(price)) return 'Price varies';

  return getDollarAmount(price);
};

export type AddonPricesQueryData = {
  serviceFeeRange: [number, number];
  passthroughFeeRange: [number, number];
};

type ServiceAndPassthroughFeesProps = {
  basePackage: PostPackageType;
  aliasEnabled: boolean;
  addonPricesQueryData?: AddonPricesQueryData;
  addOnPricesQueryDataAlias?: AddonPricesQueryData;
};

const DefaultQueryData: AddonPricesQueryData = {
  serviceFeeRange: [0, 0],
  passthroughFeeRange: [0, 0],
};

export const getServiceAndPassthroughFees = ({
  basePackage,
  aliasEnabled,
  addonPricesQueryData = DefaultQueryData,
  addOnPricesQueryDataAlias = DefaultQueryData,
}: ServiceAndPassthroughFeesProps) => {
  if (Boolean(basePackage?.use_alias_skus) || aliasEnabled) {
    return {
      serviceFeeMin: addOnPricesQueryDataAlias.serviceFeeRange[0] || 0,
      serviceFeeMax: addOnPricesQueryDataAlias.serviceFeeRange[1] || 0,
      passthroughFeeMin: addOnPricesQueryDataAlias.passthroughFeeRange[0] || 0,
      passthroughFeeMax: addOnPricesQueryDataAlias.passthroughFeeRange[1] || 0,
    };
  }
  return {
    serviceFeeMin: addonPricesQueryData?.serviceFeeRange[0] || 0,
    serviceFeeMax: addonPricesQueryData?.serviceFeeRange[1] || 0,
    passthroughFeeMin: addonPricesQueryData?.passthroughFeeRange[0] || 0,
    passthroughFeeMax: addonPricesQueryData?.passthroughFeeRange[1] || 0,
  };
};

type GetSubtotalProps = {
  withAliasFixedPrice: number;
  withoutAliasFixedPrice: number;
  hasAliasSkus: boolean;
  aliasAdded: boolean;
  aliasPrice: number;
};

export const getSubtotal = ({
  hasAliasSkus,
  aliasAdded,
  withAliasFixedPrice = 0,
  withoutAliasFixedPrice = 0,
  aliasPrice,
}: GetSubtotalProps) => {
  if (hasAliasSkus) {
    return withoutAliasFixedPrice + aliasPrice;
  }

  if (aliasAdded) {
    return withAliasFixedPrice;
  }

  return withoutAliasFixedPrice;
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ASSESS_RULESET_ASSIGNMENT_V2_FLAG_KEY } from 'Constants';
import { colors } from '@dashboard-experience/mastodon';
import { Kind } from 'modules/assess/models/assignables/base';
import * as Assignment from 'modules/assess/models/rulesets/assignment';
import DefaultDescription from './DefaultDescription';
import List from './List';
import RulesetLabel from '../../rulesets/RulesetLabel';
import { getInitialSelections } from '../assign/hooks';

type Props = {
  assignments?: Assignment.List | Assignment.Map;
  isdefaultAssignment?: boolean;
  withDetails?: boolean;
};

const StyledContainer = styled.div`
  display: flex;
  margin-top: 2em;

  > * {
    margin: 0 2em;
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;

  > * {
    margin: 1em 0;
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }

  .p3-secondary {
    color: ${colors.uiTextSecondaryLight};
  }
`;

const Spacer = styled.span`
  margin: 0 0.25em;
`;

export const Container: React.FC<Props> = ({
  assignments,
  isdefaultAssignment,
  withDetails,
}) => {
  // TODO EADA-4957: Remove this flag check once the feature flag is removed
  const rulesetAssignmentV2Flag =
    useFlag(ASSESS_RULESET_ASSIGNMENT_V2_FLAG_KEY)?.variantKey === 'on';
  const { t } = useTranslation('assess');
  const header = rulesetAssignmentV2Flag
    ? t('assess:ruleset_assignments.header.applies_to')
    : t('assess:ruleset_assignments.header.assignment');

  const filteredAssignments = getInitialSelections(
    Array.isArray(assignments) ? assignments : [],
  );
  const values = Object.values(assignments || []);

  const account = filterByAssignmentType(values, Kind.ACCOUNT);
  const enableAccountDefault = rulesetAssignmentV2Flag
    ? isdefaultAssignment || false
    : account.length > 0;
  const geos = rulesetAssignmentV2Flag
    ? filteredAssignments.geo
    : filterByAssignmentType(values, Kind.GEO);
  const packages = rulesetAssignmentV2Flag
    ? filteredAssignments.package
    : filterByAssignmentType(values, Kind.PACKAGE);
  const packages_and_geos = filterByAssignmentType(
    values,
    Kind.PACKAGE_AND_GEO,
  );
  const packages_and_segments = filterByAssignmentType(
    values,
    Kind.PACKAGE_AND_SEGMENT,
  );
  const programs = filterByAssignmentType(values, Kind.PROGRAM);
  const roles = rulesetAssignmentV2Flag
    ? filteredAssignments.role
    : filterByAssignmentType(values, Kind.ROLE);
  const segments = rulesetAssignmentV2Flag
    ? filteredAssignments.segment
    : filterByAssignmentType(values, Kind.SEGMENT);
  const states = rulesetAssignmentV2Flag
    ? filteredAssignments.state
    : filterByAssignmentType(values, Kind.STATE);
  const totalAssignments =
    geos.length +
    packages.length +
    packages_and_geos.length +
    packages_and_segments.length +
    roles.length +
    programs.length +
    segments.length +
    states.length;
  return (
    <Wrapper>
      {withDetails && (
        <h5 style={{ margin: 0 }}>
          {header}
          <Spacer />
          <RulesetLabel isDefault={isdefaultAssignment} />
        </h5>
      )}
      <DefaultDescription enabled={enableAccountDefault} />
      <StyledContainer>
        {geos.length > 0 && (
          <List assignments={geos} noun='ruleset_assignments.geo.noun_plural' />
        )}
        {packages.length > 0 && (
          <List
            assignments={packages}
            noun='ruleset_assignments.package.noun_plural'
          />
        )}
        {!rulesetAssignmentV2Flag && packages_and_geos.length > 0 && (
          <List
            assignments={packages_and_geos}
            noun='ruleset_assignments.package_and_geo.noun_plural'
          />
        )}
        {!rulesetAssignmentV2Flag && packages_and_segments.length > 0 && (
          <List
            assignments={packages_and_segments}
            noun='ruleset_assignments.package_and_segment.noun_plural'
          />
        )}
        {!rulesetAssignmentV2Flag && programs.length > 0 && (
          <List
            assignments={programs}
            noun='ruleset_assignments.program.noun_plural'
          />
        )}
        {rulesetAssignmentV2Flag && roles.length > 0 && (
          <List
            assignments={roles}
            noun='ruleset_assignments.role.noun_plural'
          />
        )}
        {segments.length > 0 && (
          <List
            assignments={segments}
            noun={
              rulesetAssignmentV2Flag
                ? 'ruleset_assignments.segment.noun_plural_v2'
                : 'ruleset_assignments.segment.noun_plural'
            }
          />
        )}
        {states.length > 0 && (
          <List
            assignments={states}
            noun='ruleset_assignments.state.noun_plural'
          />
        )}
        {withDetails && !totalAssignments && (
          <div className='p3 p3-secondary'>No current assignments</div>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

function filterByAssignmentType(
  assignments: Array<Assignment.Type>,
  kind: Kind,
) {
  return assignments.filter(assignment => assignment.type === kind);
}

export default Container;

import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

const Container = styled.div<{ shouldOverlay?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;

  ${({ shouldOverlay }) => {
    return (
      shouldOverlay &&
      `
    @media (min-width: 42rem) {
      padding: 32px;
      border-radius: 8px;
      margin: -5rem 0.5rem 1rem;
      backdrop-filter: blur(28px);
      background-color: ${colors.white};
  }
  `
    );
  }}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  flex-direction: row;
  gap: 1rem;
`;

const Header = styled.h4`
  margin: 0 !important;
`;

const Subheader = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${colors.uiTextTertiaryLight};
  padding: 0px 24px;
`;

const TopRightComponent = styled.span`
  text-align: right;
`;

const ChildContainer = styled.div`
  padding-top: 24px;
`;

const styles = {
  Container,
  Header,
  HeaderContainer,
  Subheader,
  TopRightComponent,
  ChildContainer,
};

export default styles;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import HeaderCircleMorning from 'assets/home/header-circle-morning.svg';
import HeaderCircleAfternoon from 'assets/home/header-circle-afternoon.svg';
import HeaderCircleEvening from 'assets/home/header-circle-evening.svg';
import HeaderCircleMorningSmall from 'assets/home/header-circle-morning-small.svg';
import HeaderCircleAfternoonSmall from 'assets/home/header-circle-afternoon-small.svg';
import HeaderCircleEveningSmall from 'assets/home/header-circle-evening-small.svg';
import OrderBackgroundCheck from './OrderBackgroundCheck';

const handleSegmentOfDayBackground = (
  segment: 'morning' | 'afternoon' | 'evening',
  isSmall: boolean,
) => {
  switch (segment) {
    case 'morning':
      return `background: ${colors.navy03} url(${
        isSmall ? HeaderCircleMorningSmall : HeaderCircleMorning
      }) no-repeat center left / auto 100%;`;
    case 'afternoon':
      return `background: ${colors.navy02} url(${
        isSmall ? HeaderCircleAfternoonSmall : HeaderCircleAfternoon
      }) no-repeat center center / auto 100%;`;
    case 'evening':
      return `background: ${colors.navy04} url(${
        isSmall ? HeaderCircleEveningSmall : HeaderCircleEvening
      }) no-repeat center right / auto 100%;`;
    default:
      return `background: ${colors.navy03} url(${
        isSmall ? HeaderCircleMorningSmall : HeaderCircleMorning
      }) no-repeat center left / auto 100%;`;
  }
};

const Grid = styled(M.Grid)<{ segment: 'morning' | 'afternoon' | 'evening' }>`
  ${({ segment }) => handleSegmentOfDayBackground(segment, false)}

  margin: -1rem -1rem 0;

  border-radius: 8px;

  .banners-visible & {
    margin-top: -2.4rem;
  }

  @media (max-width: 42rem) {
    ${({ segment }) => handleSegmentOfDayBackground(segment, true)}
  }

  @media (min-width: 48rem) {
    min-width: calc(100%);
    margin: -2.5rem -3.5rem 0;

    .banners-visible & {
      margin-top: -3rem;
    }
  }

  @media only screen and (min-width: 1848px) {
    min-width: calc(100% + 7rem);
  }
`;

const Row = styled(M.GridRow)`
  #mastodon & {
    align-items: center;
    max-width: 75rem;
    margin: auto;
    padding: 1.5rem 0;

    @media (min-width: 0rem) {
      padding: 2rem 1.5rem;
    }
    @media (min-width: 27rem) {
      padding: 2rem;
    }
    @media (min-width: 42rem) {
      padding: 3.5rem;
    }
  }
`;

const Col = styled(M.GridCol)`
  #mastodon & {
    padding: 0rem;
  }
`;

const Greeting = styled.h2`
  color: ${colors.white} !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0 !important;

  @media (min-width: 42rem) {
    margin-bottom: 5.375rem !important;
  }
`;

const Today = styled.div`
  color: ${colors.uiTextTertiaryDark};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
`;

const Header: React.FC = () => {
  const { t } = useTranslation('dashboard_landing');
  const date = new Date();
  const formattedDate = date.toLocaleString('en-us', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  });
  const currentHour = date.getHours();
  const user = useUser();
  let segment = '';

  if (currentHour >= 5 && currentHour < 12) {
    segment = 'morning';
  } else if (currentHour >= 12 && currentHour < 18) {
    segment = 'afternoon';
  } else {
    segment = 'evening';
  }

  return (
    <Grid segment={segment}>
      <Row>
        <Col sm={4} md={4} lg={8}>
          <Today>{formattedDate}</Today>
          <Greeting>
            {t(`header.${segment}`)}
            {user?.full_name ? `, ${user.full_name.replace(/ .*/, '')}` : ''}
          </Greeting>
        </Col>
        <Col sm={4} md={4} lg={8}>
          <OrderBackgroundCheck />
        </Col>
      </Row>
    </Grid>
  );
};

export default Header;

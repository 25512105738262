import { React } from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { AliasesEnabledType } from 'components/Packages/Alias/types';
import { screeningPayloadsByType } from '../screeningPayloads';

const CloseIcon = styled(M.Icon)`
  height: 13px;
  width: 13px;
  margin: 0 3px;
`;

const correctBasePackageSKUForAliases = packageRequest => {
  // Correct the base_package_sku to match the alias version, if alias is enabled/disabled on the package
  if (packageRequest.base_package_sku) {
    if (
      packageRequest.base_package_sku.endsWith('-A') &&
      packageRequest.aliases_enabled === 'off'
    ) {
      packageRequest.base_package_sku =
        packageRequest.base_package_sku.substring(
          0,
          packageRequest.base_package_sku.length - 2,
        );
    } else if (
      !packageRequest.base_package_sku.endsWith('-A') &&
      packageRequest.aliases_enabled === 'on'
    ) {
      packageRequest.base_package_sku = `${packageRequest.base_package_sku}-A`;
    }
  }
  return packageRequest;
};

export const buildPackageSlug = (packageName = '') =>
  packageName?.toLowerCase().trim().replace(/ /g, '_');

// Will be DRY'ing this bit up
export const buildPostBodyWithAddOnsAndAlias = ({
  basePackage,
  addedScreeningTypes,
  additionalProperties,
  packageName,
  setSlug,
  isPrivate = true,
  aliasesEnabled = AliasesEnabledType.OFF,
}) => {
  // create an object of screening payloads keyed by screening type
  const screeningsMap = addedScreeningTypes.reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: screeningPayloadsByType[curr],
    };
  }, {});
  if (
    screeningsMap.international_criminal_search_v2 &&
    !basePackage.name.includes('International')
  ) {
    if (!screeningsMap.international_adverse_media_search) {
      screeningsMap.international_adverse_media_search = {
        type: 'international_adverse_media_search',
        out_of_country_history: true,
      };
      addedScreeningTypes.push('international_adverse_media_search');
    }
  }

  // for screenings with additional properties, add those properties to the corresponding payload objects
  if (screeningsMap.facis_search && additionalProperties.facis_search) {
    screeningsMap.facis_search = {
      ...screeningsMap.facis_search,
      ...additionalProperties.facis_search,
    };
  }

  if (screeningsMap.drug_screening) {
    screeningsMap.drug_screening = {
      ...screeningsMap.drug_screening,
      ...additionalProperties.drug_screening,
    };
  }

  if (screeningsMap.occupational_health_screening) {
    screeningsMap.occupational_health_screening = {
      ...screeningsMap.occupational_health_screening,
      ...additionalProperties.occupational_health_screening,
    };
  }

  if (
    screeningsMap.education_verification &&
    additionalProperties.education_verification
  ) {
    screeningsMap.education_verification = {
      ...screeningsMap.education_verification,
      ...additionalProperties.education_verification,
    };
  }

  if (
    screeningsMap.employment_verification &&
    additionalProperties.employment_verification
  ) {
    screeningsMap.employment_verification = {
      ...screeningsMap.employment_verification,
      ...additionalProperties.employment_verification,
    };
  }

  if (
    screeningsMap.professional_license_verification &&
    additionalProperties.professional_license_verification
  ) {
    screeningsMap.professional_license_verification = {
      ...screeningsMap.professional_license_verification,
      ...additionalProperties.professional_license_verification,
    };
  }

  if (
    screeningsMap.personal_reference_verification &&
    additionalProperties.personal_reference_verification
  ) {
    screeningsMap.personal_reference_verification = {
      ...screeningsMap.personal_reference_verification,
      ...additionalProperties.personal_reference_verification,
    };
  }

  if (
    screeningsMap.professional_reference_verification &&
    additionalProperties.professional_reference_verification
  ) {
    screeningsMap.professional_reference_verification = {
      ...screeningsMap.professional_reference_verification,
      ...additionalProperties.professional_reference_verification,
    };
  }

  if (
    screeningsMap.county_criminal_search &&
    additionalProperties.county_criminal_search
  ) {
    screeningsMap.county_criminal_search = {
      ...screeningsMap.county_criminal_search,
      ...additionalProperties.county_criminal_search,
    };
  }

  if (
    screeningsMap.state_criminal_search &&
    additionalProperties.state_criminal_search
  ) {
    screeningsMap.state_criminal_search = {
      ...screeningsMap.state_criminal_search,
      ...additionalProperties.state_criminal_search,
    };
  }
  if (
    screeningsMap.motor_vehicle_report &&
    additionalProperties.motor_vehicle_report
  ) {
    screeningsMap.motor_vehicle_report = {
      ...screeningsMap.motor_vehicle_report,
      ...additionalProperties.motor_vehicle_report,
    };
  }

  basePackage.screenings.forEach((screening, index) => {
    if (
      screening.type === 'personal_reference_verification' ||
      screening.type === 'professional_reference_verification'
    ) {
      const requiredQuantity = screening.required_quantity;
      if (typeof requiredQuantity === 'number') {
        basePackage.screenings[index] = {
          ...screening,
          required_quantity: [true, requiredQuantity.toString()],
        };
      }
    }
  });

  const standaloneCountyCrim = additionalProperties?.county_criminal_search
    ? additionalProperties.county_criminal_search
        .standalone_county_criminal_search
    : [];

  const standaloneStateCrim = additionalProperties?.state_criminal_search
    ? additionalProperties.state_criminal_search
        .standalone_state_criminal_search
    : [];

  let body = {
    ...basePackage,
    aliases_enabled: aliasesEnabled,
    name: packageName,
    screenings: [...basePackage.screenings, ...Object.values(screeningsMap)],
    private: isPrivate,
    standalone_county_criminal_search: standaloneCountyCrim,
    standalone_state_criminal_search: standaloneStateCrim,
  };

  if (setSlug) {
    body = {
      ...body,
      slug: buildPackageSlug(packageName),
    };
  } else {
    delete body.slug;
  }

  body = correctBasePackageSKUForAliases(body);

  return body;
};

export const buildPostBodyWithAddOns = ({
  basePackage,
  addedScreeningTypes,
  additionalProperties,
  packageName,
  setSlug,
  isPrivate = true,
  origin = '',
  partner_origin_id = '',
}) => {
  // create an object of screening payloads keyed by screening type
  const screeningsMap = addedScreeningTypes.reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: screeningPayloadsByType[curr],
    };
  }, {});
  if (
    screeningsMap.international_criminal_search_v2 &&
    !basePackage.name.includes('International')
  ) {
    if (!screeningsMap.international_adverse_media_search) {
      screeningsMap.international_adverse_media_search = {
        type: 'international_adverse_media_search',
        out_of_country_history: true,
      };
      addedScreeningTypes.push('international_adverse_media_search');
    }
  }

  // for screenings with additional properties, add those properties to the corresponding payload objects
  if (screeningsMap.facis_search && additionalProperties.facis_search) {
    screeningsMap.facis_search = {
      ...screeningsMap.facis_search,
      ...additionalProperties.facis_search,
    };
  }

  if (screeningsMap.drug_screening) {
    screeningsMap.drug_screening = {
      ...screeningsMap.drug_screening,
      ...additionalProperties.drug_screening,
    };
  }

  if (screeningsMap.occupational_health_screening) {
    screeningsMap.occupational_health_screening = {
      ...screeningsMap.occupational_health_screening,
      ...additionalProperties.occupational_health_screening,
    };
  }

  if (
    screeningsMap.education_verification &&
    additionalProperties.education_verification
  ) {
    screeningsMap.education_verification = {
      ...screeningsMap.education_verification,
      ...additionalProperties.education_verification,
    };
  }

  if (
    screeningsMap.employment_verification &&
    additionalProperties.employment_verification
  ) {
    screeningsMap.employment_verification = {
      ...screeningsMap.employment_verification,
      ...additionalProperties.employment_verification,
    };
  }

  if (
    screeningsMap.professional_license_verification &&
    additionalProperties.professional_license_verification
  ) {
    screeningsMap.professional_license_verification = {
      ...screeningsMap.professional_license_verification,
      ...additionalProperties.professional_license_verification,
    };
  }

  if (
    screeningsMap.personal_reference_verification &&
    additionalProperties.personal_reference_verification
  ) {
    screeningsMap.personal_reference_verification = {
      ...screeningsMap.personal_reference_verification,
      ...additionalProperties.personal_reference_verification,
    };
  }

  if (
    screeningsMap.professional_reference_verification &&
    additionalProperties.professional_reference_verification
  ) {
    screeningsMap.professional_reference_verification = {
      ...screeningsMap.professional_reference_verification,
      ...additionalProperties.professional_reference_verification,
    };
  }

  if (
    screeningsMap.county_criminal_search &&
    additionalProperties.county_criminal_search
  ) {
    screeningsMap.county_criminal_search = {
      ...screeningsMap.county_criminal_search,
      ...additionalProperties.county_criminal_search,
    };
  }

  if (
    screeningsMap.state_criminal_search &&
    additionalProperties.state_criminal_search
  ) {
    screeningsMap.state_criminal_search = {
      ...screeningsMap.state_criminal_search,
      ...additionalProperties.state_criminal_search,
    };
  }

  if (
    screeningsMap.motor_vehicle_report &&
    additionalProperties.motor_vehicle_report
  ) {
    screeningsMap.motor_vehicle_report = {
      ...screeningsMap.motor_vehicle_report,
      ...additionalProperties.motor_vehicle_report,
    };
  }

  basePackage.screenings.forEach((screening, index) => {
    if (
      screening.type === 'personal_reference_verification' ||
      screening.type === 'professional_reference_verification'
    ) {
      const requiredQuantity = screening.required_quantity;
      if (typeof requiredQuantity === 'number') {
        basePackage.screenings[index] = {
          ...screening,
          required_quantity: [true, requiredQuantity.toString()],
        };
      }
    }
  });

  const standaloneCountyCrim = additionalProperties?.county_criminal_search
    ?.fips_code
    ? [additionalProperties?.county_criminal_search?.fips_code]
    : [];

  const standaloneStateCrim = additionalProperties?.state_criminal_search
    ?.abbreviation
    ? [additionalProperties?.state_criminal_search?.abbreviation]
    : [];

  let body = {
    ...basePackage,
    name: packageName,
    screenings: [...basePackage.screenings, ...Object.values(screeningsMap)],
    private: isPrivate,
    standalone_county_criminal_search: standaloneCountyCrim,
    standalone_state_criminal_search: standaloneStateCrim,
    ...(origin ? { origin } : {}),
    ...(partner_origin_id ? { partner_origin_id } : {}),
  };

  if (setSlug) {
    body = {
      ...body,
      slug: buildPackageSlug(packageName),
    };
  } else {
    delete body.slug;
  }

  body = correctBasePackageSKUForAliases(body);

  return body;
};

export const getDollarAmount = (val = '') =>
  (val / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

export const getFacisPrice = (itemPrices, subtype) => {
  return itemPrices.length === 0
    ? 'Loading price...'
    : getDollarAmount(
        itemPrices?.find(
          item =>
            item.product_type === 'facis_search' && item.subtype === subtype,
        )?.base_price,
      );
};

export const getProfessionalLicenseVerificationPrice = itemPrices => {
  return itemPrices.length === 0
    ? 'Loading price...'
    : getDollarAmount(
        itemPrices?.find(
          item =>
            item.product_type === 'professional_license_verification' ||
            item.product_type === 'pro_license_verification',
        )?.base_price,
      );
};

export const getEmploymentVerificationPrice = (
  itemPrices,
  maximum_allowed_employers,
  lookback_years,
  screeningSettingPrice,
  additionalProperties,
) => {
  if (additionalProperties?.dot_compliance) {
    return getDollarAmount(
      screeningSettingPrice?.find(
        item => item.product_type === 'employment_verification',
      )?.base_price,
    );
  }
  return getDollarAmount(
    itemPrices?.find(item => {
      const lookbackYears =
        !item.maximum_allowed_employers &&
        item.lookback_years === lookback_years;

      const currentOrMostRecentEmployer =
        additionalProperties?.maximum_allowed_employers &&
        additionalProperties?.lookback_years;

      return (
        item.product_type === 'employment_verification' &&
        (item.maximum_allowed_employers === Number(maximum_allowed_employers) ||
          (lookbackYears && !currentOrMostRecentEmployer))
      );
    })?.base_price,
  );
};

export const getPersonalReferenceVerificationPrice = (
  itemPrices,
  required_quantity,
) => {
  return getDollarAmount(
    itemPrices?.find(
      item =>
        item.product_type === 'personal_reference_verification' &&
        item.required_quantity[1] === required_quantity,
    )?.base_price,
  );
};

export const getProfessionalReferenceVerificationPrice = (
  itemPrices,
  required_quantity,
) => {
  return getDollarAmount(
    itemPrices?.find(
      item =>
        item.product_type === 'professional_reference_verification' &&
        item.required_quantity[1] === required_quantity,
    )?.base_price,
  );
};

export const getAdditionalPropertiesLabel = (
  type,
  additionalProperties = {},
) => {
  if (
    (type === 'professional_license_verification' ||
      type === 'pro_license_verification') &&
    additionalProperties?.professional_license_verification?.license_categories
  ) {
    return [
      additionalProperties.professional_license_verification.license_categories[1].replace(
        /[^a-z0-9()\s]/gi,
        '',
      ),
    ];
  }
  if (
    type === 'education_verification' &&
    additionalProperties?.education_verification?.international_upgrade
  ) {
    return ['Out-of-country history'];
  }
  if (type === 'employment_verification') {
    if (additionalProperties?.employment_verification?.international_upgrade) {
      if (!additionalProperties?.employment_verification?.label) {
        return [
          additionalProperties?.employment_verification
            ?.selectedEmploymentScreen?.label,
          'Include out-of-country history',
        ];
      }
      return [
        additionalProperties?.employment_verification?.label,
        'Include out-of-country history',
      ];
    }
    if (additionalProperties?.employment_verification?.dot_compliance) {
      if (!additionalProperties?.employment_verification?.label) {
        return [
          additionalProperties?.employment_verification
            ?.selectedEmploymentScreen?.label,
          'DOT Employment Verification',
        ];
      }
      return [
        additionalProperties?.employment_verification?.label,
        'DOT Employment Verification',
      ];
    }
    if (!additionalProperties?.employment_verification?.selectedScreening) {
      return [
        additionalProperties?.employment_verification?.label ||
          'Employers within last 7 years',
      ];
    }
    return [
      additionalProperties?.employment_verification?.selectedScreening?.label,
    ];
  }
  if (type === 'drug_screening' && additionalProperties.drug_screening) {
    return [additionalProperties.drug_screening.selectedDrugScreen.label];
  }
  if (
    type === 'occupational_health_screening' &&
    additionalProperties.occupational_health_screening
  ) {
    return additionalProperties.occupational_health_screening.selectedOccupationalHealth.map(
      occ => occ.label,
    );
  }
  if (
    type === 'county_criminal_search' &&
    additionalProperties.county_criminal_search
  ) {
    return [`${additionalProperties.county_criminal_search.name} county`];
  }
  if (
    type === 'state_criminal_search' &&
    additionalProperties.state_criminal_search
  ) {
    return [additionalProperties.state_criminal_search.name];
  }
  if (
    type === 'motor_vehicle_report' &&
    additionalProperties?.motor_vehicle_report?.mvr_type &&
    additionalProperties?.motor_vehicle_report?.mvr_type[0] &&
    additionalProperties?.motor_vehicle_report?.mvr_type[1] === 'commercial'
  ) {
    return ['Requires commercial MVR'];
  }

  return [];
};

export const getScreeningSummaryLabel = (type, additionalProperties = {}) => {
  if (type === 'facis_search' && additionalProperties.facis_search?.subtype) {
    return `FACIS Level ${additionalProperties.facis_search.subtype}`;
  }

  if (type === 'employment_verification') {
    return `Employment Verification`;
  }

  if (
    type === 'personal_reference_verification' &&
    additionalProperties?.personal_reference_verification?.required_quantity
  ) {
    return (
      <>
        {
          additionalProperties.personal_reference_verification
            ?.required_quantity
        }
        <span>
          <CloseIcon icon='Close' viewBox='0 -4 32 32' />
        </span>
        Personal Reference Verification
      </>
    );
  }
  if (
    type === 'professional_reference_verification' &&
    additionalProperties?.professional_reference_verification?.required_quantity
  ) {
    return (
      <>
        {
          additionalProperties.professional_reference_verification
            ?.required_quantity
        }
        <span>
          <CloseIcon icon='Close' viewBox='0 -4 32 32' />
        </span>
        Professional Reference Verification
      </>
    );
  }

  return (
    {
      ssn_trace: 'SSN Trace',
      child_abuse_search: 'Child Abuse Search',
      credit_report: 'Credit Report',
      drug_screening: 'Drug Screening',
      eviction_search: 'Eviction Search',
      sex_offender_search: 'Sex Offender Search',
      global_watchlist_search: 'Global Watchlist Search',
      county_criminal_search: 'County Criminal Search',
      national_criminal_search: 'National Criminal Search',
      state_criminal_search: 'State Criminal Search',
      federal_criminal_search: 'Federal Criminal Search',
      municipal_criminal_search: 'Municipal Criminal Search',
      occupational_health_screening: 'Occupational Health Screening',
      professional_license_verification: 'Professional License Verification',
      pro_license_verification: 'Professional License Verification',
      facis_search: 'FACIS Search',
      healthcare_sanctions_search: 'Healthcare Sanctions Search',
      international_criminal_search_v2: 'Out-of-Country Criminal History',
      international_right_to_work: 'UK right to work',
      right_to_work_uk: 'UK right to work',
      international_adverse_media_search: 'Out-of-Country Criminal Search',
      international_criminal_or_adverse_media:
        'Criminal search outside the US or adverse media search',
      international_education_verification:
        'Education verification outside the US',
      international_employment_verification:
        'Employment verification outside the US',
      international_identity_document_validation:
        'Identity document validation',
      international_global_watchlist_search: 'Global watchlist search',
      international_motor_vehicle_report: 'International Motor Vehicle Report',
      international_canada_bc_enhanced_criminal_search:
        'Canada BC Enhanced Criminal Search',
      county_civil_search: 'County Civil Search',
      federal_civil_search: 'Federal Civil Search',
      motor_vehicle_report: 'Motor Vehicle Report',
      drug_alcohol_clearinghouse: 'Drug Alcohol Clearinghouse',
      employment_verification: 'Employment Verification',
      education_verification: 'Education Verification',
      personal_reference_verification: 'Personal Reference Verification',
      professional_reference_verification:
        'Professional Reference Verification',
      identity_verification: 'Identity Verification',
    }[type] || type
  );
};

export function translatePackage() {
  return {
    name: 'US Package',
    slug: 'us_package',
    screenings: [
      {
        type: 'ssn_trace',
      },
      {
        type: 'sex_offender_search',
      },
      {
        type: 'global_watchlist_search',
      },
      {
        type: 'national_criminal_search',
        subtype: 'standard',
      },
      {
        type: 'federal_criminal_search',
        subtype: '10years',
      },
    ],
  };
}

const findStateSource = (stateSources, name) => {
  return stateSources.find(source => {
    return source.name === name;
  });
};

export const mapPackage = _package => {
  const postPackage = {
    name: _package.name,
    slug: _package.slug,
    price: _package.price,
    screenings: [],
  };

  postPackage.base_package_sku = _package.base_package_sku || null;
  postPackage.source_version_id = _package.version_id;
  postPackage.applicant_pay = _package.applicant_pay;
  postPackage.apply_url = _package.apply_url;
  postPackage.use_alias_skus = _package.use_alias_skus;

  postPackage.requires_fmcsa_compliance = _package.requires_fmcsa_compliance;

  postPackage.dl_history_verification_enabled =
    _package.dl_history_verification_enabled;

  postPackage.county_coverage_for_all_state_searches =
    _package.county_coverage_for_all_state_searches;

  postPackage.skip_county_fees = _package.skip_county_fees;

  postPackage.skip_mandatory_county_fees = _package.skip_mandatory_county_fees;

  if (_package.is_default_package) {
    postPackage.is_default_package = _package.is_default_package;
  }

  if (_package.aliases_enabled) {
    postPackage.aliases_enabled = _package.aliases_enabled;
  }

  if (_package.request_aliases_hard_cap_threshold) {
    postPackage.request_aliases_hard_cap_threshold =
      _package.request_aliases_hard_cap_threshold;
  }

  postPackage.protected_invitation_flow = _package.protected_invitation_flow;

  postPackage.criminal_self_disclosure = _package.criminal_self_disclosure;

  postPackage.skip_self_disclosure_validation =
    _package.skip_self_disclosure_validation;

  postPackage.skip_ssn_collection_on_candidate_create =
    _package.skip_ssn_collection_on_candidate_create;

  postPackage.education_history_based_county_criminal_searches =
    _package.education_history_based_county_criminal_searches;

  postPackage.education_history_based_state_criminal_searches =
    _package.education_history_based_state_criminal_searches;

  postPackage.employment_history_based_county_criminal_searches =
    _package.employment_history_based_county_criminal_searches;

  postPackage.employment_history_based_state_criminal_searches =
    _package.employment_history_based_state_criminal_searches;

  postPackage.candidates_can_declare_no_ssn =
    _package.candidates_can_declare_no_ssn;

  postPackage.skip_criminal_searches_in_inaccessible_states_and_counties =
    _package.skip_criminal_searches_in_inaccessible_states_and_counties;

  postPackage.skip_criminal_searches_in_inaccessible_states_and_counties_type =
    _package.skip_criminal_searches_in_inaccessible_states_and_counties_type;

  postPackage.minors_allowed = _package.minors_allowed;

  postPackage.assess_auto_engage_eligible_reports =
    _package.assess_auto_engage_eligible_reports;

  postPackage.county_criminal_search_skip =
    _package.county_criminal_search_skip;

  postPackage.has_screenings_requiring_data =
    _package.has_screenings_requiring_data;

  postPackage.continuous_check = _package.continuous_check;

  if (_package.override_international_report_locales) {
    postPackage.international_locales_for_report =
      _package.international_locales_for_report;
  }

  postPackage.international_only = _package.international_only;

  postPackage.address_history_lookback_years =
    _package.address_history_lookback_years;

  if (_package.skip_ssn_trace_exceptions)
    postPackage.skip_ssn_trace_exceptions = JSON.parse(
      _package.skip_ssn_trace_exceptions,
    );

  if (
    _package.pointer_lookback_in_years_for_county &&
    _package.pointer_lookback_in_years_for_county.toString() !== 'current'
  ) {
    postPackage.county_criminal_search_scope = `${_package.pointer_lookback_in_years_for_county.toString()}years`;
  }

  try {
    _package?.screenings?.forEach(screening => {
      if (screening.type === 'ssn_trace') {
        postPackage.screenings.push({
          type: 'ssn_trace',
          ssn_trace_exception_thin_file: [
            screening.preferences?.ssn_trace_exception_thin_file?.enabled,
            screening.preferences?.ssn_trace_exception_thin_file?.value,
          ],
          ssn_trace_exception_data_mismatch: [
            screening.preferences?.ssn_trace_exception_data_mismatch?.enabled,
            screening.preferences?.ssn_trace_exception_data_mismatch?.value,
          ],
          ssn_trace_exception_name_mismatch: [
            screening.preferences?.ssn_trace_exception_name_mismatch?.enabled,
            screening.preferences?.ssn_trace_exception_name_mismatch?.value,
          ],
          ssn_trace_exception_dob_mismatch: [
            screening.preferences?.ssn_trace_exception_dob_mismatch?.enabled,
            screening.preferences?.ssn_trace_exception_dob_mismatch?.value,
          ],
          ssn_trace_exception_death_master_file_hit: [
            screening.preferences?.ssn_trace_exception_death_master_file_hit
              ?.enabled,
            screening.preferences?.ssn_trace_exception_death_master_file_hit
              ?.value,
          ],
          ssn_trace_exception_invalid_issuance_year: [
            screening.preferences?.ssn_trace_exception_invalid_issuance_year
              ?.enabled,
            screening.preferences?.ssn_trace_exception_invalid_issuance_year
              ?.value,
          ],
        });
      } else if (screening.type === 'sex_offender_search') {
        postPackage.screenings.push({
          type: 'sex_offender_search',
        });
      } else if (screening.type === 'global_watchlist_search') {
        postPackage.screenings.push({
          type: 'global_watchlist_search',
        });
      } else if (screening.type === 'national_criminal_search') {
        postPackage.screenings.push({
          type: 'national_criminal_search',
          subtype: screening.subtype,
        });
      } else if (screening.type === 'county_criminal_search') {
        postPackage.screenings.push({
          type: 'county_criminal_search',
          subtype: screening.subtype,
          requires_education_history:
            screening.preferences.requires_education_history,
          requires_employment_history:
            screening.preferences.requires_employment_history,
        });

        if (_package.always_search_counties_values.length > 0) {
          postPackage.additional_counties =
            _package.always_search_counties_values.map(entry => ({
              state: entry[0],
              county: entry[1],
            }));
        }
      } else if (screening.type === 'state_criminal_search') {
        const co_state_source = findStateSource(
          _package?.screening_settings,
          'co_state_source_name',
        );
        const pa_state_source = findStateSource(
          _package?.screening_settings,
          'pa_state_source_name',
        );
        const wi_state_source = findStateSource(
          _package?.screening_settings,
          'wi_state_source_name',
        );

        postPackage.screenings.push({
          type: 'state_criminal_search',
          subtype: screening.subtype,
          requires_education_history:
            screening.preferences.requires_education_history,
          requires_employment_history:
            screening.preferences.requires_employment_history,
          co_state_source_name: [
            co_state_source?.enabled,
            co_state_source?.value,
          ],
          pa_state_source_name: [
            pa_state_source?.enabled,
            pa_state_source?.value,
          ],
          wi_state_source_name: [
            wi_state_source?.enabled,
            wi_state_source?.value,
          ],
        });

        if (_package.always_search_states_values.length > 0) {
          postPackage.additional_states = _package.always_search_states_values;
        }

        if (_package.only_search_states_values.length > 0) {
          postPackage.only_search_states = _package.only_search_states_values;
        }
      } else if (screening.type === 'federal_criminal_search') {
        postPackage.screenings.push({
          type: 'federal_criminal_search',
          subtype: screening.subtype,
        });
      } else if (screening.type === 'motor_vehicle_report') {
        const mvrTypeSetting = _package.screening_settings.find(
          setting => setting.name === 'mvr_type',
        );
        postPackage.screenings.push({
          type: 'motor_vehicle_report',
          ...(mvrTypeSetting && {
            mvr_type: [mvrTypeSetting.enabled, mvrTypeSetting.value],
          }),
          preferences: screening.preferences,
        });
      } else if (screening.type === 'drug_alcohol_clearinghouse') {
        postPackage.screenings.push({
          type: 'drug_alcohol_clearinghouse',
        });
      } else if (screening.type === 'identity_verification') {
        postPackage.screenings.push({
          type: 'identity_verification',
        });
      } else if (screening.type === 'education_verification') {
        const requiresCompletedDegrees =
          screening.preferences.requires_completed_degrees;
        postPackage.screenings.push({
          type: 'education_verification',
          international_upgrade: screening.preferences.international_upgrade,
          requires_education_history: screening.preferences.requires_history,
          requires_completed_degrees: requiresCompletedDegrees,
          document_collection: screening.preferences.document_collection,
          maximum_allowed_schools:
            screening.preferences.maximum_allowed_schools,
          preferences: screening.preferences,
        });
      } else if (screening.type === 'personal_reference_verification') {
        postPackage.screenings.push({
          type: 'personal_reference_verification',
          required_quantity: screening.preferences.required_quantity,
        });
      } else if (screening.type === 'professional_reference_verification') {
        postPackage.screenings.push({
          type: 'professional_reference_verification',
          required_quantity: screening.preferences.required_quantity,
        });
      } else if (screening.type === 'child_abuse_search') {
        const childAbuseSearchStates =
          _package.child_abuse_search_state_values.toString();
        postPackage.screenings.push({
          type: 'child_abuse_search',
          subtype: childAbuseSearchStates,
        });

        if (_package.child_abuse_search_state_values.length > 0) {
          postPackage.child_abuse_search_states = childAbuseSearchStates;
        }
      } else if (screening.type === 'employment_verification') {
        const maximumAllowedEmployers =
          screening.preferences.maximum_allowed_employers;
        const lookbackYears = screening.preferences.maximum_lookback_years;
        postPackage.screenings.push({
          type: 'employment_verification',
          subtype: screening.subtype,
          name: screening.preferences.name,
          label: screening.preferences.label,
          maximum_allowed_employers: maximumAllowedEmployers,
          lookback_years: lookbackYears,
          position_matching: screening.preferences.position_matching,
          date_matching: screening.preferences.date_matching,
          manager_contact: screening.preferences.manager_contact,
          salary: screening.preferences.salary,
          contract_type: screening.preferences.contract_type,
          international_upgrade: screening.preferences.international_upgrade,
          requires_employment_history: screening.preferences.requires_history,
          requires_full_lookback: screening.preferences.requires_full_lookback,
          document_collection: screening.preferences.document_collection,
          dot_compliance: screening.preferences.dot_compliance,
          preferences: screening.preferences,
        });
      } else if (screening.type === 'facis_search') {
        postPackage.screenings.push({
          type: 'facis_search',
          subtype: screening.subtype,
        });
      } else if (screening.type === 'healthcare_sanctions_search') {
        postPackage.screenings.push({
          type: 'healthcare_sanctions_search',
        });
      } else if (screening.type === 'eviction_search') {
        postPackage.screenings.push({
          type: 'eviction_search',
        });
      } else if (screening.type === 'credit_report') {
        postPackage.screenings.push({
          type: 'credit_report',
          global_subtype: screening.preferences?.global_subtype,
        });
      } else if (screening.type === 'professional_license_verification') {
        postPackage.screenings.push({
          type: 'pro_license_verification',
          input_required: [screening.preferences.input_required.enabled],
          license_categories: [
            screening.preferences.license_categories.enabled,
            screening.preferences.license_categories.value,
          ],
          locations: [
            screening.preferences.locations.enabled,
            screening.preferences.locations.value,
          ],
        });
      } else if (screening.type === 'international_criminal_search_v2') {
        postPackage.screenings.push({
          type: 'international_criminal_search_v2',
          out_of_country_history: true,
        });
      } else if (screening.type === 'international_right_to_work') {
        postPackage.screenings.push({
          type: 'international_right_to_work',
        });
      } else if (screening.type === 'international_criminal_search') {
        postPackage.screenings.push({
          type: 'international_criminal_search',
        });
      } else if (screening.type === 'international_adverse_media_search') {
        postPackage.screenings.push({
          type: 'international_adverse_media_search',
          out_of_country_history: true,
        });
      } else if (screening.type === 'municipal_criminal_search') {
        postPackage.screenings.push({
          type: 'municipal_criminal_search',
        });
      } else if (screening.type === 'county_civil_search') {
        postPackage.screenings.push({
          type: 'county_civil_search',
          subtype: screening.subtype,
        });
      } else if (screening.type === 'federal_civil_search') {
        postPackage.screenings.push({
          type: 'federal_civil_search',
        });
      } else if (screening.type === 'international_education_verification') {
        postPackage.screenings.push({
          type: 'international_education_verification',
        });
      } else if (screening.type === 'international_employment_verification') {
        postPackage.screenings.push({
          type: 'international_employment_verification',
        });
      } else if (screening.type === 'international_global_watchlist_search') {
        postPackage.screenings.push({
          type: 'international_global_watchlist_search',
        });
      } else if (
        screening.type === 'international_identity_document_validation'
      ) {
        postPackage.screenings.push({
          type: 'international_identity_document_validation',
        });
      } else if (
        screening.type === 'international_canada_bc_enhanced_criminal_search'
      ) {
        postPackage.screenings.push({
          type: 'international_canada_bc_enhanced_criminal_search',
        });
      } else if (screening.type === 'international_motor_vehicle_report') {
        postPackage.screenings.push({
          type: 'international_motor_vehicle_report',
        });
      } else if (screening.type === 'occupational_health_screening') {
        postPackage.screenings.push({
          type: 'occupational_health_screening',
          subtype: screening.subtype.split(','),
        });
      } else if (screening.type === 'drug_screening') {
        const test_reason = _package.screening_settings.find(
          setting =>
            setting.screening_type === 'drug_screening' &&
            setting.name === 'test_reason',
        );

        const test_reason_value = test_reason?.value || 'PRE_EMPLOYMENT';

        const externally_ordered = _package.screening_settings.find(
          setting =>
            setting.screening_type === 'drug_screening' &&
            setting.name === 'externally_ordered',
        );

        const requires_observed_collection = _package.screening_settings.find(
          setting =>
            setting.screening_type === 'drug_screening' &&
            setting.name === 'requires_observed_collection',
        );

        postPackage.screenings.push({
          type: 'drug_screening',
          subtype: screening.subtype.split(','),
          test_reason: [true, test_reason_value],
          externally_ordered: externally_ordered?.enabled,
          requires_observed_collection: requires_observed_collection?.enabled,
        });
      } else {
        // eslint-disable-next-line no-console
        console.error('Unexpected screening', screening.type);
        throw new Error('Unexpected screening');
      }
    });
  } catch (err) {
    if (err.message === 'Unexpected screening') {
      return null;
    }
    throw err;
  }
  return postPackage;
};

export function sleep(delay) {
  const start = new Date().getTime();
  while (new Date().getTime() < start + delay);
}

// Determines what aliases_enabled value gets used by the request body
export function getAliasEnabled(
  basePackageAliasesEnabled,
  stateAliasesEnabled,
) {
  return basePackageAliasesEnabled !== AliasesEnabledType.OFF &&
    stateAliasesEnabled === AliasesEnabledType.OFF
    ? basePackageAliasesEnabled
    : stateAliasesEnabled;
}

export function findObjectByKey(array, key, value) {
  return array.find(object => {
    return object[key] === value;
  });
}

export default buildPostBodyWithAddOns;

import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

type ContainerProps = {
  flexDirection?: 'column' | 'row';
  gap?: string;
  fullWidth?: boolean;
};

export const Container = styled.div.attrs({
  className: 'packages-page-container',
})<ContainerProps>`
  padding: 0 64px 0 64px;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'column'};
  align-items: flex-start;
  gap: ${({ gap }) => gap ?? '24'}px;
  align-self: stretch;

  @media (max-width: 992px) {
    padding: 0 24px 0 24px;
  }

  @media (max-width: 420px) {
    padding: 0;
  }
`;

export const TitleContainer = styled.div.attrs({
  className: 'title-container',
})`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const TitleContainerWithButton = styled(TitleContainer)`
  flex-direction: row;
  justify-content: space-between;
`;

export const PageSubtitleContainer = styled.div.attrs({
  className: 'page-subtitle-container',
})`
  display: flex;
  gap: 8px;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const PageSubtitle = styled.p.attrs({
  className: 'page-subtitle p2',
})`
  #mastodon & {
    color: ${colors.uiTextSecondaryLight};
    margin-top: 0;
  }
`;

export const PriceDisclaimer = styled.p.attrs({
  className: 'price-disclaimer p4',
})`
  #mastodon & {
    color: ${colors.uiTextTertiaryLight};
    margin-bottom: 0;
  }
`;

export const PassthroughFeesDisclaimer = styled.span.attrs({
  className: 'passthrough-fees-disclaimer p4',
})`
  #mastodon & {
    color: ${colors.uiTextTertiaryLight};
  }
`;

export const DisclaimerLink = styled(M.Link).attrs(props => ({
  className: 'learn-more',
  target: '_blank',
  href: 'https://checkr.com/pricing/passthrough-fees',
}))`
  font-size: inherit;
  margin-left: 4px;
`;

export const PaymentContainer = styled.div.attrs({
  className: 'payment-details-section-container',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  .cds--text-input__invalid-icon--warning path:first-of-type {
    fill: white !important;
  }
`;

export const Section = styled.div.attrs({
  className: 'payment-details-section',
})`
  display: flex;
  gap: 24px;
  padding: 0 0 32px 0;
  width: 100%;
  border-bottom: 1px solid ${colors.uiGrey200};
  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 992px) {
    flex-direction: column;

    &:last-child {
      flex-direction: row;
    }
  }

  @media (max-width: 720px) {
    &:last-child {
      flex-direction: column;
    }
  }
`;

export const FlexColumn = styled.div.attrs({
  className: 'payment-details-flex-column',
})`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 33%;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const SectionDescription = styled.div.attrs({
  className: 'payment-details-section-description',
})`
  #mastodon & {
    color: ${colors.uiTextTertiaryLight};
    margin-top: 0;
  }
`;

type FormSectionProps = {
  gap?: string;
};

export const FormSection = styled.div.attrs({
  className: 'payment-details-form-section',
})<FormSectionProps>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap || '16px'};
  width: 66%;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from 'context/CurrentUser';
import { multiUserAccountSwitch } from 'utils/AuthUtils';
import { fetchUser } from 'actions';
import { useDispatch } from 'react-redux';
import Header from './header';

const NavigationContainer = () => {
  const { pathname } = useLocation();
  const user = useUser();

  const dispatch = useDispatch();

  const handleAccountSwitch = useCallback(
    async selected => {
      await multiUserAccountSwitch(selected.account_id);
      dispatch(fetchUser());
    },
    [dispatch],
  );

  const handleLogout = useCallback(() => {
    window.location.href = '/logout';
  }, []);

  return (
    <Header
      user={user}
      pathname={pathname}
      handleAccountSwitch={handleAccountSwitch}
      handleLogout={handleLogout}
      isCollapsed={false}
    />
  );
};

export default NavigationContainer;

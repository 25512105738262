import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AssessmentVariant } from 'types/report/assessmentVariants';
import AssessmentDropdownItem from './all-assessments-dropdown-item';

type Props = {
  options: AssessmentVariant[];
};

const AssessmentDropdownItems: React.FC<Props> = ({ options }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const searchParams = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const assessmentParam = new URLSearchParams(search).get(
    'assessment_variant_registration_id',
  );
  const currentSelection = assessmentParam || options[0]?.id;

  const updateAssessmentSearchUrl = useCallback(
    (assessmentId: string) => {
      if (assessmentParam === assessmentId) return;
      searchParams.set('assessment_variant_registration_id', assessmentId);

      history.push({
        pathname,
        search: searchParams.toString(),
      });
    },
    [assessmentParam, history, pathname, searchParams],
  );

  const onSelect = useCallback(
    (assessmentId: string) => {
      updateAssessmentSearchUrl(assessmentId);
    },
    [updateAssessmentSearchUrl],
  );

  useEffect(() => {
    updateAssessmentSearchUrl(currentSelection);
  }, [currentSelection, updateAssessmentSearchUrl]);

  const optionsList = options.map(option => {
    return (
      <AssessmentDropdownItem
        key={option.id}
        option={option}
        onSelect={onSelect}
        isSelected={option.id === currentSelection}
      />
    );
  });

  return (
    <div data-testid='all-assessments-action-menu-items'>{optionsList}</div>
  );
};

export default AssessmentDropdownItems;

import React from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { DRIVER_LICENSE_HISTORY_DISPLAY } from 'Constants';

const DrivingExperience: React.FC = () => {
  // TODO: update MultiiMotorVehicleReport tests
  const flagrEnabled =
    useFlag(DRIVER_LICENSE_HISTORY_DISPLAY)?.variantKey === 'true';

  if (!flagrEnabled) {
    return null;
  }

  return (
    <div data-testid='mvr-driving-experience'>
      {/* TODO: [EADA-5135] Implement driving experience */}
    </div>
  );
};

export default DrivingExperience;

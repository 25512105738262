import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PricingSummary } from '../PricingSummary';
import { namespace } from '../../locales';
import {
  StyledLink,
  StyledSubNote,
  StyledSubNoteText,
} from './PricingSection.styles';

const PricingSection = () => {
  const { selectedPackage } = useBetterOrderExperienceContext();
  const { t } = useTranslation(namespace);

  const subtotal = selectedPackage.price; // TODO plus addons plus alias

  return (
    <>
      <PricingSummary
        packagePrice={selectedPackage.price}
        addonPrice={1000}
        subtotal={subtotal}
        minTotalFees={0}
        maxTotalFees={13500}
      />
      <StyledSubNote $showBorder={selectedPackage.price !== 0}>
        <StyledSubNoteText>
          {t(`reviewAndSubmit.infoSection.pricingSection.excludes`)}{' '}
          <StyledLink
            href='https://checkr.com/pricing/pass-through-fees'
            data-testid='footer-learn-more'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t(`reviewAndSubmit.infoSection.pricingSection.passthroughFees`)}
          </StyledLink>
          {t(`reviewAndSubmit.infoSection.pricingSection.remainingPricingText`)}
        </StyledSubNoteText>
      </StyledSubNote>
    </>
  );
};

export default PricingSection;

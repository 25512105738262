import { FlagrContext } from '@dashboard-experience/react-flagr';
import { REPORTS_REDESIGN_EXCLUSIONS } from 'Flags';
import { useCallback, useContext } from 'react';
import { BetaContext } from './context';
import { BetaState } from './types';

export const useGetBetaState = () => {
  const flagrContext = useContext(FlagrContext) as {
    flags: any;
  };
  const betaContext = useContext(BetaContext);

  const redesignExcludedAccount =
    flagrContext.flags[REPORTS_REDESIGN_EXCLUSIONS]?.variantKey === 'disabled';

  return useCallback(
    (flagKey: string) => {
      // Automatically opt-in if the account is not in excluded accounts
      if (!redesignExcludedAccount) return BetaState.OPT_IN;

      // If context has been set, use that, otherwise initial state
      return betaContext.betaStates[flagKey] || BetaState.INITIAL;
    },
    [betaContext.betaStates, redesignExcludedAccount],
  );
};

export const useBetaState = (flagKey: string) => useGetBetaState()(flagKey);

export const useShowBeta = (flagKey: string) =>
  useContext(BetaContext).showBeta(flagKey);
export const useHideBeta = (flagKey: string) =>
  useContext(BetaContext).hideBeta(flagKey);

export const useBetaEnabled = (flagKey: string, initialEnabled = true) => {
  const betaState = useBetaState(flagKey);
  if (betaState === BetaState.OPT_IN) return true;
  // Whether or not the "initial" state should count as enabled or not
  if (initialEnabled && betaState === BetaState.INITIAL) return true;
  return false;
};

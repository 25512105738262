import { useCandidate } from 'providers/Candidate';

const useHasPostHire = () => {
  const candidate = useCandidate();
  const noGeos = candidate?.geos?.length === 0;
  const isDomestic = candidate?.geos?.some(geo => {
    const isUnitedStates = geo.country === 'US';
    // geo.country can be null if the customer selected a custom domestic geo
    const isCustomDomesticGeo = geo.country === null;
    return isUnitedStates || isCustomDomesticGeo;
  });

  // Geos can be an empty array if account.geos_required = false & customer skipped this upon ordering a bgc
  // For context: skipping the geos selecting in the order background check flow filters package selection by domestic packages
  // Internatonal candidates cannot order subscription & continuous
  return noGeos || isDomestic;
};

export default useHasPostHire;

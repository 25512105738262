import * as AddonUtils from 'components/AddScreenings/shared/utils';
import { useQuery } from 'react-query';
import { AccountsClient as client } from 'api';
import { ADDON_SKU_IDS } from 'components/AddScreenings/shared/screeningPayloads';

// eslint-disable-next-line import/prefer-default-export
export function useGetScreeningPrices(accountId: string, skip: boolean) {
  const path = `${accountId}/packages/addon_prices`;

  const params = {
    sku_ids: ADDON_SKU_IDS,
  };

  const allAddOnPricesQuery = useQuery(
    [accountId],
    async () => {
      const addon = await client.get(path, { params });
      return addon;
    },
    {
      staleTime: 24 * 60 * 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: skip,
    },
  );
  return AddonUtils.mapAddonSkus(allAddOnPricesQuery);
}

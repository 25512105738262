import React, { useCallback } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BoldText = styled.h6`
  padding-top: 1rem;
  font-weight: bold;
  color: ${colors.uiTextPrimaryLight} !important;
`;

const BulkActionText = ({ setIsEnrollCsvTemplate }) => {
  const updateCsvTemplate = useCallback(value => {
    if (value === 'yes') {
      setIsEnrollCsvTemplate(false);
    } else {
      setIsEnrollCsvTemplate(true);
    }
  }, []);

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <BoldText>
          Did your business run an MVR on these candidates through Checkr?
        </BoldText>
      </div>
      <div style={{ marginBottom: '16px' }}>
        <M.RadioButtonGroup
          onChange={updateCsvTemplate}
          name='bulk-action-text-rb-group'
        >
          <M.RadioButton labelText='Yes' value='yes' />
          <M.RadioButton labelText='No' value='no' />
        </M.RadioButtonGroup>
      </div>
    </div>
  );
};

BulkActionText.propTypes = {
  setIsEnrollCsvTemplate: PropTypes.func,
};

export default BulkActionText;

import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { namespace } from '../../../locales';

type StatusCardProps = {
  status: 'pending' | 'invited' | 'clear' | 'review' | 'escalated' | 'disputed';
  value: number | Error;
  isLoading: boolean;
  handleRedirect: (...args: any[]) => void;
  isShown?: boolean;
};

const StatusCard = ({
  status,
  value,
  isLoading,
  handleRedirect,
  isShown = true,
}: StatusCardProps) => {
  const { t } = useTranslation();

  if (!isShown) return null;

  const title = t(`${namespace}:statusCards.${status}`);

  return (
    <styles.StatusCardContainer
      id={`status-card-${status}`}
      onClick={handleRedirect}
      key={`status-card-${status}`}
      data-testid={`status-card-${status}`}
      status={status}
    >
      <styles.StatusCardColor status={status} />
      <styles.StatusCardPrimaryTextContainer>
        {isLoading ? (
          <M.LoadingTableRow rows={1} columns={1} />
        ) : (
          <styles.StatusCardPrimaryText
            data-testid={`status-card-${status}-value`}
          >
            {/* {typeof value === 'object' ? 'Error' : value} */}
            {value}
          </styles.StatusCardPrimaryText>
        )}
        <styles.StatusCardIcon
          icon='ArrowRight'
          size={16}
          data-testid={`status-card-${status}-icon`}
        />
      </styles.StatusCardPrimaryTextContainer>
      <styles.StatusCardSecondaryTextContainer>
        <label
          htmlFor={`status-card-${status}`}
          data-testid={`status-card-${status}-label`}
        >
          {title}
        </label>
      </styles.StatusCardSecondaryTextContainer>
    </styles.StatusCardContainer>
  );
};

export default StatusCard;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import { useManualBulkOrder, useUploadCSV } from 'api/packages';
import { useCreateCandidate } from 'api/invitations';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { accountHasPermission } from '@dashboard-experience/utils';
import { toastError } from 'actions';
import { useDispatch } from 'react-redux';
import { Footer, StepContainer } from '../ui';
import { getGeoWorkLocation, getWorkLocation } from './ReviewAndSubmitUtils';
import { namespace } from '../locales';
import { InfoSection } from './InfoSection';
import { SelectedPackageInfoSection } from './SelectedPackageInfoSection';
import { CandidateInfoSection } from './CandidateInfoSection';
import { StyledInfoText } from './CandidateInfoSection/CandidateInfoSection.styles';
import { PricingSection } from './PricingSection';
import {
  StyledLink,
  StyledOrderCertifiedCheckbox,
  StyledOrderCertifiedText,
} from './ReviewAndSubmitStep.styles';
import { CustomizationsInfoSection } from './CustomizationsInfoSection';
import { SelectedAddOn } from './CustomizationsInfoSection/CustomizationsInfoSection';
import createInvitation, {
  CreateInvitationParams,
} from '../utils/createInvitation';
import { SubmitModal } from './SubmitModal';

const ReviewAndSubmitStep: React.FC<{}> = () => {
  const { t } = useTranslation(namespace);
  const toastDispatch = useDispatch();

  const [orderCertified, setOrderCertified] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const currentUser = useUser();
  const {
    account: { segmentation_enabled },
  } = currentUser;

  const {
    geo,
    location,
    manualOrderType,
    infoSource,
    localeType,
    selectedPackage,
    node,
    program,
    paymentProfile,
    emails,
    invites,
    manualBulkUploadData,
    csv,
  } = useBetterOrderExperienceContext();

  const workLocationValue = !segmentation_enabled
    ? getGeoWorkLocation(geo)
    : getWorkLocation(location);

  const { call: createCandidateCall, result: createCandidateResult } =
    useCreateCandidate();
  const { uploadCall, uploadResult } = useUploadCSV();
  const { call: manualBulkCall, result: manualBulkResult } =
    useManualBulkOrder();

  const hasCandidateInfo =
    manualOrderType === 'MULTIPLE' || infoSource === 'CANDIDATE';

  const showPrices =
    accountHasPermission(currentUser, 'show_package_price') &&
    currentUser?.account?.package_price_state !== 'disabled via partner' &&
    localeType !== 'INTERNATIONAL';

  const { contextId } = useContext(UIContext);

  const closeModal = useCallback(() => {
    setShowSuccessModal(false);
  }, []);

  const goToAddOns = useCallback(() => {
    const path = '/order-experience/add-ons';

    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const goToGetStarted = useCallback(() => {
    const path = '/order-experience/get-started';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const handleSelectedPackageEdit = useCallback(() => {
    const path = '/order-experience/select-your-package';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const handleOrderCertifiedClick = useCallback(() => {
    setOrderCertified(!orderCertified);
  }, [orderCertified]);

  const handleBack = useCallback(() => {
    const addonsAvailable = true;
    const path = addonsAvailable
      ? '/order-experience/add-ons'
      : '/order-experience/select-your-package';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const submitOrder = useCallback(() => {
    setIsSubmitting(true);
    const manualBulkOrderEnabled = manualOrderType === 'MULTIPLE';
    const bulkInviteEnabled: boolean = !!csv.fileStorageKey;

    if (infoSource === 'MYSELF' && !manualBulkOrderEnabled) {
      const path = '/order-experience/manual-entry';
      if (contextId) {
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      } else {
        history.push(path);
      }
      return;
    }

    const selection = {
      node,
      geo,
      program,
      country: location.country,
      city: location.city,
      state: location.state,
      package: { ...selectedPackage, aliases_enabled: false }, // TODO },
      payment_profile: paymentProfile,
    };

    createInvitation({
      selection,
      emails,
      csv,
      call: createCandidateCall,
      uploadCall,
      currentUser,
      bulkInviteEnabled,
      addons: [],
      manualBulkOrderEnabled,
      manualBulkCall,
      manualBulkUploadData,
      invites,
    } as unknown as CreateInvitationParams);
  }, [
    contextId,
    createCandidateCall,
    csv,
    currentUser,
    emails,
    geo,
    history,
    infoSource,
    invites,
    location.city,
    location.country,
    location.state,
    manualBulkCall,
    manualBulkUploadData,
    manualOrderType,
    node,
    paymentProfile,
    program,
    selectedPackage,
    uploadCall,
  ]);

  const handleSuccessfulSubmit = useCallback(() => {
    setIsSubmitting(false);
    setShowSuccessModal(true);
    localStorage.removeItem('betterOrderExperience');
  }, []);

  useEffect(() => {
    if (uploadResult.isError) {
      setIsSubmitting(false);
      toastDispatch(toastError(`ERROR: ${uploadResult?.error?.message}`));
      return;
    }

    if (uploadResult.isSuccess) {
      handleSuccessfulSubmit();
    }
  }, [handleSuccessfulSubmit, toastDispatch, uploadResult]);

  useEffect(() => {
    if (createCandidateResult.isError) {
      setIsSubmitting(false);
      toastDispatch(
        toastError(`ERROR: ${createCandidateResult?.error?.message}`),
      );
      createCandidateResult.reset();
      return;
    }

    if (createCandidateResult.isSuccess) {
      handleSuccessfulSubmit();

      createCandidateResult.reset();
    }
  }, [createCandidateResult, handleSuccessfulSubmit, toastDispatch]);

  useEffect(() => {
    if (manualBulkResult.isError) {
      setIsSubmitting(false);
      toastDispatch(toastError(`ERROR: ${manualBulkResult?.error?.message}`));
      return;
    }

    if (manualBulkResult.isSuccess) {
      handleSuccessfulSubmit();

      manualBulkResult.reset();
    }
  }, [handleSuccessfulSubmit, manualBulkResult, toastDispatch]);

  // addon prices api data sends back `items`
  // item has product_type => screening.type
  // item has base_price -> price of add on
  // TODO create a way to map item to screening type
  const addOns = [
    {
      screening: { type: 'federal_criminal_search' },
      price: 1000,
    },
  ] as SelectedAddOn[];

  const submitButtonDisabled = !orderCertified || isSubmitting;

  useEffect(() => {
    if (uploadResult.isSuccess) {
      setShowSuccessModal(true);
    }

    if (createCandidateResult.isSuccess) {
      setShowSuccessModal(true);
      createCandidateResult.reset();
    }

    if (manualBulkResult.isSuccess) {
      setShowSuccessModal(true);
      manualBulkResult.reset();
    }
  }, [createCandidateResult, manualBulkResult, uploadResult.isSuccess]);

  return (
    <StepContainer data-testid='review-and-submit-step-container'>
      {showSuccessModal && (
        <SubmitModal
          currentUser={currentUser}
          handleClose={closeModal}
          contextId={contextId}
          open={showSuccessModal}
          csv={csv}
          manualOrderType={manualOrderType}
        />
      )}
      {hasCandidateInfo && (
        <>
          <StyledInfoText>
            {t(`reviewAndSubmit.infoSection.candidateInfoText`)}
          </StyledInfoText>
          <InfoSection section='candidateInfo' onEditClick={goToGetStarted}>
            <CandidateInfoSection />
          </InfoSection>
        </>
      )}
      {workLocationValue && (
        <InfoSection section='workLocation' onEditClick={goToGetStarted}>
          {workLocationValue}
        </InfoSection>
      )}
      <InfoSection
        section='selectedPackage'
        onEditClick={handleSelectedPackageEdit}
      >
        <SelectedPackageInfoSection selectedPackage={selectedPackage} />
      </InfoSection>
      {addOns.length > 0 && (
        <InfoSection section='customizations' onEditClick={goToAddOns}>
          <CustomizationsInfoSection addOns={addOns} showPrices={showPrices} />
        </InfoSection>
      )}
      {showPrices && <PricingSection />}
      <StyledOrderCertifiedCheckbox
        id='review-and-submit-order-legal-check'
        data-testid='order-certified-checkbox'
        checked={orderCertified}
        onChange={handleOrderCertifiedClick}
        labelText={
          <StyledOrderCertifiedText>
            {manualOrderType === 'MULTIPLE'
              ? `${t('reviewAndSubmit.manualBulkOrderCertified')}`
              : `${t('reviewAndSubmit.orderCertified')}`}
            <StyledLink
              data-testid='checkr-services-agreement-link'
              href='https://checkr.com/customer-agreement'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('reviewAndSubmit.checkrServiceAgreement')}
            </StyledLink>
          </StyledOrderCertifiedText>
        }
      />
      <Footer>
        <M.Button
          data-testid='review-step-back-btn'
          type='button'
          onClick={handleBack}
        >
          {t('reviewAndSubmit.footerButtons.goBack')}
        </M.Button>
        <M.Button
          type='button'
          data-testid='review-submit-order-btn'
          disabled={submitButtonDisabled}
          onClick={submitOrder}
        >
          {t('reviewAndSubmit.footerButtons.submitOrder')}
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default ReviewAndSubmitStep;

import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import * as Ruleset from 'modules/assess/models/rulesets/ruleset';
import { useTranslation } from 'react-i18next';
import { AssignmentV2 } from 'modules/assess/models/rulesets/assignment';
import Actions from './Actions';
import Content from './Content';
import { useAssignments, useUpdate } from '../assign/hooks';

type Props = {
  ruleset?: Ruleset.Type;
  rulesets?: Array<Ruleset.Type>;
  mode?: 'edit' | 'publish';
  open: boolean;
  onClose?: (event?: SyntheticEvent) => void;
};

export const Modal: React.FC<Props> = ({
  ruleset,
  rulesets,
  mode,
  open,
  onClose,
}) => {
  const { t } = useTranslation('assess');
  const modalTitle = t('assess:ruleset.apply.title', {
    id: ruleset?.id,
    name: ruleset?.name,
    interpolation: { escapeValue: false },
  });

  const [assignments] = useAssignments(ruleset);
  const [isTouched, setIsTouched] = useState(false);
  const [assignmentPayload, setAssignmentPayload] = useState<AssignmentV2[]>(
    [],
  );

  const update = useUpdate(ruleset?.id);

  const reset = useCallback(() => {
    setIsTouched(false);
    onClose && onClose();
  }, [onClose]);

  const onSave = useCallback(() => {
    update.call(assignmentPayload);
  }, [assignmentPayload, update]);

  useEffect(() => {
    if (update.result.status === 'success' && onClose) {
      setIsTouched(false);
      onClose();
      update.result.reset();
    }
  }, [update.result, onClose]);

  if (!open) {
    return null;
  }

  return (
    <M.ComposedModal
      data-testid='assess-ruleset-apply-modal'
      open={open}
      type='default'
      onClose={reset}
    >
      <M.ModalHeader
        data-testid='assess-ruleset-apply-title'
        closeModal={reset}
        title={modalTitle}
      />
      <M.ModalBody data-testid='assess-ruleset-apply-content'>
        <Content
          assignments={assignments}
          setAssignmentPayload={setAssignmentPayload}
          mode={mode}
          rulesets={rulesets}
          setIsTouched={setIsTouched}
        />
      </M.ModalBody>
      <M.ModalFooter data-testid='assess-ruleset-apply-actions'>
        <Actions
          onCancel={reset}
          onSave={onSave}
          disabled={isTouched === false || update.result.status === 'loading'}
        />
      </M.ModalFooter>
    </M.ComposedModal>
  );
};

export default Modal;

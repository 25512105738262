import type { AddonsT } from '.';
import { ScreeningProductType } from './AddonsStep.enums';

// eslint-disable-next-line import/prefer-default-export
export const hasAllBasicPackageScreenings = (
  screenings: AddonsT.ScreeningType[],
) => {
  const basicScreenings = [
    ScreeningProductType.SSN_TRACE,
    ScreeningProductType.GLOBAL_WATCHLIST_SEARCH,
    ScreeningProductType.SEX_OFFENDER_SEARCH,
    ScreeningProductType.NATIONAL_CRIMINAL_SEARCH,
  ];
  return basicScreenings.every(screening => screenings.includes(screening));
};

export const getDollarAmount = (val: number) =>
  (val / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

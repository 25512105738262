import React, { useEffect, useState } from 'react';
import { GenericObject } from '@dashboard-experience/utils';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { DASHBOARD_LANDING_EVENT_NAMES, useTrackEvent } from 'utils';
import DefaultComplianceModal from 'components/DefaultComplianceModal/DefaultComplianceModal';
import { useUser } from 'context/CurrentUser';
import { Header, Sections } from './ui';

const HomePageSectionContainer = styled.div<{
  backgroundColor?: string;
}>`
  background-color: ${props => props.backgroundColor || colors.uiGrey0};
  @media only screen and (min-width: 0px) {
    width: 100% +48px;
    margin: 0 -24px;
    padding: 32px 16px;
  }
  @media only screen and (min-width: 672px) {
    padding: 32px 64px;
  }
  @media only screen and (min-width: 768px) {
    width: 100% +128px;
    margin: 0 -64px;
    padding: 0 16px 32px;
  }
  @media only screen and (min-width: 912px) {
    padding: 0 64px 32px;
  }
  @media only screen and (min-width: 1320px) {
    padding: 0 104px 32px;
  }
  @media only screen and (min-width: 1576px) {
    padding: 0 216px 32px;
  }
  @media only screen and (min-width: 1848px) {
    padding: 0 331px 32px;
  }
  @media only screen and (min-width: 2127px) {
    padding: 0 calc(((100vw - 264px - 1200px) / 2)) 32px
      calc(((100vw - 264px - 1200px) / 2));
  }

  display: flex;
  flex-direction: column;
  gap: 32px;
`;

type Props = {
  trackedModals?: GenericObject;
};

const DashboardLanding: React.FC<Props> = ({ trackedModals }) => {
  const [showModal, setShowModal] = useState(false);
  const [geoModalSubmitted, setGeoModalSubmitted] = useState(false);
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const currentUser = useUser();
  const isSignup = location.pathname.includes('signup');

  useEffect(() => {
    const submitted = trackedModals?.some(
      (modal: GenericObject) => modal.name === 'default_location',
    );
    setGeoModalSubmitted(submitted);
  }, [trackedModals]);

  useEffect(() => {
    if (!isSignup) {
      trackEvent(DASHBOARD_LANDING_EVENT_NAMES.DASHBOARD_HOME_PAGE_VIEWED);
      if (!currentUser?.account?.has_report_or_invitation) {
        trackEvent(
          DASHBOARD_LANDING_EVENT_NAMES.DASHBOARD_FOX_HOME_PAGE_VIEWED,
        );
      }
    }
  }, [isSignup, trackEvent]);

  return (
    <div data-dd-privacy='allow'>
      <Header />
      {!isSignup && (
        <DefaultComplianceModal
          showModal={showModal}
          setShowModal={setShowModal}
          setGeoModalSubmitted={setGeoModalSubmitted}
        />
      )}
      <HomePageSectionContainer data-testid='home-metrics-section-container'>
        <M.GridRow>
          <Sections.OverviewMetrics />
        </M.GridRow>
      </HomePageSectionContainer>
      <HomePageSectionContainer data-testid='home-general-sections-container'>
        <M.GridRow>
          <Sections.SetupAndExplore
            setShowModal={setShowModal}
            showModal={showModal}
            geoModalSubmitted={geoModalSubmitted as boolean}
          />
        </M.GridRow>
        <M.GridRow>
          <Sections.Resources />
        </M.GridRow>
        <M.GridRow>
          <Sections.BlogPosts />
        </M.GridRow>
      </HomePageSectionContainer>
    </div>
  );
};

export default DashboardLanding;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import { M } from '@dashboard-experience/mastodon';
import { StyledLookbackModalBody } from './VerificationSection.styles';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const LookbackInfoModal: React.FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation(namespace, {
    keyPrefix:
      'addOns.verificationSection.employmentVerification.lookbackInfoModal',
  });
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <M.ComposedModal
      data-testid='employment-verification-learn-more-dialog'
      open={open}
      onClose={onClose}
    >
      <M.ModalHeader
        data-testid='employment-verification-learn-more-title'
        closeModal={onClose}
      >
        {t('header')}
      </M.ModalHeader>
      <StyledLookbackModalBody data-testid='employment-verification-learn-more-content'>
        <p>{t('description1')}</p>

        <p>{t('description2')}</p>
      </StyledLookbackModalBody>
    </M.ComposedModal>
  );
};

export default LookbackInfoModal;

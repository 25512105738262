import { useState, useEffect } from 'react';
import {
  initializeAndStart,
  getExperiment,
} from 'components/amplitudeExperiment';

export default function useAmplitudeExperiment() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [variant, setVariant] = useState<string | null>(null);

  useEffect(() => {
    async function initializeExperiment() {
      try {
        const experiment = await initializeAndStart({});
        if (experiment) {
          await experiment.fetch();
          const experimentVariant = getExperiment()?.variant(
            'ab-13-mvt-on-signup-packages-page',
          ).value;
          setVariant(experimentVariant);
        }
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    }
    initializeExperiment();
  }, []);

  return { loading, error, variant };
}

import axios from 'axios';
import { useQuery } from 'react-query';
import { CLICKWRAP_API_KEY } from 'Constants';
import useGetUser from './useGetUser';

const getActivities = async (id: string) => {
  const response = await axios.get(
    `https://api.pactsafe.com/v1.1/activity?filter=custom_data.account_resource_id==${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${CLICKWRAP_API_KEY}`,
      },
    },
  );
  return response.data;
};

function useGetClickwrapActivities() {
  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useGetUser();
  const request = () => getActivities(user.account.id);

  const result = useQuery(['clickwrap-activities'], request, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !isUserLoading && !userError && user !== undefined,
  });

  const { isLoading, error, data } = result;

  return {
    data,
    isLoading,
    error,
  };
}

export default useGetClickwrapActivities;

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import PageLoading from 'components/PageLoading';
import { ID } from 'modules/id';
import { useReadOnly } from 'modules/assess/ui/hooks';
import { Ruleset } from 'modules/assess/models/rulesets';
import { useDraftPublish } from '../api/hooks';
import Dialog from '../publish';
import { Container as ApplyDialog } from '../../../../v2/ui/review-and-apply/dialog/Container';
import * as Assign from '../../menu/Assign';
import { useSearch } from '../../../rulesets/api/hooks';

type Props = {
  ruleset?: Ruleset.Type;
  version?: ID;
};

type Type = React.FC<Props>;

export const PublishV2: Type = ({ ruleset, version }) => {
  const publish = useDraftPublish(ruleset?.id);
  const history = useHistory();
  const searchRulesets = useSearch({});
  const rulesets = searchRulesets?.data?.data || [];

  const isLoading = publish.result.status === 'loading';

  const [dialogState, setDialogState] = useState({
    open: false,
    openApply: false,
    openAssign: false,
  });

  const handleOpen = useCallback(
    dialog => () => {
      setDialogState(prevState => ({ ...prevState, [dialog]: true }));
    },
    [],
  );

  const handleClose = useCallback(
    (dialog, redirect) => () => {
      setDialogState(prevState => ({ ...prevState, [dialog]: false }));
      if (redirect) {
        history.push('active');
      }
    },
    [history],
  );

  useEffect(() => {
    if (publish.result.status === 'success') {
      setDialogState({ open: false, openApply: true, openAssign: false });
    }
  }, [publish.result.status]);

  return (
    <>
      <PageLoading enabled={isLoading} />
      <Button
        disabled={useReadOnly()}
        data-testid='assess-ruleset-version-actions-publish'
        kind='primary'
        size='sm'
        trans='verbs.publish'
        onClick={handleOpen('open')}
      />
      <Dialog
        disabled={isLoading}
        open={dialogState.open}
        publish={publish.call}
        version={version}
        onClose={handleClose('open', false)}
      />
      <ApplyDialog
        open={dialogState.openApply}
        onClose={handleClose('openApply', true)}
        onClick={handleOpen('openAssign')}
        rulesetName={ruleset?.name}
      />
      <Assign.Dialog
        ruleset={ruleset}
        rulesets={rulesets}
        mode='publish'
        open={dialogState.openAssign}
        onClose={handleClose('openAssign', true)}
      />
    </>
  );
};

export default PublishV2;

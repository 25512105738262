import AccessCodeInput from 'components/MotorVehicle/AccessCodeInput';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Jurisdiction } from 'api/drivingJurisdictions/actions';
import { useGetAccessCode } from 'api/drivingJurisdictions/hooks';

const DrivingJurisdictionDisplay = ({
  accessCode,
  accountId,
  jurisdiction,
  setAccessCodesInput,
  setExistingAccessCodes,
}: {
  accessCode: string;
  accountId: string;
  jurisdiction: Jurisdiction;
  setAccessCodesInput: Dispatch<SetStateAction<Record<string, string | null>>>;
  setExistingAccessCodes: Dispatch<SetStateAction<Record<string, string>>>;
}) => {
  const { data: fetchedCode, isSuccess: fetchedCodeSuccess } = useGetAccessCode(
    {
      abbr: jurisdiction.code,
      accountId,
    },
  );

  useEffect(() => {
    if (fetchedCodeSuccess && fetchedCode) {
      const abbr = fetchedCode.driving_jurisdiction.code;
      const accessCode = fetchedCode.access_code;
      setAccessCodesInput(prev => ({ ...prev, [abbr]: accessCode }));
      setExistingAccessCodes(prev => ({ ...prev, [abbr]: accessCode }));
    }
  }, [fetchedCode, setAccessCodesInput, setExistingAccessCodes]);

  return (
    <AccessCodeInput
      jurisdiction={jurisdiction}
      accessCode={accessCode}
      setAccessCodesInput={setAccessCodesInput}
    />
  );
};

export default DrivingJurisdictionDisplay;

import React, { useCallback, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDismissI9Upsell } from 'api/accounts';
import { useGetAccountSettings } from 'api/i9';
import { I9_ALLOWED_USER_ROLES } from 'Constants';
import { useFlag } from '@dashboard-experience/react-flagr';
import { I9_ORDERING } from 'Flags';
import * as queryString from 'querystring';
import { useUser } from 'context/CurrentUser';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  StyledButton,
  StyledContainerInner,
  StyledFlexWrap,
} from './I9UpsellSection.styles';
import { StyledUpsellWrap } from './SubmitModal.styles';
import { namespace } from '../../locales';

const I9UpsellSection = () => {
  const { t } = useTranslation(namespace);
  const history = useHistory();
  const currentUser = useUser();
  const { isLoading, isError } = useGetAccountSettings();
  const { call } = useDismissI9Upsell();
  const hasI9Permissions = I9_ALLOWED_USER_ROLES.some(r =>
    currentUser?.roles?.includes(r),
  );
  const isI9UpsellFlagOn = useFlag(I9_ORDERING)?.variantKey === 'on';
  const isI9SetupAvailable = !isLoading && isError;
  const accountId = currentUser?.account?.id;
  const [isHideI9UpsellChecked, setIsHideI9UpsellChecked] = useState(false);
  const isI9Dismissed = currentUser?.account?.dismiss_i9_upsell;
  const showI9UpsellSection =
    isI9UpsellFlagOn && hasI9Permissions && !isI9Dismissed;

  const aliasesEnabledChanged = true;

  const handleI9Redirect = useCallback(() => {
    const queryParams = { source: 'order' };
    const queryStringified = queryString.stringify(queryParams);
    history.push({
      pathname: '/i-9',
      search: queryStringified,
    });
  }, [history]);

  const handleHideI9UpsellClick = useCallback(() => {
    const updatedI9UpsellState = !isHideI9UpsellChecked;

    setIsHideI9UpsellChecked(updatedI9UpsellState);

    call({ accountId, dismissStatus: true });
  }, [accountId, call, isHideI9UpsellChecked]);

  if (isLoading && showI9UpsellSection) {
    return <M.LoadingInline />;
  }

  return (
    <>
      {showI9UpsellSection && isI9SetupAvailable && aliasesEnabledChanged && (
        <StyledUpsellWrap>
          <h6>{t('submitModal.i9Upsell.header')}</h6>
          <p>{t('submitModal.i9Upsell.subHeader')}</p>
          <StyledContainerInner>
            <StyledFlexWrap>
              <div>
                <h6>{t('submitModal.i9Upsell.title')}</h6>
                <p>{t('submitModal.i9Upsell.subTitle')}</p>
              </div>
              <StyledButton kind='tertiary' onClick={handleI9Redirect}>
                <M.Icon icon='ArrowRight' size={24} />
              </StyledButton>
            </StyledFlexWrap>
            <M.Checkbox
              id='hideI9Upsell'
              onChange={handleHideI9UpsellClick}
              checked={isHideI9UpsellChecked}
              labelText={t('submitModal.i9Upsell.checkBoxText')}
            />
          </StyledContainerInner>
        </StyledUpsellWrap>
      )}
    </>
  );
};

export default I9UpsellSection;

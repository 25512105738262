import React, { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import { hasPermission } from '@dashboard-experience/utils';
import { useReport } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, CANDIDATE_REPORT_EVENT_NAMES } from 'utils/analytics';
import { useReportPropertiesForAmplitudeEvents } from 'api/reports';
import DownloadActionItems from './download-actions-items';
import useGetPdfItems from './useGetPdfItems';

const ActionMenu = styled(M.ActionMenu)`
  #mastodon && {
    align-self: flex-start;
    .mastodon-action-menu-trigger {
      svg {
        fill: ${colors.uiGrey900};
        fill-opacity: 0.78;
      }
    }
    .mastodon-action-menu-container {
      z-index: 1000;
    }
  }
`;

const StyledTooltipWrapper = styled.div`
  .cds--popover--caret {
    --cds-popover-offset: 0.25rem;
  }

  .cds--popover-caret {
    visibility: hidden;
  }
`;

const DownloadActions = () => {
  const report = useReport();
  const currentUser = useUser();

  const { pdfItems, showPdfEncryptionNote } = useGetPdfItems();

  const includeDownloadPdf =
    hasPermission(currentUser, 'read_reports') ||
    hasPermission(currentUser, 'download_health_pdf');

  const [thereWasAction, setThereWasAction] = useState(false);

  const onOpenMenu = useCallback(() => {
    setThereWasAction(false);
  }, [setThereWasAction]);

  const trackEvent = useTrackEvent();
  const reportProperties = useReportPropertiesForAmplitudeEvents();

  const onCloseMenu = useCallback(() => {
    if (currentUser && !thereWasAction) {
      trackEvent(CANDIDATE_REPORT_EVENT_NAMES.REPORT_DOWNLOAD_PDF_SELECTED, {
        'Document Selected': 'No Action',
        Report: reportProperties,
      });
    }
  }, [currentUser, thereWasAction, reportProperties, trackEvent]);

  const onActionMenuOpen = useCallback(
    open => {
      if (open) {
        onOpenMenu();
      } else {
        onCloseMenu();
      }
    },
    [onOpenMenu, onCloseMenu],
  );

  // Currently there are no download actions for invitations.
  if (report.id === undefined) return null;

  if (!includeDownloadPdf) return null;

  if (!pdfItems.length) return null;

  const trigger = (
    <StyledTooltipWrapper>
      <M.TooltipDefinition
        align='bottom'
        highlighted={false}
        definition='Downloads'
      >
        <M.Button kind='secondary'>
          <M.Icon icon='Download' />
        </M.Button>
      </M.TooltipDefinition>
    </StyledTooltipWrapper>
  );

  return (
    <div data-testid='download-actions-menu'>
      <ActionMenu
        position='left-bottom'
        trigger={trigger}
        onMenuOpen={onActionMenuOpen}
        className='secondary-actions-menu'
      >
        <DownloadActionItems
          report={report}
          user={currentUser}
          setThereWasAction={setThereWasAction}
          pdfItems={pdfItems}
          showPdfEncryptionNote={showPdfEncryptionNote}
        />
      </ActionMenu>
    </div>
  );
};

export default memo(DownloadActions);

import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useDispatch as useCandidateSearchDispatch } from 'components/Search/search-context';
import { GenericObject } from '@dashboard-experience/utils';
import { getSearchQuery } from 'modules/reports-list/utilities';
import { toastError } from '../../actions';
import { performSearch, getCandidates } from './actions';

// eslint-disable-next-line import/prefer-default-export
export const usePerformSearch = () => {
  const dispatch = useDispatch();
  const request = (query: string) => performSearch(query);

  const [performSearchCall, performSearchResult] = useMutation(request, {
    onError: data => {
      dispatch(toastError('ERROR:', data.message));
    },
  });

  return {
    performSearchCall,
    performSearchResult,
  };
};

export const useGetCandidates = () => {
  const request = (parameters: GenericObject) => {
    const { params, account, cancelToken } = parameters;
    const query = getSearchQuery(params, account);
    const url = `/search?${query}`;
    return getCandidates({ url, cancelToken });
  };

  const candidateSearchDispatch = useCandidateSearchDispatch();

  const [call, result] = useMutation(request, {
    onSuccess: res => {
      candidateSearchDispatch({
        type: 'set search',
        payload: {
          candidates: res,
        },
      });
    },
  });
  return {
    call,
    result,
  };
};

import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { DASHBOARD_LANDING_EVENT_NAMES, useTrackEvent } from 'utils';
import { useLocation } from 'react-router-dom';

type DateRange = { id: number; label: string };

type DateRangeDropdownOptionProps = {
  option: DateRange;
  selectedDateRange: DateRange;
  setSelectedDateRange: Dispatch<SetStateAction<DateRange>>;
};

const DateRangeDropdownOption = ({
  option,
  selectedDateRange,
  setSelectedDateRange,
}: DateRangeDropdownOptionProps) => {
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const isSignup = location.pathname.includes('signup');

  const handleDateRangeChange = useCallback(() => {
    setSelectedDateRange(option);

    if (!isSignup) {
      trackEvent(
        DASHBOARD_LANDING_EVENT_NAMES.REPORT_METRICS_DATE_FILTER_CLICKED,
        {
          'Date Filter': option.label,
        },
      );
    }
  }, [isSignup, setSelectedDateRange, trackEvent]);

  return (
    <M.ActionMenuItem
      key={option.id}
      title={option.label}
      kind='node'
      onClick={handleDateRangeChange}
      actionNode={
        option.id === selectedDateRange.id && (
          <M.Icon icon='Checkmark' size='16' />
        )
      }
    />
  );
};

export default DateRangeDropdownOption;

import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: #e6ecf4; // Old checkr blue
  width: 100%;
`;

export const StyledTitle = styled.div`
  color: ${colors.bgPrimaryDark};

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
`;

export const StyledAddonsContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-start;

  @media all and (min-width: 1600px) {
    justify-content: center;
  }

  @media all and (max-width: 1350px) {
    gap: 10px;
    flex-wrap: wrap;
  }

  @media all and (max-width: 1250px) {
    flex-flow: column;
  }

  @media all and (max-width: 1200px) {
    flex-flow: row;
  }

  @media all and (max-width: 1000px) {
    flex-flow: column;
  }
`;

export const StyledAddonScreeningContainer = styled.div`
  display: flex;
  padding: 16px;
  max-width: 232px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.1);

  @media all and (max-width: 1250px) {
    max-width: 100%;
  }

  @media all and (max-width: 1200px) {
    padding: 12px;
    max-width: 232px;
  }

  @media all and (max-width: 1000px) {
    max-width: 100%;
  }
`;

export const StyledScreeningNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledNameAndIcon = styled.div`
  display: flex;
`;

export const StyledScreeningName = styled.div`
  color: ${colors.bgPrimaryDark};
  padding-top: 0.175rem;
  font-size: 14px;
  font-weight: 700;
  line-height: 100%; /* 14px */
  overflow-wrap: break-word;
  @media all and (max-width: 1350px) {
    font-size: 12px;
  }
`;

export const StyledScreeningDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  @media all and (max-width: 1200px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
`;

export const StyledScreeningPrice = styled.div`
  color: ${colors.brandNavy3};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-right: 8px;
`;

export const StyledTooltip = styled(M.TooltipDefinition)`
  border-bottom: none !important;

  .cds--definition-tooltip {
    padding: 1rem;
    max-width: 18rem;
  }
`;

export const StyledHelpIcon = styled(M.Icon)`
  margin-bottom: -3px;
  margin-left: 2px;
`;

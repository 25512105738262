import { Candidate, Report } from 'types';
import { useNestedPreference } from 'api/dashboardPreferences';
import { FieldConfig } from '../types';
import { Invitation } from '../../../../../../types/report/invitation';

type UseGetFieldParams = {
  candidate: Candidate;
  report?: Report;
};

const invitationForReport = (
  invitations: Invitation[] | undefined,
  report: Report | undefined,
): boolean => {
  if (invitations == null || report == null) {
    return false;
  }

  return invitations.some(i => i.report_id === report.id);
};

const useGetFields = ({ candidate, report }: UseGetFieldParams) => {
  const [showPreference] = useNestedPreference('report', 'showEmptyFields');

  // TODO - add more granular reasons for hiding certain fields, based on Domestic vs Intl, as well as certain kinds of verifications.
  const fields: FieldConfig[] = [];

  if (candidate.first_name || showPreference) {
    fields.push({
      getFieldName: () => 'First name',
      fieldKey: 'first_name',
    });
  }

  if (candidate.middle_name || showPreference) {
    fields.push({
      getFieldName: () => 'Middle name',
      fieldKey: 'middle_name',
    });
  }

  if (candidate.last_name || showPreference) {
    fields.push({
      getFieldName: () => 'Last name',
      fieldKey: 'last_name',
    });
  }

  if (candidate.dob || showPreference) {
    fields.push({
      getFieldName: () => 'Date of birth',
      fieldKey: 'date_of_birth',
    });
  }

  if (candidate.ssn || showPreference) {
    fields.push({
      getFieldName: () => 'Social Security #',
      fieldKey: 'social_security',
    });
  }

  if (candidate.zipcode || showPreference) {
    fields.push({
      getFieldName: () => 'Location',
      fieldKey: 'location',
    });
  }

  if (
    candidate.zipcode ||
    candidate.international_postal_code ||
    showPreference
  ) {
    fields.push({
      getFieldName: () => 'Postal code',
      fieldKey: 'postal_code',
    });
  }

  if (candidate.phone || showPreference) {
    fields.push({
      getFieldName: () => 'Phone',
      fieldKey: 'phone',
    });
  }

  if (candidate.email || showPreference) {
    fields.push({
      getFieldName: () => 'Email',
      fieldKey: 'email',
    });
  }

  // Always show custom_id
  fields.push({
    getFieldName: () => 'Custom ID',
    fieldKey: 'custom_id',
  });

  if (
    candidate.driver_license_number ||
    candidate.previous_driver_license_number ||
    showPreference
  ) {
    fields.push({
      getFieldName: () => "Driver's license #",
      fieldKey: 'drivers_license',
    });
  }

  if (candidate.created_at || showPreference) {
    fields.push({
      getFieldName: () => 'Created',
      fieldKey: 'candidate_created_at',
    });
  }

  if (invitationForReport(candidate?.invitations, report)) {
    fields.push({
      getFieldName: () => 'Invitation sent',
      fieldKey: 'invitation_sent',
    });
  }

  return fields;
};

export default useGetFields;

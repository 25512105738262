/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import omit from 'lodash/omit';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import BetterOrderExperienceContext from 'pages/BetterOrderExperience/context';
import AccountContext from 'pages/Account/AccountContext';
import type { AddonsT } from '.';
import { Footer, StepContainer } from '../ui';
import { DomesticScreeningsList } from './DomesticScreeningsList';
import { PopularAddOns } from './PopularAddOns';
import { useGetScreeningPrices } from '../hooks/useGetScreeningPrices';
import { StyledFlex } from './AddonsStep.styles';

const AddonsStep: React.FC<{}> = () => {
  const [
    selectedAddedScreeningTypeWithPrices,
    setSelectedAddedScreeningTypeWithPrices,
  ] = useState<AddonsT.ScreeningTypeWithPrices[]>([]);
  const [selectedAdditionalProperties, setSelectedAdditionalProperties] =
    useState<AddonsT.AdditionalProperties>({});

  // TODO: Update disabled logic
  const disabled = false;
  const history = useHistory();
  const { contextId } = useContext(UIContext);
  const {
    selectedPackage,
    update,
    additionalProperties,
    addedScreeningTypeWithPrices,
  } = useContext(BetterOrderExperienceContext);

  const { account } = useContext(AccountContext);
  const isInternational = false; // TODO (Alice): Update logic

  const addOnPrices = useGetScreeningPrices(account.id ?? '', !isInternational);

  const addedScreeningTypes = useMemo(() => {
    return selectedAddedScreeningTypeWithPrices.map(
      screening => screening.screening.type,
    );
  }, [selectedAddedScreeningTypeWithPrices]);

  const includedScreenings = useMemo(() => {
    if (
      !selectedPackage.screenings ||
      selectedPackage?.screenings?.length === 0
    )
      return [];
    return selectedPackage.screenings.map(
      screening => (screening as { type: string }).type,
    );
  }, [selectedPackage.screenings]);

  const handleBack = useCallback(() => {
    const path = '/order-experience/select-your-package';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const handleContinue = useCallback(() => {
    const path = '/order-experience/review-and-submit';
    update({
      additionalProperties: selectedAdditionalProperties,
      addedScreeningTypeWithPrices: selectedAddedScreeningTypeWithPrices,
    });
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [
    update,
    selectedAdditionalProperties,
    selectedAddedScreeningTypeWithPrices,
    contextId,
    history,
  ]);

  const onAddClick = useCallback(
    (
      screening: AddonsT.ScreeningType,
      price: string,
      additionalProperties?: AddonsT.AdditionalProperties,
    ) => {
      // Check if the screening has already been added
      const hasBeenAdded = selectedAddedScreeningTypeWithPrices?.some(
        addOn => addOn?.screening?.type === screening,
      );

      if (!hasBeenAdded) {
        const newAddonsSelection = [
          ...selectedAddedScreeningTypeWithPrices,
          {
            screening: { type: screening },
            price,
          },
        ];
        setSelectedAddedScreeningTypeWithPrices(newAddonsSelection);
      }

      if (additionalProperties)
        setSelectedAdditionalProperties({
          ...selectedAdditionalProperties,
          ...additionalProperties,
        });
    },
    [selectedAdditionalProperties, selectedAddedScreeningTypeWithPrices],
  );

  const onRemoveClick = useCallback(
    (screening: AddonsT.ScreeningType) => {
      const newSelections = selectedAddedScreeningTypeWithPrices.filter(
        addon => addon.screening.type !== screening,
      );
      setSelectedAddedScreeningTypeWithPrices(newSelections);

      const newAdditionalProperties = omit(
        selectedAdditionalProperties,
        screening,
      );
      setSelectedAdditionalProperties(newAdditionalProperties);
    },
    [selectedAddedScreeningTypeWithPrices, selectedAdditionalProperties],
  );

  useEffect(() => {
    // Set initial values from any persisted data
    if (additionalProperties)
      setSelectedAdditionalProperties(additionalProperties);
    if (addedScreeningTypeWithPrices)
      setSelectedAddedScreeningTypeWithPrices(addedScreeningTypeWithPrices);
  }, [addedScreeningTypeWithPrices, additionalProperties]);

  return (
    <StepContainer data-testid='addons-step-container'>
      <StyledFlex>
        <StyledFlex flexDirection='column'>
          <PopularAddOns
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            addOnPrices={addOnPrices}
            includedScreenings={includedScreenings as AddonsT.ScreeningType[]}
            selectedPackage={selectedPackage}
            additionalProperties={additionalProperties}
            addedScreeningTypes={addedScreeningTypes}
          />
          <DomesticScreeningsList
            onAddClick={onAddClick}
            onRemoveClick={onRemoveClick}
            addedScreeningTypes={addedScreeningTypes}
            addOnPrices={addOnPrices}
            includedScreenings={includedScreenings as AddonsT.ScreeningType[]}
            selectedAdditionalProperties={selectedAdditionalProperties}
            setSelectedAdditionalProperties={setSelectedAdditionalProperties}
          />
        </StyledFlex>
      </StyledFlex>
      <Footer>
        <M.Button
          data-testid='addons-step-back-btn'
          type='button'
          onClick={handleBack}
        >
          Go back
        </M.Button>

        <M.Button
          type='button'
          data-testid='addons-step-continue-btn'
          disabled={disabled}
          onClick={handleContinue}
        >
          Continue
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default AddonsStep;

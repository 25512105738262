import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';

export const StyledPricingContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: end;
  margin: 1.5rem 0 calc(1.5rem - 5px) 0;
`;

export const StyledItemPricing = styled.p`
  color: ${colors.uiTextPrimaryLight};
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
`;

export const StyledSubtotalPricing = styled.p`
  color: ${colors.uiTextPrimaryLight};
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-bottom: 5px !important;
`;

export const StyledLoaderStyled = styled(M.LoadingSpinner)`
  margin: 0 auto;
  vertical-align: center;
`;

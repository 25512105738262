import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

// eslint-disable-next-line import/prefer-default-export
export const ScreeningItemContainer = styled.div`
  display: flex;

  padding: 16px 24px 16px 0px;

  .mastodon-expandable:not(.expanded):hover {
    border-bottom: none !important;
  }

  :not(:last-of-type) {
    border-bottom: 1px solid ${colors.uiGrey200};
  }

  #professional-license-verification-id {
    width: 255px !important;

    @media (max-device-width: 400px) {
      width: 125px !important;
    }
  }
`;

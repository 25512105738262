import React, { ReactNode } from 'react';
import {
  Box,
  ScreeningInfo,
  ExpandTitle,
  Price,
  ExpandableTitle,
} from 'components/AddScreenings/shared/ListItemElements';
import ScreeningSectionItemBulletedList from './ScreeningSectionItemBulletedList';
import { StyledFlex } from '../../AddonsStep.styles';

type Props = {
  id: string;
  screeningName: string;
  description: ReactNode;
  advantages: string[];
  disadvantages: string[];
  price: string;
  initialExpanded?: boolean;
  titleTag?: ReactNode;
};

const ScreeningSectionItem: React.FC<Props> = ({
  id,
  screeningName,
  description,
  advantages,
  disadvantages,
  price,
  initialExpanded = false,
  titleTag,
}) => {
  return (
    <ExpandableTitle
      initialExpanded={initialExpanded}
      title={
        <StyledFlex justifyContent='space-between'>
          <ExpandTitle data-testid={`${id}-screening-name`}>
            <StyledFlex alignItems='center'>
              {screeningName}
              {titleTag && <span>{titleTag}</span>}
            </StyledFlex>
          </ExpandTitle>
          <Price data-testid={`${id}-price`}>{price}</Price>
        </StyledFlex>
      }
    >
      <ScreeningInfo className={`${id}-description-text`}>
        {description}
      </ScreeningInfo>

      <StyledFlex columnGap='1rem'>
        <Box className={`${id}-advantages`}>
          <ScreeningSectionItemBulletedList
            header='Advantages'
            items={advantages}
          />
        </Box>

        <Box className={`${id}-disadvantages`}>
          <ScreeningSectionItemBulletedList
            header='Considerations'
            items={disadvantages}
          />
        </Box>
      </StyledFlex>
    </ExpandableTitle>
  );
};

export default ScreeningSectionItem;

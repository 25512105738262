import React from 'react';
import {
  getScreeningType,
  isInternationalMotorVehicleReport,
  getIncludeDrivingHistorySetting,
} from 'components/Packages/PackageScreenings';
import { Screening } from 'types';
import { ScreeningSetting } from '@dashboard-experience/utils';
import {
  ScreeningListItem,
  ScreeningSettings,
  ScreeningType,
} from './styles/PackageCard.styles';
import { getScreeningSetting } from '../utils/PackageScreeningUtils';
import type { AddonsT } from '../AddonsStep';

const PackageScreeningItem: React.FC<{
  screening: Screening | AddonsT.PostPackageScreeningType;
  screeningSettings?: ScreeningSetting[];
  showScreeningSettings?: boolean;
  compact?: boolean;
}> = ({
  screening = { type: null } as unknown as Screening,
  screeningSettings = [],
  showScreeningSettings = true,
  compact = false,
}) => {
  const screeningType = getScreeningType(screening.type);

  const screeningSetting = getScreeningSetting(
    screening as unknown as Screening,
    screeningSettings,
  );
  return (
    <ScreeningListItem key={`${screening.type}`} compact={compact}>
      <ScreeningType data-testid={`screening-type-${screening.type}`}>
        {screeningType}{' '}
        {showScreeningSettings && (
          <ScreeningSettings>{screeningSetting}</ScreeningSettings>
        )}
        {showScreeningSettings &&
          isInternationalMotorVehicleReport(screening.type) && (
            <ScreeningSettings>
              {`${getIncludeDrivingHistorySetting(screening)}`}
            </ScreeningSettings>
          )}
      </ScreeningType>
    </ScreeningListItem>
  );
};

export default PackageScreeningItem;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '@dashboard-experience/utils';
import PostHireContainer from 'containers/PostHire/PostHireContainer';
import useHasPostHire from 'containers/PostHire/useHasPostHire';
import { useUser } from 'context/CurrentUser';
import { namespace } from 'modules/candidate/locales';
import Content from '../content';
import EntriesPhaseTwo from '../entries-phase-two';

const trans_path = `${namespace}:report.tabs`;

const useGetTabs = () => {
  const user = useUser();
  const { t } = useTranslation();
  const canViewReportLogs = hasPermission(user, 'read_report_logs');
  const hasPostHire = useHasPostHire();

  const tabs = [
    { label: t(`${trans_path}.results`), content: <Content /> },
    ...(canViewReportLogs
      ? [{ label: t(`${trans_path}.report-log`), content: <EntriesPhaseTwo /> }]
      : []),
    ...(hasPostHire
      ? [
          {
            label: t(`${trans_path}.post-hire`),
            content: (
              <div style={{ padding: '32px 40px' }}>
                <PostHireContainer />
              </div>
            ),
          },
        ]
      : []),
  ];
  return tabs;
};

export default useGetTabs;

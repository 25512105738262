import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledCreditReportPendingContainer = styled.div`
  color: ${colors.brandSlate5};
  padding-top: 7px;
`;

export const StyledCreditReportPendingIcon = styled(M.Icon)`
  padding-top: 5px;
`;

export const StyledCreditReportSetupButton = styled(M.Button)`
  height: 40px;
  background: ${colors.uiGrey0} !important;
  color: ${colors.brandNavy4} !important;
  border-color: ${colors.brandNavy4} !important;

  @media (max-device-width: 530px) {
    height: 32px;
  }
`;

export const StyledPendingSetupTag = styled(M.Tag)`
  background: ${colors.brandYellow2};
  margin-left: 8px !important;
  border-radius: 4px;

  font-size: 12px;
`;

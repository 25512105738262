import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { useFlag } from '@dashboard-experience/react-flagr';
import styled from 'styled-components';
import { ACCOUNT_BALANCE_API_CALL } from 'Constants';
import { useGetCredits, useGetCurrentAccountBalance } from 'api/billing';
import { useUser } from 'context/CurrentUser';
import LearnMoreModal from './LearnMoreModal';
import BalanceInformation from './BalanceInformation';
import { AutopayIndicator } from '../Payment';
import './styles.scss';

const StyledHeader = styled.header`
  .mastodon-modal-body {
    p,
    a {
      font-size: 1rem !important;
    }

    p {
      padding-right: 0;

      &:last-of-type {
        padding-bottom: 1.5rem;
      }
    }
  }
`;

const LearnMoreIcon = styled(M.Icon)`
  fill: ${colors.uiBrandNavy};
  margin-right: 8px;
`;

const InfoButton = styled.div`
  #mastodon && {
    .mastodon-button.tertiary svg {
      fill: ${colors.uiBrandNavy};
    }
  }
`;

const IconButton = styled(M.Button)`
  border: none !important;
`;

type Props = {
  autopayEnabled: boolean;
  isCustomerLoading: boolean;
};

const BillingHeader: React.FC<Props> = ({
  autopayEnabled,
  isCustomerLoading,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);
  const closeModal = React.useCallback(() => setModalOpen(false), []);
  const openModal = React.useCallback(() => setModalOpen(true), []);
  const { account = {} } = useUser();
  const { data, isLoading: isCreditLoading } = useGetCredits(account?.id);
  const hasAvailableCredit = !!data && +data.amount > 0;

  const AccountBalanceApiCallFlag =
    useFlag(ACCOUNT_BALANCE_API_CALL)?.variantKey === 'enabled' || false;

  const { data: accountBalance, isLoading: isAccountBalanceLoading } =
    useGetCurrentAccountBalance(AccountBalanceApiCallFlag, account?.id) || {};

  const openBalance = accountBalance?.open_balance;

  const showAccountBalance =
    AccountBalanceApiCallFlag && !isAccountBalanceLoading;
  const showCreditBalance = hasAvailableCredit && !isCreditLoading;

  return (
    <StyledHeader data-testid='billing-header' className='billing-header'>
      <M.GridCol>
        <M.GridRow style={{ paddingBottom: '0.5rem' }}>
          <span className='billing-header-title'>
            {t(`pageHeaders.billing.title`)}
          </span>
          <M.GridCol style={{ textAlign: 'right' }}>
            <InfoButton>
              <IconButton
                kind='tertiary'
                size='small'
                onClick={openModal}
                data-testid='learn-more-button'
                style={{ gap: '0.5rem' }}
              >
                {t(`pageHeaders.billing.learn_more_button`)}
                <LearnMoreIcon icon='Information' />
              </IconButton>
            </InfoButton>
          </M.GridCol>
        </M.GridRow>
        <M.GridRow>
          {!isCustomerLoading && <AutopayIndicator enabled={autopayEnabled} />}
          <BalanceInformation
            openBalance={openBalance}
            showAccountBalance={showAccountBalance}
            credit={data?.amount}
            showCreditBalance={showCreditBalance}
          />
        </M.GridRow>
      </M.GridCol>

      <LearnMoreModal closeModal={closeModal} openModal={modalOpen} />
    </StyledHeader>
  );
};
export default BillingHeader;

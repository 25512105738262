import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { OccupationalService } from '@dashboard-experience/utils';
import OccupationalHealthSetupModal from 'components/AddScreenings/Domestic/List/DrugAndHealthScreenings/OccupationalHealthSetupModal'; // TODO: Move and refactor
import { namespace } from 'components/BetterOrderExperience/locales';
import { StyledFlex } from '../../AddonsStep.styles';
import { ScreeningSectionItem } from '../ScreeningSectionItem';
import { OccHealthStatus, ScreeningProductType } from '../../AddonsStep.enums';
import { AddonsT } from '../..';
import {
  OccHealthServices,
  OCCUPATIONAL_HEALTH_SCREENING_SECTIONS,
  OCCUPATIONAL_HEALTH_SECTIONS,
} from './DrugAndHealth.const';
import {
  StyledPendingSetupTag,
  StyledMultiSelectWrapper,
} from './DrugAndHealthSection.styles';
import OccupationalHealthSetupButtons from './OccupationalHealthSetupButtons';

type Props = {
  id: string;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  price: string;
  isAdded: boolean;
  isIncluded: boolean;
  occHealthScreenings: OccupationalService[];
  status: AddonsT.OccHealthStatusType;
};

const OccupationalHealthScreening: React.FC<Props> = ({
  id,
  price,
  isAdded,
  isIncluded,
  onAddClick,
  onRemoveClick,
  occHealthScreenings,
  status,
}) => {
  const [openSetupModal, setOpenSetupModal] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);

  const [selectedOccHealthScreening, setSelectedOccHealthScreening] = useState(
    [],
  );
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.drugAndHealth.ohs',
  });

  const handleAddClick = useCallback(() => {
    if (!selectedOccHealthScreening?.length) {
      setInvalid(true);
      return;
    }
    setInvalid(false);
    onAddClick(ScreeningProductType.OCCUPATIONAL_HEALTH_SCREENING, price, {
      occupational_health_screening: {
        subtype: selectedOccHealthScreening,
      },
    });
  }, [onAddClick, price, selectedOccHealthScreening]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(ScreeningProductType.OCCUPATIONAL_HEALTH_SCREENING);
  }, [onRemoveClick]);

  const handleChange = useCallback(selectedScreenings => {
    const selectedOHSValues = selectedScreenings.map(
      (screening: OccHealthServices) => screening.value,
    );
    setInvalid(false);
    setSelectedOccHealthScreening(selectedOHSValues);
  }, []);

  const availableOccHealthScreenings = occHealthScreenings.map(
    (ohsScreening: OccupationalService) => {
      const { name, label } = ohsScreening;

      return {
        value: name,
        label,
        disables: OCCUPATIONAL_HEALTH_SCREENING_SECTIONS[name]?.disables ?? [],
        section: OCCUPATIONAL_HEALTH_SCREENING_SECTIONS[name]?.section,
      };
    },
  );

  const handleClose = useCallback(() => {
    setOpenSetupModal(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenSetupModal(true);
  }, []);

  return (
    <StyledFlex flexDirection='column'>
      <OccupationalHealthSetupModal
        open={openSetupModal}
        handleClose={handleClose}
      />
      <StyledFlex>
        <ScreeningSectionItem
          id={id}
          description={t('description')}
          advantages={t('advantages', { returnObjects: true })}
          disadvantages={t('considerations', {
            returnObjects: true,
          })}
          price={price}
          screeningName={t('name')}
          titleTag={
            status === OccHealthStatus.DISABLED ? (
              <StyledPendingSetupTag>{t('pendingTag')}</StyledPendingSetupTag>
            ) : null
          }
        />
        <OccupationalHealthSetupButtons
          id={id}
          isAdded={isAdded}
          isIncluded={isIncluded}
          onAddClick={handleAddClick}
          onRemoveClick={handleRemoveClick}
          status={status}
          handleOpen={handleOpen}
        />
      </StyledFlex>
      {status === OccHealthStatus.ENABLED && (
        <StyledMultiSelectWrapper>
          <M.MultiSelectWithSections
            id='ohs-screening-select'
            titleText={t('titleText')}
            items={availableOccHealthScreenings}
            invalid={invalid}
            invalidText={t('invalidText')}
            sections={OCCUPATIONAL_HEALTH_SECTIONS}
            disabled={isIncluded || isAdded}
            placeholder={t('placeholder')}
            onChange={handleChange}
          />
        </StyledMultiSelectWrapper>
      )}
    </StyledFlex>
  );
};

export default OccupationalHealthScreening;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetReport } from 'api/reports';
import NonActionableBanner from './non-actionable-banner';
import { useReportActionEvents } from '../../../../adjudication/ui/report/events/api';
import { Report } from '../../../../../types';
import { replaceCheckrEmail } from './utils';

const CandidateStoryRequestedBanner = () => {
  const { report }: { report: Report } = useGetReport();
  const { data: reportActionEvents } = useReportActionEvents(report.id);
  const { t } = useTranslation();

  const candidateStoryRequested = reportActionEvents?.candidate_stories?.find(
    event => event.type === 'requested',
  );

  const requested =
    report?.candidate_stories?.length > 0 ||
    report?.tags?.includes('candidate-story-requested');

  if (!requested || !candidateStoryRequested) return null;

  const d = new Date(Date.parse(candidateStoryRequested.time));
  const displayDate = d.toLocaleString('en-us', {
    // @ts-ignore
    dateStyle: 'medium',
  });

  const adjudicatorEmail = replaceCheckrEmail(candidateStoryRequested.user);
  const firstName = report?.candidate?.first_name;

  const title = () => {
    if (firstName && adjudicatorEmail) {
      return t('banners.candidateStoryRequested.description', {
        adjudicatorEmail,
        firstName,
        displayDate,
        interpolation: { escapeValue: false },
      });
    }

    return t('banners.candidateStoryRequested.genericText', {
      displayDate,
    });
  };

  return <NonActionableBanner kind='info' title={title()} />;
};

export default CandidateStoryRequestedBanner;

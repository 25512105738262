import { useNestedPreference } from 'api/dashboardPreferences';
import React from 'react';
import styled from 'styled-components';
import { Exception } from 'types';
import MultipleExceptionsBanner from '../multiple-exceptions-banner';
import SingleExceptionBanner from '../single-exception-banner';

const StyledResolvedExceptionsBanners = styled.div`
  width: 100%;
  .expandable-panel-heading-left {
    max-width: 75%;
  }
  * {
    line-height: 23px;
  }
`;

const ResolvedExceptionsBanners = ({
  resolvedExceptions,
}: {
  resolvedExceptions: Exception[];
}) => {
  const [showPreference] = useNestedPreference('report', 'showResolvedDelays');

  if (!showPreference) return null;

  return (
    <StyledResolvedExceptionsBanners>
      <SingleExceptionBanner
        exceptions={resolvedExceptions}
        exceptionStatus='resolved'
      />
      <MultipleExceptionsBanner
        exceptions={resolvedExceptions}
        exceptionStatus='resolved'
      />
    </StyledResolvedExceptionsBanners>
  );
};

export default ResolvedExceptionsBanners;

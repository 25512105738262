import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledDescription = styled.div`
  margin-top: 15px;
  margin-bottom: 37px;
  width: 744px;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.brandNavy3};
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledNewWindowMessage = styled.div`
  size: 16px;
  color: ${colors.brandNavy3} !important;
  line-height: 24px;
  margin-bottom: 7px;
`;

export const StyledHereLink = styled.a`
  color: ${colors.brandNavy3} !important;
  text-decoration: underline;
  size: 16px;
`;

import React, { createContext, useMemo, useState } from 'react';

type HeaderHeightContextType = {
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
};

export const HeaderHeightContext = createContext<HeaderHeightContextType>({
  headerHeight: 0,
  setHeaderHeight: () => {},
});

export const HeaderHeightProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [headerHeight, setHeaderHeight] = useState(85);

  const value = useMemo(
    () => ({ headerHeight, setHeaderHeight }),
    [headerHeight],
  );

  return (
    <HeaderHeightContext.Provider value={value}>
      {children}
    </HeaderHeightContext.Provider>
  );
};

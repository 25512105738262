import { GenericObject } from '@dashboard-experience/utils';
import { colors } from '@dashboard-experience/mastodon';
import React, { useContext } from 'react';
import styled from 'styled-components';
import UIContext from '../../../../context/UI';

const Wrapper = styled.div<{ isiframe: string }>`
  p {
    color: ${colors.uiGrey700};

    a {
      color: ${colors.brandAqua4} !important;
    }

    strong {
      font-weight: 500;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: ${props => props.isiframe}) {
    display: inline-flex;
    margin-bottom: 3rem;

    p {
      max-width: 800px;
    }
  }
`;

const Title = styled.h3`
  color: #1a2026;
  font-family: 'National 2';
  font-style: normal;
  font-weight: 700;
  margin: 0 !important;
  flex-grow: 0;
  align-self: flex-start;
  @media (max-width: 671px) {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
  }

  @media (min-width: 672px) and (max-width: 1055px) {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
  }

  @media (min-width: 1056px) and (max-width: 1310px) {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.24px;
  }

  @media (min-width: 1311px) {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.24px;
  }
`;

type HeaderElement = {
  label: string;
  props?: GenericObject;
};

type Props = {
  title?: string;
  description?: HeaderElement;
  descriptionNode?: any;
  style?: object;
};

export const ReportsTitle: React.FC<Props> = ({
  title,
  description,
  descriptionNode,
  style,
}) => {
  const { isIframe } = useContext(UIContext);

  return (
    <Wrapper
      style={style}
      isiframe={isIframe ? '720px' : '992px'}
      data-testid='page-header-wrapper'
    >
      {title && (
        <Title data-testid='page-header-title-reports-list'>{title}</Title>
      )}
      {description && <p {...description?.props}>{description.label}</p>}
      {descriptionNode && descriptionNode}
    </Wrapper>
  );
};

export default ReportsTitle;

import React from 'react';
import { getDollarAmount } from 'components/AddScreenings/shared/utils';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../locales';
import {
  StyledPriceText,
  StyledPriceVaries,
  StyledPriceWrapper,
  StyledStartingAtText,
} from './PackagePrice.styles';

const PackagePrice: React.FC<{ price?: number }> = ({ price = 0 }) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:selectPackage`;
  return (
    <StyledPriceWrapper>
      {price === 0 ? (
        <StyledPriceVaries data-testid='price-varies'>
          {t(`${basePath}.packagePrice.priceVaries`)}
        </StyledPriceVaries>
      ) : (
        <>
          <StyledStartingAtText>
            {t(`${basePath}.packagePrice.startingAt`)}
          </StyledStartingAtText>
          <StyledPriceText>{getDollarAmount(String(price))}</StyledPriceText>
        </>
      )}
    </StyledPriceWrapper>
  );
};

export default PackagePrice;

import axios from 'axios';
import { useQuery } from 'react-query';
import { CLICKWRAP_API_KEY } from 'Constants';
import useGetClickwrapActivities from './useGetClickwrapActivities';

const getContractByVersion = async (versionId: string) => {
  const response = await axios.get(
    `https://api.pactsafe.com/v1.1/versions/${versionId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${CLICKWRAP_API_KEY}`,
      },
    },
  );

  return response.data;
};

function useGetClickwrapContract() {
  const {
    data: activities,
    isLoading: areActivitiesLoading,
    error: activityError,
  } = useGetClickwrapActivities();
  const request = () =>
    getContractByVersion(activities?.data[0]?.version || '');

  const result = useQuery(['clickwrap-contract'], request, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled:
      !areActivitiesLoading && !activityError && activities !== undefined,
  });

  const { isLoading, error, data } = result;

  return {
    data,
    isLoading,
    error,
  };
}

export default useGetClickwrapContract;

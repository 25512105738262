import { useCandidate } from 'providers/Candidate';
import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { hasPermission, isInternal } from '@dashboard-experience/utils';
import { Flag, useFlag } from '@dashboard-experience/react-flagr';
import { useUser } from 'context/CurrentUser';
import { useNestedPreference } from 'api/dashboardPreferences';
import ContinuousCheckContainer from '../ContinuousServices/ContinuousCheckContainer';
import SubscriptionContainer from '../ContinuousServices/SubscriptionContainer';
import {
  CC_FLAG_KEY,
  MANAGE_CC_AND_SUBSCRIPTIONS_FLAG_KEY,
  SUBSCRIPTIONS_FLAG_KEY,
} from '../../Constants';
import SubscriptionToggle from '../../components/ContinuousServices/SubscriptionToggle';
import useHasPostHire from './useHasPostHire';

const PostHireContainer: React.FC = () => {
  const currentUser = useUser();
  const candidate = useCandidate();

  const anyContinuousCheckEnabled =
    isInternal(currentUser) ||
    candidate?.account?.any_continuous_check_enabled ||
    currentUser?.account?.any_continuous_check_enabled;

  const manageContinuous = hasPermission(currentUser, 'manage_continuous');
  const readContinuous = hasPermission(currentUser, 'read_continuous');
  const permissionsFlag =
    useFlag(MANAGE_CC_AND_SUBSCRIPTIONS_FLAG_KEY)?.variantKey === 'enabled';

  const showPostHire = useHasPostHire();

  const canViewContinuousTab = () => {
    if (anyContinuousCheckEnabled) {
      if (permissionsFlag) {
        return manageContinuous || readContinuous;
      }
      return true;
    }
    return false;
  };
  const [postHirePreference, setPostHirePreference] = useNestedPreference(
    'candidate',
    'postHire',
  );
  const { expanded } = postHirePreference;

  /**
   * @name onExpand
   * @function
   * @memberOf CandidateInformation/CandidateInformation
   * @description Callback to handle setting user preference for expand
   */
  const onExpand = useCallback(
    expanded => {
      setPostHirePreference({ expanded });
    },
    [setPostHirePreference],
  );

  return showPostHire ? (
    <M.Container id='post-hire' data-testid='post-hire'>
      <M.Expandable
        title={<h5 className='mb-0'>Post-hire safety</h5>}
        initialExpanded={expanded}
        onExpand={onExpand}
      >
        {canViewContinuousTab() && (
          <Flag flagKey={CC_FLAG_KEY} variantKey='true'>
            <ContinuousCheckContainer />
          </Flag>
        )}
        {canViewContinuousTab() && (
          <Flag flagKey={SUBSCRIPTIONS_FLAG_KEY} variantKey='true'>
            <M.Divider />
          </Flag>
        )}
        <Flag flagKey={SUBSCRIPTIONS_FLAG_KEY} variantKey='true'>
          <SubscriptionContainer />
        </Flag>
        <Flag flagKey={SUBSCRIPTIONS_FLAG_KEY} variantKey='off'>
          {hasPermission(currentUser, 'create_manual_orders') && candidate && (
            <SubscriptionToggle candidate={candidate} />
          )}
        </Flag>
      </M.Expandable>
    </M.Container>
  ) : null;
};

export default PostHireContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { BillingContainer } from 'containers';
import { Redirect } from 'react-router-dom';
import Provider from 'containers/Billing/Provider';
import { useUser } from '../../context/CurrentUser';

const BillingPage = ({ match }) => {
  const { context } = match.params;
  const currentUser = useUser();
  const { account } = currentUser;

  if (
    ![
      'invoices',
      'payment',
      'documents',
      'bulk-history',
      'payment-profiles',
    ].includes(context)
  ) {
    return <Redirect to='/404' />;
  }
  return (
    <Provider accountId={account.id}>
      <BillingContainer context={context} />
    </Provider>
  );
};

BillingPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BillingPage;

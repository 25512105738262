import { AnyQueryKey, useMutation, useQuery } from 'react-query';
import { useErrorNotifier } from 'hooks';
import {
  getDrivingJurisdictions,
  AccessCodeParams,
  createAccessCode,
  DrivingJurisdictionsParams,
  updateAccessCode,
  getAccessCode,
} from './actions';

export const useDrivingJurisdictionsList = (
  params: DrivingJurisdictionsParams,
) => {
  const key: AnyQueryKey = ['driving-jurisdictions', params];

  const getDrivingJurisdictionsRequest = () => getDrivingJurisdictions(params);
  return useQuery(key, getDrivingJurisdictionsRequest, {
    refetchOnWindowFocus: false,
  });
};

export const useGetAccessCode = (params: AccessCodeParams) => {
  const key: AnyQueryKey = ['access-code', params];
  const getAccessCodeRequest = () => getAccessCode(params);
  const errorNotifier = useErrorNotifier();
  return useQuery(key, getAccessCodeRequest, {
    refetchOnWindowFocus: false,
    onError: (err: Error) => {
      // 404 is expected when the access code does not exist
      errorNotifier(err, { ignoreCodes: 404 });
    },
  });
};

export const useCreateAccessCode = () => {
  const addAccessCodeRequest = (params: AccessCodeParams) =>
    createAccessCode(params);
  return useMutation(addAccessCodeRequest);
};

export const useUpdateAccessCode = () => {
  const editAccessCodeRequest = (params: AccessCodeParams) =>
    updateAccessCode(params);
  return useMutation(editAccessCodeRequest);
};

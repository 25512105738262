import React, { useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';

import {
  GenericObject,
  getStatusTypeForUser,
  StatusTypes,
} from '@dashboard-experience/utils';
import { PARAMS } from 'components/Search';
import { useUser } from 'context/CurrentUser';
import { useGetReportsList } from 'modules/reports-list/utilities';
import { useFilters } from '../state/hooks';
import PaginationContainer from './pagination-container';

type Props = {};

const SearchTableContainer: React.FC<Props> = () => {
  const [reports, setReports] = useState<GenericObject>();
  const [, setCancelSource] = useState<any | null>(null);

  const filters = useFilters();
  const currentUser = useUser();
  const { account } = currentUser;
  const userType = getStatusTypeForUser(currentUser);
  const assessEnabled = userType === StatusTypes.Assess;

  // useGetReportsList calls setReport on success
  // This allows us to retain the previous reports data while a refetch is being made
  const { isLoading } = useGetReportsList(
    filters,
    currentUser,
    account,
    setReports,
    setCancelSource,
  );

  useEffect(() => {
    if (account) {
      PARAMS.updateGeneralParams({
        assessEnabled,
        account,
      });
    }
  }, [account, assessEnabled]);

  return (
    <div data-testid='reports-list-search-table'>
      <h2>Table</h2>
      {!!reports && (
        <PaginationContainer
          count={reports?.count}
          pageNum={filters.page}
          perPage={filters.per_page}
        />
      )}
      {!reports && isLoading && <M.LoadingInline description='Loading...' />}
    </div>
  );
};

export default SearchTableContainer;

// What does <div data-dd-privacy='allow'> do?

// TODO: handle contextId
// TODO: handle useCandidateSearchFilterReport
// TODO: inveatigate improving updateGeneralParamszZ
// TODO: add Filters
// TODO: handle add AwaitingCredentialingNotification
// TODO: add error inline notification

/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { map } from 'lodash/fp';

import { useRumError } from '@dashboard-experience/utils';
import { getUserObject } from 'utils';
import styled from 'styled-components';
import { Location, LocationSearchProps } from './types';
import {
  HeaderContainer,
  ChoiceCardGroup,
  ChoiceCardWrapped,
  RadioButtonWidth,
  ZipcodeContainer,
  LocationPanelList,
  SkeletonContainer,
  Header,
  LocationSearchContainer,
} from './styles';
import './LocationSearch.scss';
import Panel from './Panel';
import { useClinicLocations } from '../../../api/healthScreening/hooks';
import { convertCaseInArray } from './utils';

const PaginationContainer = styled.div`
   {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`;

const LocationSearch: React.FC<LocationSearchProps> = ({
  examSlugs,
  packageId,
  selectedClinic,
  setSelectedClinic,
  shouldSelectClinic,
  setShouldSelectClinic,
  testPackage = false,
  requiresObservedCollection = false,
}) => {
  const [showExpandedHoursOnly, setShowExpandedHoursOnly] = useState(false);
  const [zipcode, setZipcode] = useState<string | undefined>(
    selectedClinic?.zipcode,
  );
  enum CLINIC_TYPE {
    AFFIRMATIVE = 'SELECT_CLINIC_AFFIRMATIVE',
    NEGATIVE = 'SELECT_CLINIC_NEGATIVE',
  }

  const { siteId: selectedSiteId } = selectedClinic || {};
  const [selectClinicState, setSelectClinicState] = useState<
    string | undefined
  >(CLINIC_TYPE.NEGATIVE);
  const currentUser = getUserObject();
  const { account } = currentUser || {};
  const { id: accountId } = account || {};

  const isClinicSelectedOnPreviousStep =
    selectedSiteId && selectClinicState === CLINIC_TYPE.NEGATIVE;
  if (isClinicSelectedOnPreviousStep) {
    setSelectClinicState(CLINIC_TYPE.AFFIRMATIVE);
  }

  const addDataDogError = useRumError();
  const onError = (err: any): void => {
    const error_request = new Error(
      `Failed to get the locations: ${err.message}`,
    );
    addDataDogError(error_request, {
      zipcode,
      examSlugs,
      accountId,
      packageId,
      testPackage,
      requiresObservedCollection,
    });
  };

  const {
    data: locations,
    error,
    isLoading,
  } = useClinicLocations(
    zipcode as string,
    examSlugs,
    accountId,
    packageId as string,
    testPackage,
    requiresObservedCollection,
    showExpandedHoursOnly,
    onError,
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [pageWithLocations, setPageWithLocations] = useState<
    Location[] | undefined
  >(locations);
  const PAGE_SIZE = 5;
  const pageCount = Math.ceil((locations ? locations.length : 0) / PAGE_SIZE);

  const handlePageClick = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  useEffect(() => {
    if (locations) {
      setPageWithLocations(
        locations.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE),
      );
    }
  }, [currentPage, locations]);

  const isAffirmativeResponse = useCallback(
    (key: string) => key === CLINIC_TYPE.AFFIRMATIVE,
    [CLINIC_TYPE.AFFIRMATIVE],
  );

  const onExpandedHoursClick = useCallback(
    () => setShowExpandedHoursOnly(!showExpandedHoursOnly),
    [showExpandedHoursOnly],
  );

  const handleChooseClinicChange = useCallback(
    (key: string) => {
      if (!isAffirmativeResponse(key) && !!selectedClinic)
        setSelectedClinic(null);
      setShouldSelectClinic(key === CLINIC_TYPE.AFFIRMATIVE);
      setSelectClinicState(key);
    },
    [
      isAffirmativeResponse,
      selectedClinic,
      setSelectClinicState,
      setShouldSelectClinic,
      setSelectedClinic,
      CLINIC_TYPE.AFFIRMATIVE,
    ],
  );

  const handleMyself = useCallback(() => {
    handleChooseClinicChange(CLINIC_TYPE.AFFIRMATIVE);
  }, [handleChooseClinicChange, CLINIC_TYPE.AFFIRMATIVE]);

  const handleCandidate = useCallback(() => {
    handleChooseClinicChange(CLINIC_TYPE.NEGATIVE);
  }, [handleChooseClinicChange, CLINIC_TYPE.NEGATIVE]);

  return (
    <M.Grid
      className='location-grid-container'
      data-testid='location-grid-container'
    >
      <Header>Order test</Header>
      <LocationSearchContainer>
        <M.GridRow>
          <HeaderContainer>Select clinic</HeaderContainer>
        </M.GridRow>
        <ChoiceCardGroup>
          <ChoiceCardWrapped data-testid='myself-radio-button-card-wrap'>
            <RadioButtonWidth
              data-testid='myself-radio-button-testid'
              name={CLINIC_TYPE.AFFIRMATIVE}
              onClick={handleMyself}
              checked={selectClinicState === CLINIC_TYPE.AFFIRMATIVE}
              value={CLINIC_TYPE.AFFIRMATIVE}
              title='I will select a clinic'
              description='I provide the screening pass and all instructions to the people testing.'
            />
          </ChoiceCardWrapped>
          <ChoiceCardWrapped data-testid='candidate-radio-button-card-wrap'>
            <RadioButtonWidth
              data-testid='candidate-radio-button-testid'
              name={CLINIC_TYPE.NEGATIVE}
              onClick={handleCandidate}
              checked={selectClinicState === CLINIC_TYPE.NEGATIVE}
              value={CLINIC_TYPE.NEGATIVE}
              title='Tester will select a clinic'
              description='Checkr emails a link to the tester to select a clinic and receive a screening pass.'
            />
          </ChoiceCardWrapped>
        </ChoiceCardGroup>
        {shouldSelectClinic ? (
          <>
            <p>The pass will expire 24 hours after you submit your order.</p>
            <M.GridRow>
              <ZipcodeContainer>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor='zipcode'>Enter zip code</label>
                <M.Search
                  searchId='zipcode'
                  onChange={(event: React.BaseSyntheticEvent) => {
                    setZipcode(event?.target?.value);
                  }}
                  value={zipcode}
                />
              </ZipcodeContainer>
            </M.GridRow>
            <M.GridRow>
              <M.Checkbox
                data-testid='show-only-expanded-hours-filter'
                onClick={onExpandedHoursClick}
                name='showOnlyExpandedHoursFilter'
                labelText='Only show clinics with expanded hours'
                hideLabel={false}
                id='showOnlyExpandedHoursFilter'
                checked={showExpandedHoursOnly}
              />
            </M.GridRow>
            <M.GridRow>
              <LocationPanelList>
                {pageWithLocations && (
                  <>
                    {map(
                      (location: Location) => (
                        <Panel
                          data-testid={`panel-${location.siteId}`}
                          key={location.siteId}
                          selectedSiteId={selectedSiteId}
                          setSelectedClinic={setSelectedClinic}
                          location={location}
                        />
                      ),
                      convertCaseInArray(pageWithLocations),
                    )}
                  </>
                )}
                {isLoading && (
                  <SkeletonContainer data-testid='loading-container'>
                    <M.CodeSnippetSkeleton type='multi' />
                  </SkeletonContainer>
                )}
              </LocationPanelList>

              {error ? (
                <M.InlineNotification
                  data-testid='error-notification'
                  role='alert'
                  iconDescription='indicates error retrieving data'
                  kind='error'
                  title='Error'
                >
                  There was an error retrieving the list of available clinics.
                  Please try again later. If the problem persists, please
                  contact support.
                </M.InlineNotification>
              ) : null}
            </M.GridRow>
          </>
        ) : (
          <>
            {shouldSelectClinic === false && (
              <p>
                The screening pass will expire 24 hours after the tester select
                a clinic.
              </p>
            )}
          </>
        )}
      </LocationSearchContainer>
      <PaginationContainer>
        {locations && pageCount > 1 && (
          <M.GridRow>
            <M.Pagination
              pageCount={pageCount}
              selectedIndex={currentPage}
              onPageClick={handlePageClick}
            />
          </M.GridRow>
        )}
      </PaginationContainer>
    </M.Grid>
  );
};

export default LocationSearch;

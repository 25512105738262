import React, { memo } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import useGetMenuItems from './useGetMenuItems';

const ActionMenu = styled(M.ActionMenu)`
  #mastodon && {
    align-self: flex-start;
    .mastodon-action-menu-trigger {
      svg {
        fill: ${colors.uiGrey900};
        fill-opacity: 0.78;
      }
    }
    .mastodon-action-menu-container {
      z-index: 1000;
    }
  }
`;

const StyledTooltipWrapper = styled.div`
  .cds--popover--caret {
    --cds-popover-offset: 0.25rem;
  }

  .cds--popover-caret {
    visibility: hidden;
  }
`;

const SecondaryActions = () => {
  const menuItems = useGetMenuItems();
  const trigger = (
    <StyledTooltipWrapper>
      <M.TooltipDefinition
        align='bottom-right'
        highlighted={false}
        definition='More options'
      >
        <M.Button kind='secondary'>
          <M.Icon icon='OverflowMenuVertical' />
        </M.Button>
      </M.TooltipDefinition>
    </StyledTooltipWrapper>
  );

  return (
    <div data-testid='secondary-actions-menu'>
      <ActionMenu
        position='left-bottom'
        trigger={trigger}
        className='secondary-actions-menu'
      >
        {menuItems}
      </ActionMenu>
    </div>
  );
};
export default memo(SecondaryActions);

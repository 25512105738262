import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CreditReportSetupModal from 'components/AddScreenings/Domestic/List/CreditReportSetupModal'; // TODO (Refactor & move into BOX in the future)
import { namespace } from 'components/BetterOrderExperience/locales';
import { StyledFlex } from '../../AddonsStep.styles';
import { ScreeningSectionItem } from '../ScreeningSectionItem';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { AddonsT } from '../..';
import CreditReportSetupButtons from './CreditReportSetupButtons';
import { StyledPendingSetupTag } from './CreditReport.styles';

type Props = {
  id: string;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  price: string;
  isAdded: boolean;
  isIncluded: boolean;
  status: AddonsT.CreditReportStatusType;
};

const CreditReport: React.FC<Props> = ({
  id,
  price,
  isAdded,
  isIncluded,
  onAddClick,
  onRemoveClick,
  status,
}) => {
  const [openSetupModal, setOpenSetupModal] = useState(false);

  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.creditReport',
  });

  const handleAddClick = useCallback(() => {
    onAddClick(ScreeningProductType.CREDIT_REPORT, price);
  }, [onAddClick, price]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(ScreeningProductType.CREDIT_REPORT);
  }, [onRemoveClick]);

  const handleClose = useCallback(() => {
    setOpenSetupModal(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenSetupModal(true);
  }, []);

  return (
    <StyledFlex>
      <CreditReportSetupModal open={openSetupModal} handleClose={handleClose} />
      <ScreeningSectionItem
        id={id}
        description={t('description')}
        advantages={t('advantages', { returnObjects: true })}
        disadvantages={t('considerations', {
          returnObjects: true,
        })}
        price={price}
        screeningName={t('name')}
        titleTag={
          status === 'disabled' ? (
            <StyledPendingSetupTag>{t('disabled.tag')}</StyledPendingSetupTag>
          ) : null
        }
      />
      <CreditReportSetupButtons
        id={id}
        isAdded={isAdded}
        isIncluded={isIncluded}
        onAddClick={handleAddClick}
        onRemoveClick={handleRemoveClick}
        status={status}
        handleOpen={handleOpen}
      />
    </StyledFlex>
  );
};

export default CreditReport;

import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Text = styled.span`
  color: ${colors.uiGrey500};
  font-weight: bolder;
`;

const keyStyle = {
  color: colors.uiTextPrimaryDark,
  fontWeight: 700,
};

const valueStyle = {
  color: colors.uiTextPrimaryDark,
  fontWeight: 400,
  marginRight: '0.25rem',
};

type Item = {
  itemKey: string;
  itemValue: string;
};

type Props = {
  count?: number;
  keyValueList?: Item[];
};

const AssignedStatus: React.FC<Props> = ({ count, keyValueList }) => {
  const { t } = useTranslation('assess');

  const text = t(`assess:ruleset_assignments.status.assigned`, 'Assigned');

  return (
    <div data-testid='assess-rulesets-assigned-status'>
      <M.Tooltip
        data-testid='assess-rulesets-assigned-tooltip'
        highlighted={false}
        label={
          <M.KeyValueList
            items={keyValueList?.length ? keyValueList : []}
            keyStyle={keyStyle}
            valueStyle={valueStyle}
          />
        }
      >
        <Text>
          {text} ({count})
        </Text>
      </M.Tooltip>
    </div>
  );
};

export default AssignedStatus;

import { namespace } from 'modules/candidate/locales';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { compact, flow, uniq } from 'lodash';
import {
  ExceptionWithScreeningName,
  getDocumentTypesForMultipleExceptions,
} from '../../utils';

const MultipleExceptionsMessageBody = ({
  exceptions,
  firstName,
  totalCanceledScreenings,
  screeningStatus,
}: {
  exceptions: ExceptionWithScreeningName[];
  firstName: string | null | undefined;
  totalCanceledScreenings: number;
  screeningStatus: string;
}) => {
  const { t } = useTranslation();
  const joinWithAnd = (arr: string[]) => {
    if (arr.length <= 1) return arr[0] || '';
    if (arr.length === 2) return arr.join(' and ');
    return `${arr.slice(0, -1).join(', ')}, and ${arr[arr.length - 1]}`;
  };

  const getScreeningNamesString = flow(
    () => exceptions.map(exception => exception.screeningName),
    compact,
    uniq,
    joinWithAnd,
  );

  const getDocumentTypeString = flow(
    () => getDocumentTypesForMultipleExceptions(exceptions),
    joinWithAnd,
  );

  const bannerMessageDetails = {
    firstName: firstName || 'The candidate',
    documentTypes: getDocumentTypeString(),
    screeningNames: getScreeningNamesString(),
    screeningStatus,
    interpolation: { escapeValue: false },
  };

  const message =
    totalCanceledScreenings > exceptions.length
      ? t(
          `${namespace}:report.exceptions.banners.canceledScreenings.body.multipleRelated`,
          {
            ...bannerMessageDetails,
          },
        )
      : t(
          `${namespace}:report.exceptions.banners.canceledScreenings.body.multipleCanceled`,
          {
            ...bannerMessageDetails,
          },
        );

  return <span style={{ lineHeight: '23px' }}>{message}</span>;
};

export default MultipleExceptionsMessageBody;

import { M, colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledModal = styled(M.ComposedModal)`
  .cds--modal-close {
    display: none;
  }
`;

export const StyledUpsellWrap = styled.div`
  border-top: 1px solid ${colors.borderPrimaryLight};
  margin-top: 1.5rem;
  padding-top: 1.5rem;
`;

export const StyledContainerInner = styled.div`
  border: 1px solid ${colors.borderPrimaryLight};
  border-radius: 0.25rem;
  box-shadow: 0rem 0.25rem 2rem -1rem ${colors.bgPrimaryDark}1a;
  align-items: center;
  margin: 1.5rem 0;
  padding: 1.5rem;
`;

export const StyledFlexWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledButton = styled(M.Button)`
  margin: 0.75rem 0 0 1rem !important;
  padding: 0.5rem !important;
`;
